import { state } from "../../../../store";

export const [
  organizationSecretDetailsActions,
  organizationSecretDetailsSelector,
  organizationSecretDetailsState,
] = state("OrganizationSecretDetails", {
  initial: {
    organizationAccount: { item: {}, loading: false },
    loading: false,
  },
  on: {
    loadSecret: (_, result) => {
      const newStoreObj = { ..._ };
      newStoreObj.organizationAccount.item = result;
      newStoreObj.organizationAccount.loading = true;
      return newStoreObj;
    },
    setLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        loading: data,
      };
    },
  },

  events: [
    "organizationSecretById",
    "updateOrganizationSecret",
    "deleteOrganizationSecret",
  ],
});
