import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { diagnosticPageActions } from "./store";
import {
  sym_key,
  decrypt_user_account_object,
  debugOrganizationObject,
} from "../../../helpers/apiUtils";
import { api } from "../../../helpers/api";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function getSingleArray(corruptArray) {
  let all = [];

  corruptArray.forEach((obj) => {
    all.push(...obj);
  });
  return all;
}

function* viewDiagnosticInfoSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        diagnosticPageActions.loadData().type
      );
      yield putResolve(diagnosticPageActions.setLoading(true));

      let apiData = yield call(api, `/me/me_v2.json`);

      let symKey = sym_key();

      let corruptCompanies = [];
      let corruptTeams = [];
      let corruptCompanySecrets = [];
      let corruptTeamSecrets = [];
      let corruptPersonalSecrets = [];

      let error;
      if (typeof symKey == "undefined" || symKey == "") {
        error = "No Sym key";
      }

      for (let i = 0; i < apiData.user.accounts.length; i++) {
        let accountData = apiData.user.accounts[i];
        try {
          decrypt_user_account_object(accountData, symKey);
        } catch (error) {
          corruptPersonalSecrets.push(accountData);
        }
      }
      let orgs = Object.values(apiData.orgs_by_id);
      if (!!orgs) {
        for (let j = 0; j < orgs.length; j++) {
          let org = orgs[j];
          let orgResult = yield call(debugOrganizationObject, symKey, org);
          if (orgResult.organizations.length > 0) {
            corruptCompanies.push(orgResult.organizations);
          }
          if (orgResult.organizationGroups.length > 0) {
            let organizationGroups = orgResult.organizationGroups.map(
              (group) => {
                let org = apiData.orgs_by_id[group.organization_id];
                group.orgName = org.name;
                return group;
              }
            );
            corruptTeams.push(organizationGroups);
          }
          if (orgResult.organizationGroupAccounts.length > 0) {
            let organizationGroupAccounts = orgResult.organizationGroupAccounts.map(
              (account) => {
                let org = apiData.orgs_by_id[account.organization_id];
                let team = org.groups_by_id[account.group_id];
                account.orgName = org.name;
                account.teamName = team.name;
                return account;
              }
            );
            corruptTeamSecrets.push(organizationGroupAccounts);
          }
          if (orgResult.organizationAccounts.length > 0) {
            corruptCompanySecrets.push(orgResult.organizationAccounts);
          }
        }
      }

      let corruptTeamsAll = getSingleArray(corruptTeams);
      let corruptCompanySecretsAll = getSingleArray(corruptCompanySecrets);
      let corruptTeamSecretsAll = getSingleArray(corruptTeamSecrets);

      corruptTeamsAll = getSingleArray(corruptTeams);

      let allCorruptedData = {
        corruptCompaniesAll: corruptCompanies,
        corruptTeamsAll: corruptTeamsAll,
        corruptCompanySecretsAll: corruptCompanySecretsAll,
        corruptTeamSecretsAll: corruptTeamSecretsAll,
        corruptPersonalSecretsAll: corruptPersonalSecrets,
      };

      yield putResolve(diagnosticPageActions.setLoading(false));

      yield putResolve(
        diagnosticPageActions.setCorruptedData({ ...allCorruptedData })
      );
    } catch (err) {
      yield call(sentryErrorCatch, err, "#197");
      toast.success("Something went wrong #197", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}

export default function* viewDiagnosticInfoRootSaga() {
  yield spawn(viewDiagnosticInfoSaga);
}
