import { state } from "../../store";
import { search, sorting } from "../../helpers";

export const [
  personalsecretActions,
  personalsecretSelector,
  personalsecretState,
] = state("personalSecretPage", {
  initial: {
    personalSecrets: { items: [], loading: true },
    filterPersonalSecrets: { items: [], loading: true },
    sortPersonalSecret: {
      login_page_hostname: { ascending: false },
      url: { ascending: false },
      login: { ascending: false },
    },
    searchField: ["page_title", "login_page_hostname", "url", "login"],
    searchText: "",
    selectedSecret: { items: {}, loading: false },
  },
  on: {
    loadPageSecretData: (_, data) => {
      const newStoreObj = { ..._ };
      newStoreObj.personalSecrets.loading = false;
      newStoreObj.personalSecrets.items = data;
      newStoreObj.filterPersonalSecrets.loading = false;
      newStoreObj.filterPersonalSecrets.items = data;
      newStoreObj.selectedSecret.loading = false;

      return newStoreObj;
    },

    searching: (_, result) => {
      const newStoreObj = { ..._ };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      newStoreObj.filterPersonalSecrets.items = [
        ...search(newStoreObj.personalSecrets.items)(newStoreObj.searchText)(
          newStoreObj.searchField
        ),
      ];

      return newStoreObj;
    },
    sorting: (_, result) => {
      let newStoreObj = { ..._ };
      newStoreObj.sortPersonalSecret[result.fieldName].ascending = !newStoreObj
        .sortPersonalSecret[result.fieldName].ascending;
      result["sortType"] =
        newStoreObj.sortPersonalSecret[result.fieldName].ascending;
      newStoreObj.filterPersonalSecrets.items = [
        ...sorting(newStoreObj.personalSecrets.items, result),
      ];
      return { ...newStoreObj };
    },
    setSelectedSecret: (_, result) => {
      let newStoreObj = { ..._ };
      newStoreObj.selectedSecret.items = result;
      newStoreObj.selectedSecret.loading = true;
      return { ...newStoreObj };
    },
  },
  events: ["loadData", "detailsPersonalSecret"],
});
