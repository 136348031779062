import { call, spawn, take, select, putResolve } from "redux-saga/effects";
import { personalSecretAddActions } from "./store";
import { api } from "../../../helpers/api";
import {
  decryptAccountsData,
  wrapHashKeys,
  encrypt,
  sym_key,
  accountFormDataMatchesExistingAccount,
  remove_placeholders_from_login_string,
} from "../../../helpers/apiUtils";
import { authSelector } from "../../auth/store";
import { toast } from "react-toastify";
import { secretActions } from "../../../components/secrets/store";
import { sorting } from "../../../helpers";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* personalSecretAddPageSaga() {
  while (true) {
    try {
      yield take(personalSecretAddActions.loadData().type);
      yield putResolve(personalSecretAddActions.setLoading(true));
      const { csrfToken } = yield select(authSelector((state) => state));
      let domainDictionaries;
      if (csrfToken) {
        //API
        domainDictionaries = yield call(
          api,
          `/domain_dictionaries/all`,
          null,
          null,
          null,
          csrfToken
        );

        // if (domainDictionaries.success === true) {
        // } else {
        //   //toaster;
        //   toast.error("Something went wrong", {
        //     className: "toast-danger",
        //   });
        // }
      } else {
        yield call(sentryErrorCatch, "No CSRF Token", "#183");
        //toaster;
        toast.error("Something went wrong #183", {
          className: "toast-danger",
        });
      }
      let sortedDomainsDictionary = sorting(domainDictionaries.all_domains, {
        sortBy: "STRING",
        fieldName: "title",
        sortType: true,
      });
      yield putResolve(personalSecretAddActions.setLoading(false));
      yield putResolve(
        secretActions.loadAddSecret({
          domains: sortedDomainsDictionary,
          from: "personal-secret",
        })
      );
    } catch (err) {
      yield call(sentryErrorCatch, err, "#184");
      // toaster
      toast.error("Something went wrong #184", {
        className: "toast-danger",
      });
      console.log("error in loadDataPageSaga");
    }
  }
}

function* addSecretAutoSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        personalSecretAddActions.addSecretAuto().type
      );
      yield putResolve(secretActions.setAddSecretButtonLoading(true));
      // csrfToken
      const { csrfToken, me } = yield select(authSelector((state) => state));
      const user_data = me;
      if (csrfToken) {
        // all_accounts API
        const result = yield call(
          api,
          `/me/all_accounts`,
          null,
          null,
          null,
          csrfToken
        );
        // domain_dictionaries API required for Auto-Login
        const domain_dictionaries = yield call(
          api,
          `/domain_dictionaries/${data.application_id}`,
          null,
          null,
          null,
          csrfToken
        );

        // decryptAccountsData
        var accounts = yield call(decryptAccountsData, result, data, user_data);
        // first record of domain_dictionaries
        var dd = domain_dictionaries.domain_dictionary;

        // accountFormDataMatchesExistingAccount for auto-login to check account is already added or not
        if (accountFormDataMatchesExistingAccount(accounts, data, dd)) {
          toast.error("Account already added.", {
            className: "toast-danger",
          });
          yield putResolve(secretActions.setAddSecretButtonLoading(false));
        } else {
          // add a type user
          data["type"] = "user";

          let accountFormData = {};
          accountFormData.login = data.login;
          accountFormData.cipher = data.password;
          accountFormData.notes = data.notes;
          accountFormData.url = domain_dictionaries.domain_dictionary.login_url;
          accountFormData.page_title =
            domain_dictionaries.domain_dictionary.title;
          accountFormData.redirect_page_title =
            domain_dictionaries.domain_dictionary.title;
          accountFormData.redirect_page_hostname =
            domain_dictionaries.domain_dictionary.url;
          accountFormData.redirect_origin =
            domain_dictionaries.domain_dictionary.login_url;
          accountFormData.login_page_title =
            domain_dictionaries.domain_dictionary.title;
          accountFormData.login_page_hostname =
            domain_dictionaries.domain_dictionary.url;
          accountFormData.login_origin =
            domain_dictionaries.domain_dictionary.login_url;
          accountFormData.login_string = remove_placeholders_from_login_string(
            domain_dictionaries.domain_dictionary.login_string,
            data.login,
            data.password
          );

          const form_data = new FormData();
          for (let key in accountFormData) {
            // Form Data
            form_data.append(`user_account[${key}]`, accountFormData[key]);
          }
          var type = data["type"];

          // rename
          var save = wrapHashKeys(accountFormData, type + "_" + "account");
          // encrypt
          var save_admin = {};

          save_admin = encrypt(save, sym_key());

          const save_adminFormData = new FormData();
          for (let key in save_admin) {
            save_adminFormData.append(key, save_admin[key]);
          }

          save_admin[type + "_account[auto_login]"] = typeof dd != "undefined";
          // API
          let userAccountResult = yield call(
            api,
            `/user_accounts.json`,
            "POST",
            null,
            save_adminFormData,
            csrfToken
          );

          if (userAccountResult) {
            yield putResolve(secretActions.setAddSecretButtonLoading(false));
            data.history.push("/user-accounts");
            // toaster
            toast.success("Secret Added Successfully", {
              className: "toast-success",
            });
          }
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#185");
      // toaster
      toast.error("Something went wrong #185", {
        className: "toast-danger",
      });
      console.log("error in addAutoLoginPersonalSecretSaga", err);
    }
  }
}

function* addSecretCustomSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        personalSecretAddActions.addSecretCustom().type
      );
      yield putResolve(secretActions.setAddSecretButtonLoading(true));
      // csrfToken
      const { csrfToken } = yield select(authSelector((state) => state));

      if (csrfToken) {
        // all_accounts API
        const result = yield call(
          api,
          `/me/all_accounts`,
          null,
          null,
          null,
          csrfToken
        );
        // domain_dictionaries API (id should be undefine)
        const domain_dictionaries = yield call(
          api,
          `/domain_dictionaries/${data["application_id"]}`,
          null,
          null,
          null,
          csrfToken
        );
        // add a type user
        data["type"] = "user";

        //accountDataFromAccountFormData only for custom secret add
        let accountFormData = {};
        accountFormData.login = data.login;
        accountFormData.cipher = data.password;
        accountFormData.notes = data.notes;
        accountFormData.url = data.url;
        accountFormData.page_title = data.application;
        accountFormData.redirect_page_title = data.application;
        accountFormData.redirect_page_hostname = data.application;
        accountFormData.redirect_origin = "";
        accountFormData.login_page_title = data.application;
        accountFormData.login_page_hostname = data.application;
        accountFormData.login_origin = "";
        accountFormData.login_string = "";
        accountFormData.auto_login = false;

        const form_data = new FormData();
        for (let key in accountFormData) {
          form_data.append(`user_account[${key}]`, accountFormData[key]);
        }

        var type = data["type"];
        // rename
        var save = wrapHashKeys(accountFormData, type + "_" + "account");

        // encrypt
        var save_admin = {};

        save_admin = encrypt(save, sym_key());

        const save_adminFormData = new FormData();
        for (let key in save_admin) {
          save_adminFormData.append(key, save_admin[key]);
        }
        // API
        let userAccountResult = yield call(
          api,
          `/user_accounts.json`,
          "POST",
          null,
          save_adminFormData,
          csrfToken
        );

        if (userAccountResult) {
          yield putResolve(secretActions.setAddSecretButtonLoading(false));
          data.history.push("/user-accounts");
          // toaster
          toast.success("Secret Added Successfully", {
            className: "toast-success",
          });
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#186");
      // toaster
      toast.error("Something went wrong #186", {
        className: "toast-danger",
      });
      console.log("error in addCustomPersonalSecretSaga", err);
    }
  }
}

export default function* personalSecretAddRootSaga() {
  yield spawn(personalSecretAddPageSaga);
  yield spawn(addSecretCustomSaga);
  yield spawn(addSecretAutoSaga);
}
