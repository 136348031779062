import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { userActions } from "./store";
import { api } from "../../../helpers/api";
import { authSelector } from "../../auth/store";
import { userSelector } from "./store";
import {
  rsaEncrypt,
  rsaDecrypt,
  subtleRSAEncrypt,
} from "../../../helpers/apiUtils";
import { toast } from "react-toastify";
import { org_sym_key } from "../../../helpers/apiUtils";
import { decrypt } from "../../../helpers/ckService/EncryptionHelper";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* createUsers(users, invitations, org_admin, org_group) {
  users.map((user) => {
    let flag = false;
    let i;

    //invitation
    for (i = 0; i < invitations.length; i++) {
      if (user.email === invitations[i].email) {
        flag = true;
        break;
      }
    }

    //team
    let team = [];
    for (let j = 0; j < org_group.length; j++) {
      if (user.id === org_group[j].user_id) {
        team.push(org_group[j].group);
      }
    }
    user["team"] = team;

    if (flag === true) {
      user["invitationId"] = invitations[i].id;
      user["permission"] = "removePermission";
    } else {
      user["invitationId"] = 0;
      user["permission"] = "grantPermission";
    }

    //admin
    let idIndex = org_admin.findIndex((admin) => admin.id === user.id);
    if (idIndex != -1) {
      user["invitationId"] = 0;
      user["permission"] = "removePermission";
    }
  });
  return users;
}

function* userPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(userActions.loadData().type);

      let apiData = yield call(
        api,
        `/dashboard/companies/${data.companyid}/users.json`,
        "GET",
        null,
        null,
        null
      );

      apiData.users = yield call(
        createUsers,
        apiData.users,
        apiData.userInvitation,
        apiData.admin,
        apiData.org_group
      );
      if (apiData) {
        yield putResolve(userActions.loadUserData(apiData));
      } else {
        yield call(sentryErrorCatch, "API response is empty", "#143");
        //toaster
        toast.error("Something Went Wrong #143", {
          className: "toast-danger",
        });
      }

      yield true;
    } catch (err) {
      yield call(sentryErrorCatch, err, "#144");
      console.log("error in userPageSaga", err);
      toast.error("Something Went Wrong #144", {
        className: "toast-danger",
      });
    }
  }
}

function* grantPermissionSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(userActions.grantPermission().type);
      let result1;
      yield putResolve(
        userActions.setGrantAdminPermissionLoading({
          flag: true,
          index: data.index,
        })
      );
      const { me, csrfToken } = yield select(authSelector((state) => state));

      result1 = yield call(
        api,
        `/organizations/${data.companyid}/pk/?email=${data.email}`,
        "GET",
        null,
        null,
        csrfToken
      );

      // response back from api
      // { "exists": true, "pk": "CcpaOnLv4KbOsBtF4FueQt2eA9nbBq2rflytGBJpDplYyddwJd0ePPitkRTu6axaAAhuxaFdIE5PQel1xxi3ZNxbdydOckxbxal8xxGHJZ1nXUHXzeiTVQ2bfWiS1kmlXxas8tGgEHhNQtSAQJEEjy5deEB6FqI5iewF0ziiHNyeurxbbWwxaz4ls2BQJxaDTllaWjNsxxpiEFcQmtqVxxgVbZFaxbmfhYe44GG7CR9hPJm2ZfFUxbJVslq54fqAmYplJovuhUr3KbrjqN7aTXlNVua9C9E4j6SWWzUWUazV7NQaQibxbxaLJDxaxaqBuiXrmYsEAxawvTnYy9cC4gCIHYgv5gPfd71H5421X9mWi76n7TJKedjafPeVt67xaWB9oNLGObtJqm1wKDq3K" }

      if (result1.exists === true) {
        var public_key_of_user = result1.pk;
        let group_id = null;

        // invite data
        let inviteData = {
          "invitation[email]": data.email,
          "invitation[group_public_key_encrypted]": "",
          "invitation[admin]": true,
          "invitation[share_type]": "organization",
          clear: true,
          group_id: "",
          organization_id: data.companyid,
        };

        let options = {
          admin: me.orgs_by_id[data.companyid].admin,
          clear: true,
          share_type: "organization",
        };

        // admin
        if (options) {
          if (typeof options.admin != "undefined") {
            inviteData["invitation[admin]"] = options.admin;
          }
          if (typeof options.clear != "undefined") {
            inviteData.clear = options.clear;
          }
        }

        // determine the type of invitation
        if (options && options.share_type) {
          inviteData["invitation[share_type]"] = options.share_type;
        } else {
          if (
            typeof group_id == "undefined" ||
            group_id == "" ||
            group_id == null
          ) {
            inviteData["invitation[share_type]"] = "organization";
          } else {
            inviteData["invitation[share_type]"] = "group";
          }
        }

        // re-encrypt with public key
        if (public_key_of_user && public_key_of_user.length) {
          if (inviteData["invitation[share_type]"] == "group") {
            //if group invite
            var encrypted_group_key =
              me.orgs_by_id[data.companyid].groups_by_id[group_id].symKey;
            let orgSymKey = yield call(org_sym_key, me, data.companyid);
            var decrypted_group_key = decrypt(encrypted_group_key, orgSymKey);
            if (result1.asymmetric_key_algo === "RSA-OAEP") {
              inviteData[
                "invitation[group_public_key_encrypted]"
              ] = yield subtleRSAEncrypt(
                public_key_of_user,
                decrypted_group_key
              );
            } else {
              inviteData["invitation[group_public_key_encrypted]"] = rsaEncrypt(
                public_key_of_user,
                decrypted_group_key
              );
            }
          } else if (
            inviteData["invitation[share_type]"] == "organization" &&
            inviteData["invitation[admin]"]
          ) {
            // if org invite and admin (don't care otherwise if not admin)
            let orgSymKey = yield call(org_sym_key, me, data.companyid);
            let encrypted_org_key = "";
            if (result1.asymmetric_key_algo === "RSA-OAEP") {
              encrypted_org_key = yield subtleRSAEncrypt(
                public_key_of_user,
                orgSymKey
              );
            } else {
              encrypted_org_key = rsaEncrypt(public_key_of_user, orgSymKey);
            }
            inviteData[
              "invitation[organization_public_key_encrypted]"
            ] = encrypted_org_key;
          }
        } else {
          //throw "NoRSAPublicKey";
          yield call(sentryErrorCatch, "No RSA PublicKey", "#145");
          //toaster
          toast.error("Something Went Wrong #145", {
            className: "toast-danger",
          });
        }

        // creating form data
        const form_data = new FormData();

        form_data.append("invitation[email]", inviteData["invitation[email]"]);
        form_data.append(
          "invitation[group_public_key_encrypted]",
          inviteData["invitation[group_public_key_encrypted]"]
        );
        form_data.append("group_id", inviteData["group_id"]);
        form_data.append("organization_id", inviteData["organization_id"]);
        form_data.append("invitation[admin]", inviteData["invitation[admin]"]);
        form_data.append(
          "invitation[share_type]",
          inviteData["invitation[share_type]"]
        );
        form_data.append(
          "invitation[organization_public_key_encrypted]",
          inviteData["invitation[organization_public_key_encrypted]"]
        );

        yield call(
          api,
          `/invitations.json`,
          "POST",
          null,
          form_data,
          csrfToken
        );

        let apiData = yield call(
          api,
          `/dashboard/companies/${data.companyid}/users.json`,
          "GET",
          null,
          null,
          null
        );

        let invitationId = apiData.userInvitation.filter(
          (userInvitations) => userInvitations.email === data.email
        )[0].id;
        yield putResolve(
          userActions.setGrantAdminPermissionLoading({
            flag: false,
            index: data.index,
          })
        );
        yield putResolve(
          userActions.grantPermission({
            id: data.id,
            invitationId: invitationId,
          })
        );

        // toaster
        toast.success("Grant admin permission", {
          className: "toast-success",
        });
      } else {
        yield call(sentryErrorCatch, "API response is empty", "#146");
        //toaster
        toast.error("Something Went Wrong #146", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#147");
      //toaster
      toast.error("Something Went Wrong #147", {
        className: "toast-danger",
      });
      console.log("error in grantPermissionSaga ", err);
    }
  }
}

function* removePermissionSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(userActions.removePermission().type);
      yield putResolve(
        userActions.setRemovePermissionLoading({
          flag: true,
          index: data.index,
        })
      );
      const { me, csrfToken } = yield select(authSelector((state) => state));
      let apiData;

      if (data.invitationId === 0) {
        let result = yield call(
          api,
          `/organizations/${data.companyid}/pk/?email=${data.email}`,
          "GET",
          null,
          null,
          csrfToken
        );

        if (result.exists === true) {
          // invite data
          let inviteData = {
            "invitation[email]": data.email,
            "invitation[group_public_key_encrypted]": "",
            "invitation[admin]": false,
            "invitation[share_type]": "organization",
            clear: true,
            group_id: "",
            organization_id: data.companyid,
          };
          const form_data = new FormData();

          form_data.append(
            "invitation[email]",
            inviteData["invitation[email]"]
          );
          form_data.append(
            "invitation[group_public_key_encrypted]",
            inviteData["invitation[group_public_key_encrypted]"]
          );
          form_data.append("group_id", inviteData["group_id"]);
          form_data.append("organization_id", inviteData["organization_id"]);
          form_data.append(
            "invitation[admin]",
            inviteData["invitation[admin]"]
          );
          form_data.append("clear", true);
          form_data.append("invitation[share_type]", "organization");

          apiData = yield call(
            api,
            `/invitations.json`,
            "POST",
            null,
            form_data,
            csrfToken
          );
        }
      } else {
        apiData = yield call(
          api,
          `/invitations/${data.invitationId}.json`,
          "DELETE",
          null,
          null,
          csrfToken
        );
      }

      //response back from api
      // { "status": true, "organization_invite_deleted": null }

      if (apiData.status === true || apiData.success === true) {
        yield putResolve(
          userActions.setRemovePermissionLoading({
            flag: false,
            index: data.index,
          })
        );
        yield putResolve(userActions.removeAccess(data.id));
        // toaster
        toast.success("Remove admin permission", {
          className: "toast-success",
        });
      } else {
        yield call(sentryErrorCatch, "API response is empty", "#148");
        //toaster
        toast.error("Something Went Wrong #148", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#149");
      console.log("error in removePermissionSaga", err);
      //toaster
      toast.error("Something Went Wrong #149", {
        className: "toast-danger",
      });
    }
  }
}

function* deleteUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(userActions.deleteUser().type);

      const { csrfToken } = yield select(authSelector((state) => state));

      let apiData;
      apiData = yield call(
        api,
        `/organizations/${data.organization_id}/users/${data.user_id}`,
        "DELETE",
        null,
        null,
        csrfToken
      );

      // Response={"success":true,"subscription_check":"subscribed"}

      if (apiData.success === true) {
        yield putResolve(userActions.removeUser(data.user_id));
        // toaster
        toast.success("User deleted", {
          className: "toast-success",
        });
      } else {
        yield call(sentryErrorCatch, "API response is empty", "#150");
        //toaster
        toast.error("Something Went Wrong #150", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#151");
      console.log("error in deleteUserSaga", err);
      //toaster
      toast.error("Something Went Wrong #151", {
        className: "toast-danger",
      });
    }
  }
}

function* removeUserFromTeam() {
  while (true) {
    try {
      const { payload: data } = yield take(userActions.removeTeam().type);

      const { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();
      form_data.append("email", data.email);
      form_data.append("organization_id", data.organizationId);
      form_data.append("group_id", data.groupId);

      let result = yield call(
        api,
        `/organizations/${data.organizationId}/delete_user_from_group`,
        "POST",
        null,
        form_data,
        csrfToken
      );

      if (result === true) {
        yield putResolve(
          userActions.removeTeamUser({
            teamIndex: data.teamIndex,
            userIndex: data.userIndex,
          })
        );
        // toaster
        toast.success("User removed successfully!", {
          className: "toast-success",
        });
      } else {
        yield call(sentryErrorCatch, "API response is empty", "#152");
        //toaster
        toast.error("Something Went Wrong #152", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#153");
      console.log("error in removeUserFromTeam", err);
      //toaster
      toast.error("Something Went Wrong #153", {
        className: "toast-danger",
      });
    }
  }
}

function* redirectURLSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(userActions.redirectURL().type);
      data.history.push(data.url);
    } catch (err) {
      yield call(sentryErrorCatch, err, "#154");
      console.log("error in  redirectURLSaga", err);
      toast.error("Something Went Wrong #154", {
        className: "toast-danger",
      });
    }
  }
}

export default function* userPageRootSaga() {
  yield spawn(userPageSaga);
  yield spawn(deleteUserSaga);
  yield spawn(grantPermissionSaga);
  yield spawn(removePermissionSaga);
  yield spawn(removeUserFromTeam);
  yield spawn(redirectURLSaga);
}
