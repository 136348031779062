import {
  decrypt_object,
  decrypt_needed,
  decrypt_number,
  decrypt_string,
  decrypt_boolean,
} from "../apiUtils";
import { ckServiceSymKey } from "./AccountEncryptionRules";
export function decryptObjectMarkingCorrupt(object, symKey) {
  if (object == null) {
    return null;
  }

  var result = {};

  for (let key in object) {
    if (typeof object[key] == "string" && !decrypt_needed(object[key])) {
      result[key] = object[key];
    } else {
      try {
        result[key] = decrypt(object[key], symKey);
      } catch (e) {
        result.corrupt = true;
      }
    }
  }

  return result;
}

export function decrypt(value, symKey) {
  if (typeof value == "object") {
    return decrypt_object(value, symKey);
  } else {
    if (typeof value == "string") {
      var encPieces = value.split(":");
      var encString = encPieces[0];
      var decType = encPieces[1];

      if (decType == "number") {
        return decrypt_number(encString, symKey);
      } else {
        if (decType == "string") {
          return decrypt_string(encString, symKey);
        } else {
          if (decType == "boolean") {
            return decrypt_boolean(encString, symKey);
          }
        }
      }
    } else {
      return value;
    }
  }
}

export function isCorruptAfterDecryption(
  object,
  // commonkey,
  // cksession,
  orgSymKey,
  grpSymKey
) {
  var sym_Key = ckServiceSymKey(object, orgSymKey, grpSymKey);
  var decrypted = decryptObjectMarkingCorrupt(object, sym_Key);
  return !!decrypted.corrupt;
}
