import { putResolve, call, spawn, take } from "redux-saga/effects";
import { confirmationEmailPageActions } from "./store";
import { api } from "../../../helpers/api";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* confirmationEmailPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        confirmationEmailPageActions.sendEmailConfirmation().type
      );
      yield putResolve(
        confirmationEmailPageActions.setSendConfirmationLoading(true)
      );
      const form_data = new FormData();

      form_data.append("email", data.email);

      // saving to data to DB
      const result = yield call(
        api,
        `/check_user_for_send_confirmation`,
        "POST",
        null,
        form_data
      );
      yield putResolve(
        confirmationEmailPageActions.setSendConfirmationLoading(false)
      );
      if (result.status) {
        if (result.type === "info") {
          yield putResolve(
            confirmationEmailPageActions.setNotification(result.status)
          );

          yield putResolve(
            confirmationEmailPageActions.resetNotification(true)
          );
        }
        if (result.type === "redirect") {
          data.history.push(`/login?notify=${result.status}`);
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#112")
      console.log("error in sendEmailConfirmation", err);
      toast.error("Something Went Wrong #112", {
        className: "toast-danger",
      });
    }
  }
}
export default function* confirmationEmailPageRootSaga() {
  yield spawn(confirmationEmailPageSaga);
}
