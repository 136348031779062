import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { api } from "../../../helpers/api";
import {
  rsaEncrypt,
  exportOrganizationAccounts,
  accountFormDataMatchesExistingAccount,
  accountDataFromAccountFormData,
  get_g_s,
  encrypt,
  sym_key,
  decrypt,
  subtleRSAEncrypt,
} from "../../../helpers/apiUtils";
import { settingsTabActions, settingsTabSelector } from "./store";
import { getLatestUserData } from "../../../helpers/apiUtils";
import { authActions, authSelector } from "../../auth/store";
import { toast } from "react-toastify";
import { org_sym_key } from "../../../helpers/apiUtils";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* settingsTabSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(settingsTabActions.loadData().type);
      yield putResolve(settingsTabActions.setLoading(true));
      let result = yield call(
        api,
        `/dashboard/companies/${data.companyid}/teams.json`
      );
      yield putResolve(settingsTabActions.setLoading(false));
      yield putResolve(settingsTabActions.setData(result));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#160");
      toast.error("Something Went Wrong #160", {
        className: "toast-danger",
      });
    }
  }
}
function* updateOrganizationSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingsTabActions.updateOrganization().type
      );

      const form_data = new FormData();

      form_data.append("_method", "patch");
      form_data.append("organization[name]", data.organizationName);
      form_data.append("organization[industry_type]", data.industryType);
      let result = yield call(
        api,
        `/organizations/${data.companyid}.json`,
        "POST",
        null,
        form_data
      );

      if (result.success === true) {
        const user_me_data = yield call(getLatestUserData);
        yield putResolve(authActions.setMe(user_me_data));

        yield putResolve(
          settingsTabActions.setCurrentCompany(result.organization)
        );
        toast.success("Organization updated successfully!", {
          className: "toast-success",
        });
      }
      //show notification
      yield putResolve(settingsTabActions.setCompanyLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#161");
      console.log("error in updateOrganizationSaga", err);
      toast.error("Something Went Wrong #161", {
        className: "toast-danger",
      });
    }
  }
}
function* exportSecretsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingsTabActions.exportSecrets().type
      );
      yield putResolve(settingsTabActions.setExportSecretLoading(true));
      //   yield putResolve(billingActions.setLoading(true));
      const { csrfToken, me } = yield select(authSelector((state) => state));

      //adding timestamp at the end
      let timestamp = new Date();
      timestamp = timestamp.getTime();

      let result = yield call(
        api,
        `/organizations/${data.companyid}/export.json?_=${timestamp}`,
        "GET",
        null,
        null,
        csrfToken
      );
      yield putResolve(settingsTabActions.setExportSecretLoading(false));
      if (result.success === true) {
        try {
          yield call(exportOrganizationAccounts, result.accounts, me);
          toast.success("Secrets exported!", {
            className: "toast-success",
          });
        } catch (err) {
          yield call(sentryErrorCatch, err, "#162");
          toast.error("Something Went Wrong #162", {
            className: "toast-danger",
          });
          //show notification something went wrong!
          //happens when Some secrets are corrupted
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#163");
      console.log("error in exportSecretsSaga", err);
      toast.error("Something Went Wrong #163", {
        className: "toast-danger",
      });
    }
  }
}
function* transferOwnershipSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingsTabActions.transferOwnership().type
      );
      let org = data.company;

      yield putResolve(settingsTabActions.setTransferOwnershipLoading(true));
      const { csrfToken, me } = yield select(authSelector((state) => state));

      let pkResult = yield call(
        api,
        `/pkid/${data.transferOwnershipUserId}`,
        "GET",
        null,
        null,
        csrfToken
      );

      //if (pkResult) {
      let orgSymKey = yield call(org_sym_key, me, org.id);
      let encrypt_org_key = "";
      if (pkResult.asymmetric_key_algo === "RSA-OAEP") {
        encrypt_org_key = yield subtleRSAEncrypt(pkResult.pk, orgSymKey);
      } else {
        encrypt_org_key = rsaEncrypt(pkResult.pk, orgSymKey);
      }
      const form_data = new FormData();

      form_data.append("IOPkey", encrypt_org_key);
      form_data.append("organization[user_id]", data.transferOwnershipUserId);
      let result = yield call(
        api,
        `/organizations/${org.id}`,
        "PUT",
        null,
        form_data,
        csrfToken
      );

      if (result.success === true) {
        //show notification
        toast.success("Ownership transfered successfully!", {
          className: "toast-success",
        });
        yield putResolve(settingsTabActions.loadData({ companyid: org.id }));
      }
      yield putResolve(settingsTabActions.setTransferOwnershipLoading(false));
      // }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#164");
      console.log("error in transferOwnershipSaga", err);
      toast.error("Something Went Wrong #164", {
        className: "toast-danger",
      });
    }
  }
}
function* cancelTransferOwnershipSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingsTabActions.cancelTransferOwnership().type
      );
      yield putResolve(
        settingsTabActions.setCancelTransferOwnershipLoading(true)
      );
      const { csrfToken } = yield select(authSelector((state) => state));

      let result = yield call(
        api,
        `/organizations/${data.companyid}/cancel_transfer`,
        "GET",
        null,
        null,
        csrfToken
      );

      if (result.success === true) {
        yield putResolve(
          settingsTabActions.loadData({ companyid: data.companyid })
        );
        toast.success("Ownership transfer cancelled!", {
          className: "toast-success",
        });
      }
      yield putResolve(
        settingsTabActions.setCancelTransferOwnershipLoading(false)
      );
    } catch (err) {
      yield call(sentryErrorCatch, err, "#165");
      console.log("error in cancelTransferOwnershipSaga", err);
      toast.error("Something Went Wrong #165", {
        className: "toast-danger",
      });
    }
  }
}
function* deleteCompanySaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingsTabActions.deleteCompany().type
      );
      yield putResolve(settingsTabActions.setDeleteCompanyLoading(true));
      const { csrfToken, me } = yield select(authSelector((state) => state));
      const form_data = new FormData();

      form_data.append("_method", "delete");
      form_data.append("authenticity_token", csrfToken);
      let result = yield call(
        api,
        `/organizations/${data.companyid}.json`,
        "POST",
        null,
        form_data,
        csrfToken
      );

      if (result.success === true) {
        //delete company from me obj
        let orgs = me.orgs.filter((org) => org.id !== data.companyid);
        delete me.orgs_by_id[data.companyid];
        me["orgs"] = orgs;
        yield putResolve(settingsTabActions.setDeleteCompanyLoading(false));
        toast.success("Company deleted successfully!", {
          className: "toast-success",
        });
        data.history.push("/dashboard");
        yield putResolve(authActions.setMe(me));
        //show notification
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#166");
      console.log("error in deleteCompanySaga", err);
      toast.error("Something Went Wrong #166", {
        className: "toast-danger",
      });
    }
  }
}
function* importSecretsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingsTabActions.importSecrets().type
      );
      yield putResolve(settingsTabActions.setImportSecretLoading(true));

      const { csrfToken, me } = yield select(authSelector((state) => state));

      const accountsParamsFormData = new FormData();

      let csvTextToJson = data.csvTextToJson;

      const titles = csvTextToJson.meta.fields;
      var csvJsonObject =
        csvTextToJson.data.length > 0 ? csvTextToJson.data : [];
      //package return one additional row so removing it
      csvJsonObject = csvJsonObject.slice(0, -1);

      //checking for comma seprated teams
      var accountsData = [];
      var errors = [];

      const requiredFields = [
        "login",
        "password",
        "notes",
        "auto_login",
        "url",
        "application",
        "redirect_page_title",
        "cipher",
      ];

      requiredFields.forEach((reqTitle) => {
        if (
          !titles.includes(reqTitle) &&
          reqTitle !== "application" &&
          reqTitle !== "redirect_page_title" &&
          reqTitle !== "password" &&
          reqTitle !== "cipher"
        ) {
          errors.push(`Missing Field: ${reqTitle}`);
        }
      });
      let applicationError = !titles.includes("application")
        ? !titles.includes("redirect_page_title")
          ? errors.push("Missing Field: application/redirect_page_title in CSV")
          : null
        : null;

      let cipherError = !titles.includes("cipher")
        ? !titles.includes("password")
          ? errors.push("Missing Field: Password/Cipher in CSV")
          : null
        : null;

      if (titles.includes("teams")) {
        csvJsonObject.forEach((account) => {
          let hasManyTeams = account.teams.includes(",");
          if (hasManyTeams === true) {
            let teams = account.teams.split(",");
            teams.forEach((team) => {
              accountsData.push({ ...account, teams: team });
            });
          } else {
            accountsData.push(account);
          }
        });
      } else {
        accountsData = csvJsonObject;
      }

      if (data.useTeamsColumnInCsv !== true && titles.includes("teams")) {
        errors.push(
          "It looks like there is teams column in csv file but you choose wrong option"
        );
      }
      if (data.useTeamsColumnInCsv === true && !titles.includes("teams")) {
        errors.push("It looks like there is no teams column in csv file");
      }

      let all_accounts = yield call(
        api,
        `/me/all_accounts`,
        "GET",
        null,
        null,
        csrfToken
      );
      let accountsParams = [];
      let organizationId = data.company.id;
      for (let i = 0; i < accountsData.length; i++) {
        let find_by_hostnames;

        let accountData = accountsData[i];
        if (
          accountData.auto_login === "1" ||
          accountData.auto_login === "y" ||
          accountData.auto_login === "Y"
        ) {
          const form_data = new FormData();

          form_data.append("hostnames[]", accountData.url);

          find_by_hostnames = yield call(
            api,
            `/domain_dictionaries/find_by_hostnames.json`,
            "POST",
            null,
            form_data,
            csrfToken
          );
        }
        let domainDictionary =
          find_by_hostnames && find_by_hostnames.length > 0
            ? find_by_hostnames[0]
            : undefined;

        if (
          accountFormDataMatchesExistingAccount(
            all_accounts,
            accountData,
            domainDictionary
          )
        ) {
          return;
        }
        let unencryptedAccount = accountDataFromAccountFormData(
          accountData,
          domainDictionary
        );
        var encryptedAccount = {};

        let type = accountData["type"] ? accountData["type"] : "organization";
        let organization_sym_key = data.company.organization_sym_key;
        switch (type) {
          case "user":
            encryptedAccount = encrypt(unencryptedAccount, sym_key());
            break;
          case "organization":
            encryptedAccount = encrypt(
              unencryptedAccount,
              decrypt(organization_sym_key, sym_key())
            );
            break;
          default:
            encryptedAccount = "";
        }
        if (encryptedAccount) {
          encryptedAccount["auto_login"] =
            typeof domainDictionary != "undefined";
        } else {
          encryptedAccount = [];
        }

        let groupDatas = [];
        if (
          type === "organization" &&
          data.useTeamsColumnInCsv === true &&
          titles.includes("teams")
        ) {
          let groupName = accountData.teams;

          let org = me.orgs_by_id[organizationId];
          let groupData = org.groups.filter(
            (group) => group.name === groupName
          );
          if (groupData.length > 0) groupDatas.push(groupData[0]);
          else {
            errors.push(`No group named ${groupName} found`);
          }
          // }
        } else {
          let team_ids = data.selectedTeamsOptions.map((team) => team.id);
          team_ids.forEach((id) => {
            let org = me.orgs_by_id[organizationId];
            let groupData = org.groups.filter((group) => group.id === id);

            if (groupData.length > 0) {
              groupDatas.push(groupData[0]);
            } else {
              errors.push(`No group with ID ${id} found`);
            }
          });
        }
        if (errors.length === 0) {
          encryptedAccount["organization_group_accounts"] = [];

          for (let j = 0; j < groupDatas.length; j++) {
            let groupData = groupDatas[j];

            let unencryptedGroupAccount = Object.assign(
              true,
              {},
              unencryptedAccount
            );
            let groupKey = get_g_s(me, organizationId, groupData.id);

            let encryptedGroupAccount = encrypt(
              unencryptedGroupAccount,
              groupKey
            );

            encryptedGroupAccount["organization_group_id"] = groupData.id;

            encryptedGroupAccount["auto_login"] = encryptedAccount.auto_login;
            encryptedAccount["organization_group_accounts"].push(
              encryptedGroupAccount
            );

            accountsParams.push(encryptedAccount);
          }

          for (let key in encryptedAccount) {
            if (key !== "organization_group_accounts") {
              accountsParamsFormData.append(
                `organization_accounts[${i}][${key}]`,
                encryptedAccount[key]
              );
            }
          }
          if (
            encryptedAccount.organization_group_accounts &&
            encryptedAccount.organization_group_accounts
          ) {
            for (
              let k = 0;
              k < encryptedAccount.organization_group_accounts.length;
              k++
            ) {
              let groupSecret = encryptedAccount.organization_group_accounts[k];
              for (let grpKey in groupSecret) {
                accountsParamsFormData.append(
                  `organization_accounts[${i}][organization_group_accounts][${k}][${grpKey}]`,
                  groupSecret[grpKey]
                );
              }
            }
          }
        }
      }

      if (errors.length === 0) {
        let imports = yield call(
          api,
          `/organizations/${data.company.id}/imports.json`,
          "POST",
          null,
          accountsParamsFormData,
          csrfToken
        );

        if (imports.success === true) {
          const user_me_data = yield call(getLatestUserData);
          yield putResolve(authActions.setMe(user_me_data));
          yield putResolve(settingsTabActions.setImportSecretModal(false));
          toast.success("secrets imported successfully!", {
            className: "toast-success",
          });
        }
      } else {
        yield putResolve(settingsTabActions.setImportSecretError([...errors]));
      }
      yield putResolve(settingsTabActions.setImportSecretLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#167");
      console.log("error in importSecretsSaga", err);
      toast.error("Something Went Wrong #167", {
        className: "toast-danger",
      });
    }
  }
}

export default function* settingsTabPageRootSaga() {
  yield spawn(settingsTabSaga);
  yield spawn(updateOrganizationSaga);
  yield spawn(exportSecretsSaga);
  yield spawn(transferOwnershipSaga);
  yield spawn(cancelTransferOwnershipSaga);
  yield spawn(deleteCompanySaga);
  yield spawn(importSecretsSaga);
}
