import { state } from "../../../../store";

export const [
  teamTabEditDeleteActions,
  teamTabEditDeleteSelector,
  teamTabEditDeleteState,
] = state("editDeleteSecretTeamTab", {
  initial: {
    userAccount: { item: {}, loading: false },
    loading: false,
  },
  on: {
    loadSecret: (_, result) => {
      const newStoreObj = { ..._ };
      newStoreObj.userAccount.loading = false;
      newStoreObj.userAccount.item = result;
      return newStoreObj;
    },
    setLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        loading: data,
      };
    },
    resetState: (_) => {
      return {
        userAccount: { item: {}, loading: false },
        loading: true,
      };
    },
  },

  events: [
    "teamSecretById",
    "deleteOrganizationTeamSecret",
    "updateOrganizationTeamSecret",
  ],
});
