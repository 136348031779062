import React, { Component } from "react";
import { Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

class SecretsTable extends Component {
  sorting = (result) => {
    this.props.handleSorting(result);
  };

  searching = (result) => {
    this.props.handleSearching(result);
  };

  getSecretId = (secret) => {
    this.props.handleGetSecretId(secret);
  };

  render() {
    const { secrets, secretType, history } = this.props;
    const PersonalSecretsList = () => {
      return secrets && secrets.length > 0 ? (
        this.props.secrets.map((secret, index) => {
          return (
            <tr
              key={index}
              onClick={() => this.getSecretId(secret)}
              className="lineHeight"
            >
              <td className="p-1" style={{ width: "30px" }}>
                <img
                  src={`https://favicons.commonkey.com/?host=${secret.redirect_page_hostname}`}
                  alt=""
                  className=" imageSize align-self-center"
                  height="16"
                />
              </td>
              <td className="d-none d-md-table-cell">
                <p
                  className="secret-list-items-ellipses m-0"
                  title={secret.page_title}
                >
                  {secret.page_title}
                </p>{" "}
              </td>
              {secret.auto_login ? (
                <td>
                  <p
                    className="secret-list-items-ellipses-hostname m-0"
                    title={secret.login_page_hostname}
                  >
                    {secret.login_page_hostname}
                  </p>
                </td>
              ) : (
                <td>
                  <p
                    className="secret-list-items-ellipses m-0"
                    title={secret.url}
                  >
                    {secret.url}
                  </p>
                </td>
              )}
              <td>
                <p
                  className="secret-list-items-ellipses m-0"
                  title={secret.login}
                >
                  {secret.login}
                </p>
              </td>
              {secretType === "org" && (
                <td>
                  <p className="secret-list-items-ellipses m-0">
                    {secret?.teams?.map((team, index) => {
                      return (
                        <Link
                          to={"#"}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            history.push(
                              `/dashboard/companies/${secret.organization_id}/teams/${team.id}`
                            );
                          }}
                          className="btn-link cursor-pointer"
                        >
                          {index === 0 ? "" : ", "} {team.name}
                        </Link>
                      );
                    })}
                  </p>
                </td>
              )}
            </tr>
          );
        })
      ) : this.props.searchText.length > 0 ? (
        <tr>
          <td className="mt-2 text-center" colSpan="4">
            <p>Secret Not Found !</p>
          </td>
        </tr>
      ) : (
        <tr>
          <td className="mt-2 text-center" colSpan="4">
            <p>No Secret !</p>
          </td>
        </tr>
      );
    };

    return (
      <div className="table-responsive">
        <Table className="table-centered mb-0 cursor-pointer table-nowrap mb-0 table-fixed table table-hover personal-secret-scroll">
          <thead>
            <tr className="thead-light">
              <th style={{ width: "15px" }}></th>
              <th
                className="d-none d-md-table-cell"
                onClick={() =>
                  this.sorting({
                    fieldName: "login_page_hostname",
                    sortBy: "STRING",
                  })
                }
              >
                <span>
                  Name <i className="uil-sort cursor-pointer"></i>
                </span>{" "}
              </th>
              <th
                onClick={() =>
                  this.sorting({
                    fieldName: "url",
                    sortBy: "STRING",
                  })
                }
              >
                Hostname <i className="uil-sort cursor-pointer"></i>
              </th>
              <th
                onClick={() =>
                  this.sorting({
                    fieldName: "login",
                    sortBy: "STRING",
                  })
                }
              >
                Login <i className="uil-sort cursor-pointer"></i>
              </th>
              {secretType === "org" && <th>Teams</th>}
            </tr>
          </thead>
          <tbody className="scroller-style">{PersonalSecretsList()}</tbody>
        </Table>
      </div>
    );
  }
}
export default withRouter(SecretsTable);
