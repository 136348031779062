import { call, spawn, take, select, putResolve } from "redux-saga/effects";
import { addSecretStepActions } from "./store";
import { api } from "../../../helpers/api";
import {
  decryptAccountsData,
  wrapHashKeys,
  encrypt,
  sym_key,
  accountFormDataMatchesExistingAccount,
  remove_placeholders_from_login_string,
  decrypt_object,
  decrypt,
  encrypt_for_rails,
  update_group_account_json,
  getLatestUserData,
  checkOrganaizationGroupAccounts,
  accountDataFromAccountFormData,
} from "../../../helpers/apiUtils";
import { authActions, authSelector } from "../../auth/store";
import { toast } from "react-toastify";
import { secretActions } from "../../../components/secrets/store";
import {
  convert_encrypt_to_decrypt_based_on_type,
  get_only_org_sym_key,
} from "../../../helpers/organization";
import { org_sym_key } from "../../../helpers/apiUtils";
import {
  getExistingSecretInTeam,
  get_group,
  get_org,
  is_not_admin,
} from "../../../helpers/ckService/UserDataHelper";
import { get_g_s } from "../../../helpers/session";
import { sorting } from "../../../helpers";
import sentryErrorCatch from "../../../helpers/sentryUtils";
import { ckServiceSymKey } from "../../../helpers/ckService/AccountEncryptionRules";
import { decryptObjectMarkingCorrupt } from "../../../helpers/ckService/EncryptionHelper";

function* SecretTabAddPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        addSecretStepActions.loadData().type
      );
      yield putResolve(addSecretStepActions.setLoading(true));
      const { csrfToken, me } = yield select(authSelector((state) => state));
      let user_data = me;

      let domainDictionaries;
      let existingSecret;
      let decryptedUserSecret = [];
      let decryptedOrganizationSecret = [];
      let secretWithTeams;

      //List of domain dictionaries
      domainDictionaries = yield call(
        api,
        `/domain_dictionaries/all`,
        null,
        null,
        null,
        csrfToken
      );

      // Existing secret
      existingSecret = yield call(
        api,
        `/dashboard/companies/${data.organization_id}/teams/${data.team_id}/existing_accounts.json`, //org id and team id
        null,
        null,
        null,
        csrfToken
      );

      if (
        existingSecret &&
        existingSecret.accounts &&
        existingSecret.empty != true
      ) {
        let userSecret = existingSecret.accounts.filter(
          (secret) => secret.class_name === "UserAccount"
        );

        decryptedUserSecret = decrypt_object(userSecret, sym_key());

        let orgahanizationSecret = existingSecret.accounts.filter(
          (secret) => secret.class_name === "OrganizationAccount"
        );

        decryptedOrganizationSecret = convert_encrypt_to_decrypt_based_on_type(
          orgahanizationSecret,
          user_data
        );

        secretWithTeams = decryptedOrganizationSecret.map((secret) => {
          let teams = getExistingSecretInTeam(
            user_data,
            secret.organization_id,
            secret.id
          );
          if (teams.length > 0) {
            return { ...secret, teams };
          } else {
            let org = get_org(user_data, secret.organization_id);
            return { ...secret, org };
          }
        });
      } else {
        secretWithTeams = [];
        decryptedUserSecret = [];
      }

      let sortedDomainsDictionary = sorting(domainDictionaries.all_domains, {
        sortBy: "STRING",
        fieldName: "title",
        sortType: true,
      });

      yield putResolve(addSecretStepActions.setLoading(false));
      yield putResolve(
        secretActions.loadAddSecret({
          domains: sortedDomainsDictionary,
          existingSecret: secretWithTeams,
          personalSecret: decryptedUserSecret,
          from: "secret-tab",
        })
      );
    } catch (err) {
      yield call(sentryErrorCatch, err, "#131");
      // toaster
      toast.error("Something went wrong #131", {
        className: "toast-danger",
      });
      console.log("error in SecretTabAddPageSaga");
    }
  }
}

function* getTeamsDataSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        addSecretStepActions.loadTeamsData().type
      );
      let result = yield call(
        api,
        `/dashboard/companies/${data.companyid}/teams.json`
      );
      yield putResolve(secretActions.setTeamsData(result));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#225");
      toast.error("Something Went Wrong #225", {
        className: "toast-danger",
      });
    }
  }
}

function* addSecretAutoSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        addSecretStepActions.addSecretAuto().type
      );

      yield putResolve(secretActions.setAddSecretButtonLoading(true));
      var error;
      // const { team, secrets } = yield select(
      //   teamsTabSelector((state) => state.currentCompany)
      // );

      data["group"] = { id: data.id };
      let organization_group_account = [];

      // csrfToken
      const { csrfToken, me } = yield select(authSelector((state) => state));

      const user_data = me;
      let apiData;
      if (csrfToken) {
        // all_accounts API
        const result = yield call(
          api,
          `/me/all_accounts`,
          null,
          null,
          null,
          csrfToken
        );
        // domain_dictionaries API required for Auto-Login
        const domain_dictionaries = yield call(
          api,
          `/domain_dictionaries/${data.application_id}`,
          null,
          null,
          null,
          csrfToken
        );

        // decryptAccountsData
        var accounts = yield call(decryptAccountsData, result, data, user_data);
        // first record of domain_dictionaries
        var dd = domain_dictionaries.domain_dictionary;
        // accountAlreadyExist is the flag for manager and memeber duplicate secret
        let accountAlreadyExist = false;

        // accountFormDataMatchesExistingAccount for auto-login to check account is already added or not for Owner and Admin
        if (accountFormDataMatchesExistingAccount(accounts, data, dd)) {
          yield putResolve(secretActions.setAddSecretButtonLoading(false));
          toast.error("Account already added", {
            className: "toast-danager",
          });
        } else {
          // add a type organization
          data["type"] = "organization";

          let accountFormData = {};
          accountFormData.login = data.login;
          accountFormData.cipher = data.password;
          accountFormData.notes = data.notes;
          accountFormData.url = domain_dictionaries.domain_dictionary.login_url;
          accountFormData.page_title =
            domain_dictionaries.domain_dictionary.title;
          accountFormData.redirect_page_title =
            domain_dictionaries.domain_dictionary.title;
          accountFormData.redirect_page_hostname =
            domain_dictionaries.domain_dictionary.url;
          accountFormData.redirect_origin =
            domain_dictionaries.domain_dictionary.login_url;
          accountFormData.login_page_title =
            domain_dictionaries.domain_dictionary.title;
          accountFormData.login_page_hostname =
            domain_dictionaries.domain_dictionary.url;
          accountFormData.login_origin =
            domain_dictionaries.domain_dictionary.login_url;
          accountFormData.login_string = remove_placeholders_from_login_string(
            domain_dictionaries.domain_dictionary.login_string,
            data.login,
            data.password
          );

          const form_data = new FormData();
          for (let key in accountFormData) {
            // Form Data
            form_data.append(
              `organization_account[${key}]`,
              accountFormData[key]
            );
          }
          var type = data["type"];

          // rename
          var save = wrapHashKeys(accountFormData, type + "_" + "account");

          // encrypt
          var save_admin = {};
          var symKey;
          // check the user and get sym key
          symKey = yield call(org_sym_key, user_data, data.organization_id);

          // return;
          save_admin = encrypt(save, symKey);

          save_admin[type + "_account[auto_login]"] = typeof dd != "undefined";

          const save_adminFormData = new FormData();
          for (let key in save_admin) {
            save_adminFormData.append(key, save_admin[key]);
          }

          // API
          apiData = yield call(
            api,
            `/organizations/${data.organization_id}/accounts.json`,
            "POST",
            null,
            save_adminFormData,
            csrfToken
          );

          if (apiData.success === true) {
            if (data.type === "organization" && data["group"]) {
              var group = data.group;
              // rename for group save
              var save = wrapHashKeys(
                accountFormData,
                "organization_group_account"
              );
              // save encrypted for each group

              // encrypt
              var gsk = yield call(
                get_g_s,
                data.organization_id,
                group.id,
                user_data
              );

              var save_group = encrypt(save, gsk);

              // id of account just saved
              save_group[
                "organization_group_account[organization_account_id]"
              ] = apiData.organization_account.id;

              // auto login
              save_group["organization_group_account[auto_login]"] =
                apiData.organization_account.auto_login;
              const org_form_data = new FormData();
              for (let key in save_group) {
                org_form_data.append(key, save_group[key]);
              }

              apiData = yield call(
                api,
                `/organizations/${data.organization_id}/groups/${group.id}/accounts.json`,
                "POST",
                null,
                org_form_data,
                csrfToken
              );
            }

            const user_me_data = yield call(getLatestUserData);
            yield putResolve(authActions.setMe(user_me_data));
            yield putResolve(
              addSecretStepActions.loadOrganizationGroupSecret({
                organization_id: data.organization_id,
                team_id: data.id,
              })
            );
            yield putResolve(
              addSecretStepActions.loadData({
                organization_id: data.organization_id,
                team_id: data.id,
              })
            );
            // toaster
            toast.success("Secret Added Successfully", {
              className: "toast-success",
            });
            yield putResolve(secretActions.setAddSecretButtonLoading(false));
          }
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#132");
      // toaster
      toast.error("Something went wrong #132", {
        className: "toast-danger",
      });
      console.log("error in addAutoLoginPersonalSecretSaga", err);
    }
  }
}

function* addSecretCustomSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        addSecretStepActions.addSecretCustom().type
      );
      yield putResolve(secretActions.setAddSecretButtonLoading(true));
      // const team = yield select(
      //   teamsTabSelector((state) => state.currentCompany.team)
      // );

      data["group"] = { id: data.id };

      // csrfToken
      const { csrfToken, me } = yield select(authSelector((state) => state));
      // all_accounts API
      const result = yield call(
        api,
        `/me/all_accounts`,
        null,
        null,
        null,
        csrfToken
      );

      let apiData;
      if (csrfToken) {
        // domain_dictionaries API (id should be undefine)
        const domain_dictionaries = yield call(
          api,
          `/domain_dictionaries/${data["application_id"]}`,
          null,
          null,
          null,
          csrfToken
        );
        // add a type organization
        data["type"] = "organization";

        //accountDataFromAccountFormData only for custom secret add
        let accountFormData = {};
        accountFormData.login = data.login;
        accountFormData.cipher = data.password;
        accountFormData.notes = data.notes;
        accountFormData.url = data.url;
        accountFormData.page_title = data.application;
        accountFormData.redirect_page_title = data.application;
        accountFormData.redirect_page_hostname = data.application;
        accountFormData.redirect_origin = "";
        accountFormData.login_page_title = data.application;
        accountFormData.login_page_hostname = data.application;
        accountFormData.login_origin = "";
        accountFormData.login_string = "";
        accountFormData.auto_login = false;

        const form_data = new FormData();
        for (let key in accountFormData) {
          form_data.append(
            `organization_account[${key}]`,
            accountFormData[key]
          );
        }

        var type = data["type"];
        // rename
        var save = wrapHashKeys(accountFormData, type + "_" + "account");

        // encrypt
        var save_admin = {};
        // check the user and get sym key
        var symKey;

        symKey = yield call(org_sym_key, me, data.organization_id);

        save_admin = encrypt(save, symKey);

        const save_adminFormData = new FormData();
        for (let key in save_admin) {
          save_adminFormData.append(key, save_admin[key]);
        }
        // API
        apiData = yield call(
          api,
          `/organizations/${data.organization_id}/accounts.json`,
          "POST",
          null,
          save_adminFormData,
          csrfToken
        );

        if (apiData.success === true) {
          if (data.type === "organization" && data["group"]) {
            var group = data.group;
            // rename for group save
            var save = wrapHashKeys(
              accountFormData,
              "organization_group_account"
            );

            // save encrypted for each group

            // encrypt
            var gsk = yield call(get_g_s, data.organization_id, group.id, me);

            var save_group = encrypt(save, gsk);

            // id of account just saved
            save_group["organization_group_account[organization_account_id]"] =
              apiData.organization_account.id;

            // auto login
            save_group["organization_group_account[auto_login]"] =
              apiData.organization_account.auto_login;

            const org_form_data = new FormData();
            for (let key in save_group) {
              org_form_data.append(key, save_group[key]);
            }

            apiData = yield call(
              api,
              `/organizations/${data.organization_id}/groups/${group.id}/accounts.json`,
              "POST",
              null,
              org_form_data,
              csrfToken
            );
          }

          const user_me_data = yield call(getLatestUserData);
          yield putResolve(authActions.setMe(user_me_data));
          yield putResolve(
            addSecretStepActions.loadOrganizationGroupSecret({
              organization_id: data.organization_id,
              team_id: data.id,
            })
          );
          // toaster
          toast.success("Secret Added Successfully", {
            className: "toast-success",
          });
          yield putResolve(secretActions.setAddSecretButtonLoading(false));
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#133");
      // toaster
      toast.error("Something went wrong #133", {
        className: "toast-danger",
      });
      console.log("error in addCustomPersonalSecretSaga", err);
    }
  }
}

function* existingSecretSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        addSecretStepActions.existingSecret().type
      );
      yield putResolve(secretActions.setAddSecretButtonLoading(true));
      const { csrfToken, me } = yield select(authSelector((state) => state));
      let user_data = me;
      let team = { id: data.id, organization_id: data.organization_id };

      for (let i = 0; i < data.selectedExistingSecret.length; i++) {
        let secret = data.selectedExistingSecret[i];

        // depending if it's either a UserAccount or an OrganizationAccount
        if (secret.class_name === "UserAccount") {
          // get the current user account
          let apiData = yield call(
            api,
            `/user_accounts/${secret.id}.json`,
            "GET",
            null,
            null,
            csrfToken
          );
          if (apiData) {
            // decrypt user account
            var decrypted_account = decrypt(apiData, sym_key());

            // encrypt and generate sym key as per user role
            let symKey;

            symKey = yield call(org_sym_key, user_data, team.organization_id);

            var account_for_org = encrypt_for_rails(decrypted_account, symKey, {
              remove_protected_attrs: true,
            });

            var conversion_url = "";
            if (is_not_admin(team.organization_id, user_data)) {
              // conversion_url = "convert_account_to_organization_group_account"
              // create normaly group account
              var account_data = {
                login: decrypted_account.login,
                notes: decrypted_account.notes,
                password: decrypted_account.cipher,
                url: decrypted_account.url,
                type: "organization",
                organization_id: team.organization_id,
              };

              let group = get_group(user_data, team.id, team.organization_id);
              var groups_hash = {};
              groups_hash["" + team.id] = group.symKey;
              account_data["groups"] = groups_hash;

              if (decrypted_account.auto_login) {
                let domain_dictionaries = yield call(
                  api,
                  `/domain_dictionaries/show_by_hostname?hostnames=${decrypted_account.login_page_hostname}`,
                  "GET",
                  null,
                  null,
                  csrfToken
                );

                if (domain_dictionaries.length > 0 && domain_dictionaries[0]) {
                  account_data["application_id"] = domain_dictionaries[0]["id"];

                  yield call(
                    add_new_account,
                    secret.auto_login,
                    account_data,
                    decrypted_account.id, // for delete the personal secret
                    team
                  );
                }
              } else {
                account_data["application"] = decrypted_account.page_title;

                yield call(
                  add_new_account,
                  secret.auto_login,
                  account_data,
                  decrypted_account.id, // for delete the personal secret
                  team
                );
              }
            } else {
              conversion_url = "convert_account_to_organizational";

              // save to server and remove associated user_accountcalcu

              const form_data = new FormData();
              for (let key in account_for_org) {
                form_data.append(`account[${key}]`, account_for_org[key]);
              }
              form_data.append("organization_id", team.organization_id);
              let convert_account = yield call(
                api,
                `/user_accounts/${secret.id}/convert_account_to_organizational`,
                "POST",
                null,
                form_data,
                csrfToken
              );

              if (convert_account.success === true) {
                var new_org_account = convert_account.organization_account;
                // save account to the appropriate group
                let groupSymKey = yield call(
                  get_g_s,
                  team.organization_id,
                  team.id,
                  user_data
                );
                var account_for_group = encrypt_for_rails(
                  decrypted_account,
                  groupSymKey,
                  { remove_protected_attrs: true }
                );
                // switch organization_account id to new variable for group
                account_for_group.organization_account_id = new_org_account.id;
                delete account_for_group.id;

                const org_form_data = new FormData();
                for (let key in account_for_group) {
                  org_form_data.append(
                    `organization_group_account[${key}]`,
                    account_for_group[key]
                  );
                }

                let addSecretToGroup = yield call(
                  api,
                  `/organizations/${team.organization_id}/groups/${team.id}/accounts.json`,
                  "POST",
                  null,
                  org_form_data,
                  csrfToken
                );
              }
            }
            // end convert user to org
          } else {
            yield call(sentryErrorCatch, "API response is empty", "#134");
            // toaster
            toast.error("Something went wrong #134", {
              className: "toast-danger",
            });
          }
        } else {
          // add an account that is already an existing organizational account to a group
          // get the account first
          let account = yield call(
            api,
            `/organizations/${secret.organization_id}/accounts/${secret.id}.json`,
            "GET",
            null,
            null,
            csrfToken
          );
          if (account) {
            let orgSymKey = yield call(
              org_sym_key,
              user_data,
              secret.organization_id
            );
            var decrypted_account = decrypt(account, orgSymKey);
            let groupSymKey = yield call(
              get_g_s,
              team.organization_id,
              team.id,
              user_data
            );
            var account_for_group = encrypt_for_rails(
              decrypted_account,
              groupSymKey,
              { remove_protected_attrs: true }
            );

            // save stuff from org account
            account_for_group.organization_account_id = decrypted_account.id;
            delete account_for_group.needs_to_be_completed;

            const org_form_data = new FormData();
            for (let key in account_for_group) {
              org_form_data.append(
                `organization_group_account[${key}]`,
                account_for_group[key]
              );
            }

            let addSecretToTeam = yield call(
              api,
              `/organizations/${secret.organization_id}/groups/${team.id}/accounts.json`,
              "POST",
              null,
              org_form_data,
              csrfToken
            );

            if (addSecretToTeam.success === true) {
              update_group_account_json(
                addSecretToTeam.organization_group_account,
                decrypted_account.organization_id,
                user_data
              );
            }
          }
        }
      }

      const user_me_data = yield call(getLatestUserData);
      yield putResolve(authActions.setMe(user_me_data));
      yield putResolve(secretActions.resetSelectedExistingSecret());
      yield putResolve(
        addSecretStepActions.loadData({
          organization_id: data.organization_id,
          team_id: data.id,
        })
      );
      yield putResolve(
        addSecretStepActions.loadOrganizationGroupSecret({
          organization_id: data.organization_id,
          team_id: data.id,
        })
      );
      // toaster
      toast.success("Secret Added Successfully", {
        className: "toast-success",
      });
      yield putResolve(secretActions.setAddSecretButtonLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#135");
      // toaster
      toast.error("Something went wrong #135", {
        className: "toast-danger",
      });
      console.log("err in existingSecretSaga", err);
    }
  }
}

function* add_new_account(flag, payload, deleteId, team) {
  //call auto and custom
  //flag > auto_login flag (true/false)(auto/custom)

  let data = { ...payload };
  // const team = yield select(
  //   teamsTabSelector((state) => state.currentCompany.team)
  // );
  data["group"] = team;

  if (flag === true) {
    //auto personal secret
    // csrfToken
    const { csrfToken, me } = yield select(authSelector((state) => state));
    if (csrfToken) {
      // all_accounts API
      const result = yield call(
        api,
        `/me/all_accounts`,
        null,
        null,
        null,
        csrfToken
      );
      // domain_dictionaries API required for Auto-Login
      const domain_dictionaries = yield call(
        api,
        `/domain_dictionaries/${data.application_id}`,
        null,
        null,
        null,
        null
      );
      // decryptAccountsData
      var accounts = yield call(decryptAccountsData, result, data);

      // first record of domain_dictionaries
      var dd = domain_dictionaries.domain_dictionary;

      // accountFormDataMatchesExistingAccount for auto-login to check account is already added or not
      if (accountFormDataMatchesExistingAccount(accounts, data, dd)) {
        toast.error("Account already added.", {
          className: "toast-danger",
        });
        yield putResolve(secretActions.setAddSecretButtonLoading(false));
      } else {
        // add a type user
        //data["type"] = "user";
        var accounts = yield call(decryptAccountsData, result, data);

        let accountFormData = {};
        accountFormData.login = data.login;
        accountFormData.cipher = data.password;
        accountFormData.notes = data.notes;
        accountFormData.url = domain_dictionaries.domain_dictionary.login_url;
        accountFormData.page_title =
          domain_dictionaries.domain_dictionary.title;
        accountFormData.redirect_page_title =
          domain_dictionaries.domain_dictionary.title;
        accountFormData.redirect_page_hostname =
          domain_dictionaries.domain_dictionary.url;
        accountFormData.redirect_origin =
          domain_dictionaries.domain_dictionary.login_url;
        accountFormData.login_page_title =
          domain_dictionaries.domain_dictionary.title;
        accountFormData.login_page_hostname =
          domain_dictionaries.domain_dictionary.url;
        accountFormData.login_origin =
          domain_dictionaries.domain_dictionary.login_url;
        accountFormData.login_string = remove_placeholders_from_login_string(
          domain_dictionaries.domain_dictionary.login_string,
          data.login,
          data.password
        );

        const form_data = new FormData();
        for (let key in accountFormData) {
          // Form Data
          form_data.append(
            `${data.type}_account[${key}]`,
            accountFormData[key]
          );
        }
        var type = data["type"];

        // rename
        var save = wrapHashKeys(accountFormData, type + "_" + "account");
        // encrypt
        var save_admin = {};

        //save_admin = encrypt(save, sym_key());

        // setup url
        var url = {};
        // based on user account or organization account
        switch (type) {
          case "user":
            save_admin = encrypt(save, sym_key());
            url.path = `/user_accounts.json`;
            break;
          case "organization":
            let orgSymKey = yield call(
              get_only_org_sym_key,
              me,
              data.organization_id
            );
            save_admin = encrypt(save, orgSymKey);
            url.organization_id = data.organization_id;
            url.path = `/organizations/${url.organization_id}/accounts.json`;
          // save_admin[type + "_account[needs_to_be_completed]"] =
          //   argsdup.team_only;
        }

        save_admin[type + "_account[auto_login]"] = typeof dd != "undefined";

        const save_adminFormData = new FormData();
        for (let key in save_admin) {
          save_adminFormData.append(key, save_admin[key]);
        }

        // API
        let apiData = yield call(
          api,
          url.path,
          "POST",
          null,
          save_adminFormData,
          csrfToken
        );

        if (apiData) {
          if (data.type === "organization" && data["group"]) {
            var group = data.group;
            // rename for group save
            var save = wrapHashKeys(
              accountFormData,
              "organization_group_account"
            );

            // save encrypted for each group

            // encrypt
            var gsk = yield call(get_g_s, data.organization_id, group.id, me);

            var save_group = encrypt(save, gsk);

            // id of account just saved
            save_group["organization_group_account[organization_account_id]"] =
              apiData.organization_account.id;

            // auto login
            save_group["organization_group_account[auto_login]"] =
              apiData.organization_account.auto_login;

            const org_form_data = new FormData();
            for (let key in save_group) {
              org_form_data.append(key, save_group[key]);
            }

            apiData = yield call(
              api,
              `/organizations/${data.organization_id}/groups/${group.id}/accounts.json`,
              "POST",
              null,
              org_form_data,
              csrfToken
            );
          }

          yield call(
            api,
            `/user_accounts/${deleteId}`,
            "DELETE",
            null,
            null,
            csrfToken
          );
          // toaster
          toast.success("Secret Added Successfully", {
            className: "toast-success",
          });
        } else {
          console.log("Error in adding user secret");
        }
      }
    }
  } else {
    //custom personal secret
    // csrfToken
    const { csrfToken, me } = yield select(authSelector((state) => state));

    if (csrfToken) {
      // all_accounts API
      const result = yield call(
        api,
        `/me/all_accounts`,
        null,
        null,
        null,
        csrfToken
      );
      // domain_dictionaries API (id should be undefine)
      const domain_dictionaries = yield call(
        api,
        `/domain_dictionaries/${data["application_id"]}`,
        null,
        null,
        null,
        csrfToken
      );
      // add a type user
      //data["type"] = "user";
      var accounts = yield call(decryptAccountsData, result, data);

      //accountDataFromAccountFormData only for custom secret add
      let accountFormData = {};
      accountFormData.login = data.login;
      accountFormData.cipher = data.password;
      accountFormData.notes = data.notes;
      accountFormData.url = data.url;
      accountFormData.page_title = data.application;
      accountFormData.redirect_page_title = data.application;
      accountFormData.redirect_page_hostname = data.application;
      accountFormData.redirect_origin = "";
      accountFormData.login_page_title = data.application;
      accountFormData.login_page_hostname = data.application;
      accountFormData.login_origin = "";
      accountFormData.login_string = "";
      accountFormData.auto_login = false;

      const form_data = new FormData();
      for (let key in accountFormData) {
        form_data.append(`${data.type}_account[${key}]`, accountFormData[key]);
      }

      var type = data["type"];
      // rename
      var save = wrapHashKeys(accountFormData, type + "_" + "account");

      // encrypt
      var save_admin = {};

      //save_admin = encrypt(save, sym_key());

      var url = {};
      // based on user account or organization account
      switch (type) {
        case "user":
          save_admin = encrypt(save, sym_key());
          url.path = `/user_accounts.json`;
          break;
        case "organization":
          let orgSymKey = yield call(
            get_only_org_sym_key,
            me,
            data.organization_id
          );
          save_admin = encrypt(save, orgSymKey);
          url.organization_id = data.organization_id;
          url.path = `/organizations/${url.organization_id}/accounts.json`;
        // save_admin[type + "_account[needs_to_be_completed]"] =
        //   argsdup.team_only;
      }

      const save_adminFormData = new FormData();
      for (let key in save_admin) {
        save_adminFormData.append(key, save_admin[key]);
      }
      // API
      let apiData = yield call(
        api,
        url.path,
        "POST",
        null,
        save_adminFormData,
        csrfToken
      );

      if (apiData) {
        if (data.type === "organization" && data["group"]) {
          var group = data.group;
          // rename for group save
          var save = wrapHashKeys(
            accountFormData,
            "organization_group_account"
          );

          // save encrypted for each group

          // encrypt
          var gsk = yield call(get_g_s, data.organization_id, group.id, me);

          var save_group = encrypt(save, gsk);

          // id of account just saved
          save_group["organization_group_account[organization_account_id]"] =
            apiData.organization_account.id;

          // auto login
          save_group["organization_group_account[auto_login]"] =
            apiData.organization_account.auto_login;
          const org_form_data = new FormData();
          for (let key in save_group) {
            org_form_data.append(key, save_group[key]);
          }

          apiData = yield call(
            api,
            `/organizations/${data.organization_id}/groups/${group.id}/accounts.json`,
            "POST",
            null,
            org_form_data,
            csrfToken
          );
        }

        yield call(
          api,
          `/user_accounts/${deleteId}`,
          "DELETE",
          null,
          null,
          csrfToken
        );
        // toaster
        toast.success("Secret Added Successfully", {
          className: "toast-success",
        });
      } else {
        console.log("Error in adding user secret");
      }
    }
  }
  //auto > put resolved event get > event pass data> saga call
}

function* getOrganiztionGroupSecrets() {
  while (true) {
    try {
      const { payload: data } = yield take(
        addSecretStepActions.loadOrganizationGroupSecret().type
      );
      const { me } = yield select(authSelector((state) => state));
      yield putResolve(addSecretStepActions.setLoading(true));

      let user_data = me;

      let result = yield call(
        api,
        `/dashboard/companies/${data.organization_id}/teams/${data.team_id}.json`,
        "GET",
        null,
        null,
        null
      );
      //checking if team has secrets to decrypt
      try {
        if (result && result.secrets) {
          let organizationId = data.organization_id
            ? data.organization_id
            : data.id;
          let organization_sym_key = get_org(
            user_data,
            organizationId
          ).organization_sym_key;
          let groupId = result.team.id;
          let groupSymKey = get_group(
            user_data,
            groupId,
            organizationId
          ).symKey;

          let decryptedResult = result.secrets.map((secret, index) => {
            let accountId = parseInt(secret.id);
            let groupId = parseInt(secret.organization_group_id);
            let organizationId = parseInt(secret.organization_id);
            let groupAccounts =
              user_data.orgs_by_id[organizationId].groups_by_id[groupId]
                .accounts;

            //getting current account object
            let organizationAccount = groupAccounts.filter(
              (account) => account.id === accountId
            );

            if (organizationAccount) {
              organizationAccount = organizationAccount[0];

              //return object with all info with symkey of account
              let symKey;
              try {
                symKey = ckServiceSymKey(
                  organizationAccount,
                  organization_sym_key,
                  groupSymKey
                );
              } catch (error) {
                symKey = "";
              }

              //Decrypting the secret and also checking for corrupt secrets
              let decryptedAccount = decryptObjectMarkingCorrupt(
                organizationAccount,
                symKey
              );

              if (
                decryptedAccount.page_title === "" ||
                decryptedAccount.login === ""
              ) {
                decryptedAccount.corrupt = true;
              }
              return decryptedAccount;
            }
          });
          //replacing the original secrets array from api with decrypted array
          result["secrets"] = decryptedResult;
        }
      } catch (e) {
        result["secrets"] = [];
      }

      yield putResolve(addSecretStepActions.setLoading(false));

      //yield putResolve(authActions.setMe(user_data));
      yield putResolve(addSecretStepActions.setCurrentCompany({ ...result }));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#115");
      console.log("error in companyLoadPageSaga", err);
      toast.error("Something went wrong #115", {
        className: "toast-danger",
      });
    }
  }
}

function* importSecretsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        addSecretStepActions.importSecretsBulk().type
      );
      yield putResolve(secretActions.setImportSecretLoading(true));
      const { csrfToken, me } = yield select(authSelector((state) => state));

      const accountsParamsFormData = new FormData();
      let csvTextToJson = data.csvTextToJson;
      const titles = csvTextToJson.meta.fields;
      var csvJsonObject =
        csvTextToJson.data.length > 0 ? csvTextToJson.data : [];
      //package return one additional row so removing it
      csvJsonObject = csvJsonObject.slice(0, -1);

      //checking for comma seprated teams
      var accountsData = [];
      var errors = [];

      const requiredFields = [
        "login",
        "password",
        "notes",
        "auto_login",
        "url",
        "application",
        "redirect_page_title",
        "cipher",
      ];

      requiredFields.forEach((reqTitle) => {
        if (
          !titles.includes(reqTitle) &&
          reqTitle !== "application" &&
          reqTitle !== "redirect_page_title" &&
          reqTitle !== "password" &&
          reqTitle !== "cipher"
        ) {
          errors.push(`Missing Field: ${reqTitle}`);
        }
      });

      let applicationError = !titles.includes("application")
        ? !titles.includes("redirect_page_title")
          ? errors.push("Missing Field: application/redirect_page_title in CSV")
          : null
        : null;

      let cipherError = !titles.includes("cipher")
        ? !titles.includes("password")
          ? errors.push("Missing Field: Password/Cipher in CSV")
          : null
        : null;

      if (titles.includes("teams")) {
        csvJsonObject.forEach((account) => {
          let hasManyTeams = account.teams.includes(",");
          if (hasManyTeams === true) {
            let teams = account.teams.split(",");
            teams.forEach((team) => {
              accountsData.push({ ...account, teams: team });
            });
          } else {
            accountsData.push(account);
          }
        });
      } else {
        accountsData = csvJsonObject;
      }

      if (data.useTeamsColumnInCsv !== true && titles.includes("teams")) {
        errors.push(
          "It looks like there is teams column in csv file but you choose wrong option"
        );
      }
      if (data.useTeamsColumnInCsv === true && !titles.includes("teams")) {
        errors.push("It looks like there is no teams column in csv file");
      }

      let all_accounts = yield call(
        api,
        `/me/all_accounts`,
        "GET",
        null,
        null,
        csrfToken
      );

      let accountsParams = [];
      let organizationId = data.company.id;
      for (let i = 0; i < accountsData.length; i++) {
        let find_by_hostnames;

        let accountData = accountsData[i];
        if (
          accountData.auto_login === "1" ||
          accountData.auto_login === "y" ||
          accountData.auto_login === "Y"
        ) {
          const form_data = new FormData();

          form_data.append("hostnames[]", accountData.url);

          find_by_hostnames = yield call(
            api,
            `/domain_dictionaries/find_by_hostnames.json`,
            "POST",
            null,
            form_data,
            csrfToken
          );
        }

        let domainDictionary =
          find_by_hostnames && find_by_hostnames.length > 0
            ? find_by_hostnames[0]
            : undefined;

        if (
          accountFormDataMatchesExistingAccount(
            all_accounts,
            accountData,
            domainDictionary
          )
        ) {
          return;
        }
        let unencryptedAccount = accountDataFromAccountFormData(
          accountData,
          domainDictionary
        );
        var encryptedAccount = {};

        let type = accountData["type"] ? accountData["type"] : "organization";
        let organization_sym_key = data.company.organization_sym_key;
        switch (type) {
          case "user":
            encryptedAccount = encrypt(unencryptedAccount, sym_key());
            break;
          case "organization":
            encryptedAccount = encrypt(
              unencryptedAccount,
              decrypt(organization_sym_key, sym_key())
            );
            break;
          default:
            encryptedAccount = "";
        }
        if (encryptedAccount) {
          encryptedAccount["auto_login"] =
            typeof domainDictionary != "undefined";
        } else {
          encryptedAccount = [];
        }

        let groupDatas = [];
        if (
          type === "organization" &&
          data.useTeamsColumnInCsv === true &&
          titles.includes("teams")
        ) {
          let groupName = accountData.teams;

          let org = me.orgs_by_id[organizationId];
          let groupData = org.groups.filter(
            (group) => group.name === groupName
          );
          if (groupData.length > 0) groupDatas.push(groupData[0]);
          else {
            errors.push(`No group named ${groupName} found`);
          }
        } else {
          let team_ids = data.selectedTeamsOptions.map((team) => team.id);
          team_ids.forEach((id) => {
            let org = me.orgs_by_id[organizationId];
            let groupData = org.groups.filter((group) => group.id === id);

            if (groupData.length > 0) {
              groupDatas.push(groupData[0]);
            } else {
              errors.push(`No group with ID ${id} found`);
            }
          });
        }
        if (errors.length === 0) {
          encryptedAccount["organization_group_accounts"] = [];

          for (let j = 0; j < groupDatas.length; j++) {
            let groupData = groupDatas[j];

            let unencryptedGroupAccount = Object.assign(
              true,
              {},
              unencryptedAccount
            );
            let groupKey = yield call(
              get_g_s,
              organizationId,
              groupData.id,
              me
            );
            let encryptedGroupAccount = encrypt(
              unencryptedGroupAccount,
              groupKey
            );
            encryptedGroupAccount["organization_group_id"] = groupData.id;

            encryptedGroupAccount["auto_login"] = encryptedAccount.auto_login;
            encryptedAccount["organization_group_accounts"].push(
              encryptedGroupAccount
            );

            accountsParams.push(encryptedAccount);
          }

          for (let key in encryptedAccount) {
            if (key !== "organization_group_accounts") {
              accountsParamsFormData.append(
                `organization_accounts[${i}][${key}]`,
                encryptedAccount[key]
              );
            }
          }
          if (
            encryptedAccount.organization_group_accounts &&
            encryptedAccount.organization_group_accounts
          ) {
            for (
              let k = 0;
              k < encryptedAccount.organization_group_accounts.length;
              k++
            ) {
              let groupSecret = encryptedAccount.organization_group_accounts[k];
              for (let grpKey in groupSecret) {
                accountsParamsFormData.append(
                  `organization_accounts[${i}][organization_group_accounts][${k}][${grpKey}]`,
                  groupSecret[grpKey]
                );
              }
            }
          }
        }
      }

      if (errors.length === 0) {
        let imports = yield call(
          api,
          `/organizations/${data.company.id}/imports.json`,
          "POST",
          null,
          accountsParamsFormData,
          csrfToken
        );

        if (imports.success === true) {
          const user_me_data = yield call(getLatestUserData);
          yield putResolve(authActions.setMe(user_me_data));
          yield putResolve(secretActions.setImportSecretModal(false));

          yield putResolve(
            addSecretStepActions.loadOrganizationGroupSecret({
              organization_id: data.company.id,
              team_id: data.selectedTeamsOptions[0].id,
            })
          );

          toast.success("secrets imported successfully!", {
            className: "toast-success",
          });
        }
      } else {
        yield putResolve(secretActions.setImportSecretError([...errors]));
      }
      yield putResolve(secretActions.setImportSecretLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#224");
      console.log("error in importSecretsSaga", err);
      toast.error("Something Went Wrong #224", {
        className: "toast-danger",
      });
    }
  }
}

export default function* addSecretStepRootSaga() {
  yield spawn(SecretTabAddPageSaga);
  yield spawn(addSecretAutoSaga);
  yield spawn(addSecretCustomSaga);
  yield spawn(existingSecretSaga);
  yield spawn(getOrganiztionGroupSecrets);
  yield spawn(getTeamsDataSaga);
  yield spawn(importSecretsSaga);
}
