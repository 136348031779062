import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { personalsecretActions } from "./store";
import { api } from "../../../helpers/api";
import {
  sym_key,
  decrypt,
  remove_placeholders_from_login_string,
  replace_login_and_password_for_dd_login_string,
  sym_key_by_org_id_decrypt_new,
  encrypt,
  sym_key_by_org_id_new,
  getLatestUserData,
} from "../../../helpers/apiUtils";
import { authActions, authSelector } from "../../auth/store";
import { toast } from "react-toastify";
import { personalsecretSelector } from "./store";
import { secretActions } from "../../../components/secrets/store";
import { isCorruptAfterDecryption } from "../../../helpers/ckService/EncryptionHelper";
import { get_group, get_org } from "../../../helpers/ckService/UserDataHelper";
import { getUserAccount } from "../../../helpers/ckService/AccountService";
import { teamsTabSelector } from "../../company-page/teams-tab/store";
import {
  check_if_exists_except,
  delete_param_for_org_grp_account,
} from "../../../helpers/organization";
import { get_g_s } from "../../../helpers/session";
import sentryErrorCatch from "../../../helpers/sentryUtils";

// Dsiplay personal secret by Id
function* personalSecretPageByIdSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        personalsecretActions.personalSecretById().type
      );
      yield putResolve(personalsecretActions.setLoading(true));
      let secret = yield call(api, `/user_accounts/${data.secret_id}.json`);

      const decryptedResult = decrypt(secret, sym_key());
      yield putResolve(personalsecretActions.setLoading(false));
      yield putResolve(
        personalsecretActions.loadPersonalSecret(decryptedResult)
      );

      yield putResolve(secretActions.loadSecret(decryptedResult));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#177");
      // toaster
      toast.error("Something went wrong #177", {
        className: "toast-danger",
      });
      console.log("error in personalSecretPageByIdSaga", err);
    }
  }
}

// Delete Personal secret
function* deletePersonalSecretSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        personalsecretActions.deletePersonalSecret().type
      );
      yield putResolve(secretActions.setDeleteButtonLoading(true));
      let user_data = yield call(getLatestUserData);
      // auth selector
      const { csrfToken } = yield select(authSelector((state) => state));
      let apiData;
      // csrfToken
      if (csrfToken) {
        var account = undefined;

        var accountId = parseInt(data.secret_id);
        account = yield call(getUserAccount, accountId, user_data);

        // var ck_user means (orgSymKey and grpSymKey);
        let orgSymKey = get_org(user_data);
        let grpSymKey = get_group(user_data);
        var isCorrupt = isCorruptAfterDecryption(
          account,
          // commonkey,
          // cksession,
          orgSymKey,
          grpSymKey
        );

        const form_data = new FormData();
        form_data.append("corrupt", isCorrupt);

        // perosnal secret API
        apiData = yield call(
          api,
          `/user_accounts/${data.secret_id}`,
          "delete",
          null,
          form_data,
          csrfToken
        );
      }
      if (apiData.success === true) {
        yield putResolve(authActions.setMe(user_data));
        yield putResolve(secretActions.setDeleteButtonLoading(false));
        data.history.push("/user-accounts");
        // toaster
        toast.success("Secret Delete Successfully", {
          className: "toast-success",
        });
      } else {
        yield call(sentryErrorCatch, "API response is empty", "#178");
        // toaster
        toast.error("Something went wrong #178", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#179");
      // toaster
      toast.error("Something went wrong #179", {
        className: "toast-danger",
      });
      console.log("error in deletePersonalSecretSaga", err);
    }
  }
}

function* generatePayload(data, decryptedSecret) {
  let newSecret = {
    account_id: decryptedSecret.id,
    custom: decryptedSecret.auto_login ? false : true,
    custom_title: decryptedSecret.custom_title,
    group_id: undefined,
    login_hostname: data.login_page_hostname,
    login: data.login,
    notes: data.notes,
    page_title: data.page_title,
    password: data.password,
    redirect_hostname: decryptedSecret.auto_login
      ? decryptedSecret.redirect_page_hostname
      : decryptedSecret.url,
    url: data.url,
    type: "user",
  };

  return newSecret;
}

//update personal secret
function* updatePersonalSecretSaga() {
  while (true) {
    try {
      const { payload: dataPayload } = yield take(
        personalsecretActions.updatePersonalSecret().type
      );
      yield putResolve(secretActions.setUpdateButtonLoading(true));
      const { userAccount } = yield select(
        personalsecretSelector((state) => state)
      );

      let data = yield call(generatePayload, dataPayload, userAccount.item);

      // login, login_hostname, redirect_hostname, account_id, type required
      // message
      if (
        !data.login ||
        !data.login_hostname ||
        !data.id ||
        !data.type ||
        !data.redirect_hostname
      ) {
        const { me, csrfToken } = yield select(authSelector((state) => state));
        const { userRole } = yield select(teamsTabSelector((state) => state));
        let user_data = me;
        let url = "";
        if (
          me.orgs_by_id[data.organization_id] &&
          !me.orgs_by_id[data.organization_id].admin
        ) {
          url = `/me/all_accounts?group_id=${data.group_id}`;
        } else {
          url = `/me/all_accounts/`;
        }
        var urlAjax = yield call(api, url, "GET", null, null, csrfToken);

        if (urlAjax.length > 0) {
          // Convert all encrypted account to decrypted account
          // according to type
          let accounts;
          // decrypt  secret
          accounts = convert_encrypt_to_decrypt_based_on_type(
            urlAjax,
            user_data
          );

          if (
            check_if_exists_except(
              data.account_id,
              data.login,
              data.login_hostname,
              data.redirect_hostname,
              accounts
            ) !== false
          ) {
            dataPayload.history.go(-1);
            yield putResolve(secretActions.setUpdateButtonLoading(false));
            toast.error("Account already added.", {
              className: "toast-danger",
            });
          } else {
            var to_save = {
              login: data.login,
              page_title: data.page_title,
              cipher: data.password,
              notes: data.notes,
              custom_title: data.custom_title,
            };

            // save all shit
            ///====> not requred for personal secret

            if (data.save_all) {
              to_save = Object.assign(to_save, {
                login_origin: data.login_url,
                redirect_origin: data.redirect_origin,
                url: data.url,
                needs_to_be_completed: false,
                login_page_hostname: data.login_hostname,
                login_string: remove_placeholders_from_login_string(
                  data.login_string,
                  data.login,
                  data.password
                ),
                redirect_page_title: data.redirect_page_title,
                login_page_title: data.login_page_title,
                redirect_page_hostname: data.redirect_hostname,
                //auto_login: !args.custom
              });
            }
            ///====> not requred for personal secret

            if (data.custom) {
              to_save.login_page_hostname = data.page_title;
              to_save.login_page_title = data.page_title;
              to_save.redirect_page_title = data.page_title;
              to_save.redirect_page_hostname = data.page_title;
              to_save.url = data.url;
              to_save.login_string = "";
            } else {
              if (!data.save_all) {
                // find this account in current accounts and lets update the login_string
                var account_ref = accounts.filter(
                  (account) =>
                    account.id == parseInt(data.account_id) &&
                    (account.type == data.type ||
                      account.organization_id == data.organization_id ||
                      account.organization_group_id == parseInt(data.group_id))
                );

                if (account_ref.length == 1) {
                  to_save.login_string = replace_login_and_password_for_dd_login_string(
                    account_ref[0].login_string,
                    account_ref[0].login,
                    to_save.login
                  );
                  to_save.login_string = replace_login_and_password_for_dd_login_string(
                    to_save.login_string,
                    account_ref[0].cipher,
                    to_save.cipher
                  );
                } else {
                  //toaster
                  //
                  alert(
                    "Oh no an error occurred! We're on it! More than one account with the same id...impossible"
                  );
                  return;
                }
              }
            }

            //Arrange the data to be given to form data and encrypt it.
            // rename
            var save = {};
            for (let x in to_save) {
              save[data.type + "_" + "account[" + x + "]"] = to_save[x];
            }

            // encrypt
            let save_enc = {};

            // setup url
            url = {};
            if (
              user_data.orgs_by_id[data.organization_id] &&
              !user_data.orgs_by_id[data.organization_id].admin
            ) {
              data.type = "manager";
            }

            switch (data.type) {
              case "user":
                url.path = `/user_accounts/${data.account_id}.json`;
                save_enc = encrypt(save, sym_key());
                break;
              case "organization":
                save_enc = encrypt(
                  save,
                  sym_key_by_org_id_decrypt_new(data.organization_id, user_data)
                );

                url.organization_id = data.organization_id;
                url.path = `/organizations/${data.organization_id}/accounts/${data.account_id}.json`;
                break;

              case "manager":
                let org = user_data.orgs_by_id[data.organization_id];
                let group = org.groups.filter(
                  (group) => group.id === data.group_id
                )[0];
                var key = group.symKey;

                var decrypt_org_group_symkey = decrypt(key, sym_key());
                delete_param_for_org_grp_account(save);
                save_enc = encrypt(save, decrypt_org_group_symkey);

                url.organization_id = data.organization_id;

                url.path = `/organizations/${data.organization_id}/groups/${data.group_id}/accounts/${data.account_id}/update_manager.json`;

                // If manager, handle saving within this callback.
                //instance.saveAccount(args, argsdup, to_save, url, save_enc);
                break;
            }

            const form_data = new FormData();
            for (let key in save_enc) {
              form_data.append(key, save_enc[key]);
            }

            let apiData;
            apiData = yield call(
              api,
              url.path,
              "PUT",
              null,
              form_data,
              csrfToken
            );

            if (apiData.success === true) {
              if (data.type == "organization" && apiData.groups) {
                // rename for group save
                var save = {};

                // save it all
                var save = {};
                for (var arg in to_save) {
                  if (arg != "needs_to_be_completed") {
                    save["organization_group_account[" + arg + "]"] =
                      to_save[arg];
                  }
                }

                var groups = apiData.organization_group_accounts;

                for (var g in groups) {
                  if (g === "isArray") {
                    //argsdup.success(d);
                    continue;
                  }

                  // encrypt
                  var gsk = decrypt(
                    groups[g].k,
                    sym_key_by_org_id_decrypt_new(
                      url.organization_id,
                      user_data
                    )
                  );

                  delete_param_for_org_grp_account(save);

                  var g_save = encrypt(save, gsk);

                  // id of account just saved
                  g_save[
                    "organization_group_account[organization_account_id]"
                  ] = apiData.organization_account.id;

                  const group_form_data = new FormData();
                  //Form Data for Group
                  for (let key in g_save) {
                    group_form_data.append(key, g_save[key]);
                  }

                  yield call(
                    api,
                    `/organizations/${url.organization_id}/groups/${groups[g].group_id}/accounts/${groups[g].organization_group_account}.json`, //orgid and account_id
                    "put",
                    null,
                    group_form_data,
                    csrfToken
                  );
                }
              }
              //toaster;
              toast.success("Secret Update Successfully", {
                className: "toast-success",
              });
              yield putResolve(secretActions.setUpdateButtonLoading(false));
              const user_me_data = yield call(getLatestUserData);
              yield putResolve(authActions.setMe(user_me_data));
              dataPayload.history.go(-1);
            } else {
              yield call(sentryErrorCatch, "API response is empty", "#180");
              //toaster;
              toast.error("Something went wrong #180", {
                className: "toast-danger",
              });
            }
          }
        } else {
          toast.error("No Secret Accounts", {
            className: "toast-danger",
          });
        }
      } else {
        yield call(sentryErrorCatch, "Condition false", "#181");
        //toaster;
        toast.error("Something went wrong #181", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#182");
      toast.error("Something went wrong #182", {
        className: "toast-danger",
      });
    }
  }
}

function convert_encrypt_to_decrypt_based_on_type(arr, user_data) {
  // types can be class_name => USER / ORGANIZATION / MANAGER
  // 1.type=user          class_name=UserAccount
  // 2.type=organization  class_name=OrganizationAccount
  // 3.type=              class_name-OrganizationGroupAccount
  // type=> is missing if the class_name is OrganizationGroupAccount
  //        they are determining it by using this condition
  //        if ( user_data.orgs_by_id[v.organization_id] && !user_data.orgs_by_id[v.organization_id].admin)
  var accounts = [];
  let key;
  for (let i = 0; i < arr.length; i++) {
    let v = arr[i];

    if (v.type == "user") {
      key = sym_key();
    } else {
      if (
        user_data.orgs_by_id[v.organization_id] &&
        !user_data.orgs_by_id[v.organization_id].admin
      ) {
        key = get_g_s(v.organization_id, v.organization_group_id);
      } else {
        key = decrypt(
          sym_key_by_org_id_new(v.organization_id, user_data),
          sym_key()
        );
      }
    }
    accounts.push(decrypt(v, key));
  }
  return accounts;
}

export default function* personalSecretDetailsRootSaga() {
  yield spawn(personalSecretPageByIdSaga);
  yield spawn(deletePersonalSecretSaga);
  yield spawn(updatePersonalSecretSaga);
}
