import {
  putResolve,
  call,
  spawn,
  take,
  select,
  all,
  takeEvery,
} from "redux-saga/effects";
import { secretTabActions, secretTabSelector } from "./store";
import { api } from "../../../helpers/api";
import {
  sym_key,
  decrypt,
  sym_key_by_org_id_new,
  debugOrganizationObject,
  replace_login_and_password_for_dd_login_string,
  remove_placeholders_from_login_string,
  encrypt,
  sym_key_by_org_id_decrypt_new,
  getLatestUserData,
  process_accounts_in_groups_only,
} from "../../../helpers/apiUtils";
import { toast } from "react-toastify";
import { authActions, authSelector } from "../../auth/store";
import { get_g_s } from "../../../helpers/session";
import { get_group, get_org } from "../../../helpers/ckService/UserDataHelper";
import { ckServiceSymKey } from "../../../helpers/ckService/AccountEncryptionRules";
import { decryptObjectMarkingCorrupt } from "../../../helpers/ckService/EncryptionHelper";
import { teamsTabSelector } from "../teams-tab/store";
import { organizationSecretDetailsSelector } from "./secret-tab-details-page/store";
import { delete_param_for_org_grp_account } from "../../../helpers/organization";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* secretTabPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(secretTabActions.loadData().type);
      const { me } = yield select(authSelector((state) => state));
      const { searchText } = yield select(secretTabSelector((state) => state));

      const { userRole } = yield select(teamsTabSelector((state) => state));
      let user_data = me;

      yield call(process_accounts_in_groups_only, user_data);

      let result;
      result = yield call(api, `/dashboard/companies/${data.companyid}.json`);

      let decryptedSecret = [];
      const uniqueSecrets = [];
      if (userRole === "owner" || userRole === "admin") {
        const orgSecrets = Object.values(
          me.orgs_by_id[data.companyid]?.all_accounts_by_id
        );

        //THIS WORKS FINE FOR OWNER/ADMIN STARTS HERE
        if (result && result.org_account) {
          decryptedSecret = yield call(
            convert_encrypt_to_decrypt_based_on_type,
            orgSecrets,
            user_data
          );
          decryptedSecret = decryptedSecret.map((secret) => {
            let organizationId = parseInt(secret.organization_id);
            const foundIndex = uniqueSecrets.findIndex(
              (s) =>
                s.auto_login === secret.auto_login &&
                s.login === secret.login &&
                s.cipher === secret.cipher &&
                s.url === secret.url
            );

            if (foundIndex === -1) {
              const teamNames = secret.groups.map((groupId) => {
                const name =
                  user_data.orgs_by_id[organizationId].groups_by_id[groupId]
                    .name;
                return { name: name, id: groupId };
              });
              uniqueSecrets.push({
                ...secret,
                teams: teamNames ? teamNames : [],
              });
            } else {
              const foundSecret = uniqueSecrets[foundIndex];
              let teams = foundSecret.teams ? foundSecret.teams : [];
              if (secret.groups.length > 0) {
                teams = secret.groups.map((groupId) => {
                  const name =
                    user_data.orgs_by_id[organizationId].groups_by_id[groupId]
                      .name;
                  return { name: name, id: groupId };
                });
              }

              uniqueSecrets[foundIndex] = {
                ...secret,
                teams: [...teams],
              };
            }
          });
          //decryptedSecret = yield call(debugOrganizationObject, )
        }
      } else {
        if (result && result.all_secrets) {
          decryptedSecret = result.all_secrets.map((secret) => {
            let accountId = parseInt(secret.id);
            let groupId = parseInt(secret.organization_group_id);
            let organizationId = parseInt(secret.organization_id);
            let groupAccounts =
              user_data.orgs_by_id[organizationId].groups_by_id[groupId]
                .accounts;

            let groupName =
              user_data.orgs_by_id[organizationId].groups_by_id[groupId].name;
            const team = groupName;

            let organizationAccount = groupAccounts.filter(
              (account) => account.id === accountId
            );

            if (organizationAccount) {
              organizationAccount = { groupName, ...organizationAccount[0] };
              let organization_id = get_org(
                user_data,
                organizationId
              ).organization_sym_key;
              let groupSymKey = get_group(
                user_data,
                groupId,
                organizationId
              ).symKey;
              //return object with all info with symkey of account
              let symKey;
              try {
                symKey = ckServiceSymKey(
                  organizationAccount,
                  organization_id,
                  groupSymKey
                );
              } catch (err) {
                symKey = "";
              }
              //Decrypting the secret and also checking for corrupt secrets
              let decryptedAccount = decryptObjectMarkingCorrupt(
                organizationAccount,
                symKey
              );

              const foundIndex = uniqueSecrets.findIndex(
                (s) =>
                  s.auto_login === decryptedAccount.auto_login &&
                  s.login === decryptedAccount.login &&
                  s.cipher === decryptedAccount.cipher &&
                  s.url === decryptedAccount.url
              );

              if (foundIndex === -1) {
                uniqueSecrets.push({
                  ...decryptedAccount,
                  teams: team ? [{ name: team, id: groupId }] : [],
                });
              } else {
                const foundSecret = uniqueSecrets[foundIndex];
                const teams = foundSecret.teams ? foundSecret.teams : [];
                uniqueSecrets[foundIndex] = {
                  ...foundSecret,
                  teams: [...teams, { name: team, id: groupId }],
                };
              }

              return decryptedAccount;
            }
          });
        }
      }
      yield putResolve(authActions.setMe(user_data));
      yield putResolve(secretTabActions.loadSecretTabData(uniqueSecrets));
      let payload = {
        fieldName: "searchText",
        value: searchText,
      };
      yield putResolve(secretTabActions.searching(payload));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#136");
      //toaster
      toast.error("Something went wrong #136", {
        className: "toast-danger",
      });
      console.log("err in secretTabPageSaga", err);
    }
  }
}

function* OrganizationSecretDetailsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        secretTabActions.organizationSecretDetails().type
      );

      const { organizationSecrets } = yield select(
        secretTabSelector((state) => state)
      );

      const { userRole } = yield select(teamsTabSelector((state) => state));

      let secret = organizationSecrets.items.filter(
        (secret) => secret.id === data.secretId
      )[0];

      yield putResolve(secretTabActions.setSelectedSecret(secret));
      if (userRole === "owner" || userRole === "admin") {
        data.history.push(
          `/dashboard/companies/${secret.organization_id}/secrets/${secret.id}`
        );
      } else {
        data.history.push(
          `/dashboard/companies/${secret.organization_id}/secrets/${secret.id}`
        );
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#137");
      toast.error("Something went wrong #137", {
        className: "toast-danger",
      });
      console.log("err in OrganizationSecretDetailsSaga", err);
    }
  }
}

function* convert_encrypt_to_decrypt_based_on_type(arr, user_data) {
  // types can be class_name => USER / ORGANIZATION / MANAGER
  // 1.type=user          class_name=UserAccount
  // 2.type=organization  class_name=OrganizationAccount
  // 3.type=              class_name-OrganizationGroupAccount
  // type=> is missing if the class_name is OrganizationGroupAccount
  //        they are determining it by using this condition
  //        if ( user_data.orgs_by_id[v.organization_id] && !user_data.orgs_by_id[v.organization_id].admin)
  var accounts = [];
  let key;
  for (let i = 0; i < arr.length; i++) {
    let v = arr[i];

    if (v.type == "user") {
      key = sym_key();
    } else {
      if (
        user_data.orgs_by_id[v.organization_id] &&
        !user_data.orgs_by_id[v.organization_id].admin
      ) {
        key = get_g_s(v.organization_id, v.organization_group_id, user_data);
      } else {
        try {
          key = decrypt(
            sym_key_by_org_id_new(v.organization_id, user_data),
            sym_key()
          );
        } catch (err) {
          yield call(sentryErrorCatch, err, "#202");
          console.log("error in convert_encrypt_to_decrypt_based_on_type", err);
        }
      }
    }

    try {
      accounts.push(decrypt(v, key));
    } catch (e) {
      yield call(sentryErrorCatch, e, "#203");
      console.log("error in convert_encrypt_to_decrypt_based_on_type", e);
    }
  }
  return accounts;
}

export default function* secretTabPageRootSaga() {
  yield spawn(secretTabPageSaga);
  yield spawn(OrganizationSecretDetailsSaga);
}
