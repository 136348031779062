import { state } from "../../../store";
export const [billingActions, billingSelector, billingState] = state(
  "billing",
  {
    initial: {
      plans: [],
      organization: {},
      adminsCount: "",
      usersCount: "",
      paymentMethods: {},
      upcomingInvoice: {},
      subscribedPlans: [],
      loading: true,
      savedCard: false,
      stripe_pk: "",
      teamPlanLoading: true,
      adminPlanLoading: true,
      discountText: "",
      discountApplied: "",
      invoicesList: [],
      applyCouponLoading: false,
      have_credit_card: "",
    },
    on: {
      inputChangeHandler: (_, result) => {
        const newStoreObj = {
          ..._,
        };
        const { fieldName, value } = result;
        newStoreObj[fieldName] = value;
        return { ...newStoreObj };
      },
      loadBillingData: (_, data) => {
        const newStoreObj = { ..._ };

        let teamManagementPlan = data.teamManagementPlan;
        teamManagementPlan.planType = "teamPasswordManagement";

        let multilevelAdminPlan = data.multilevelAdminPlan;
        multilevelAdminPlan.planType = "multiLevelAdminPermissions";

        let growthPlan = data.growthPlan;
        growthPlan.planType = "growthPlan";

        let teamPasswordManagementPlanAnnual =
          data.teamPasswordManagementPlanAnnual;
        teamPasswordManagementPlanAnnual.planType =
          "teamPasswordManagementPlanAnnual";

        let growthPlanAnnual = data.growthPlanAnnual;
        growthPlanAnnual.planType = "growthPlanAnnual";

        //plans
        let plans = [
          teamManagementPlan,
          multilevelAdminPlan,
          growthPlan,
          teamPasswordManagementPlanAnnual,
          growthPlanAnnual,
        ];

        plans = plans.map((plan) => {
          let subscribedPlan = data.subscribedPlans.filter(
            (p) =>
              p.stripe_plan_id === plan.stripe_plan_name ||
              p.stripe_plan_id === plan.stripe_user
          );
          if (subscribedPlan.length > 0) {
            plan.subscribed = true;
            plan.subscribedPlanInfo = subscribedPlan[0];
          } else {
            plan.subscribed = false;
          }
          plan.loading = false;
          return plan;
        });

        //card details
        let paymentMethods = data.methods.length > 0 ? data.methods[0] : null;
        let savedCard;
        if (paymentMethods) {
          savedCard = true;
        } else {
          savedCard = false;
        }

        //billing info

        let upcomingInvoice = data.upcoming_invoice;

        if (upcomingInvoice) {
          // to decimal
          upcomingInvoice.total = upcomingInvoice.total / 100;
          upcomingInvoice.total = upcomingInvoice.total.toFixed(2);

          upcomingInvoice.subtotal = upcomingInvoice.subtotal / 100;
          upcomingInvoice.subtotal = upcomingInvoice.subtotal.toFixed(2);

          upcomingInvoice.invoiceDiscount = data.InvoiceDiscount;
        }
        //public stripe key
        let stripe_pk = data.stripe_pk;

        newStoreObj.organization = data.organization;
        newStoreObj.upcomingInvoice = upcomingInvoice;
        newStoreObj.adminsCount = data.adminsCount;
        newStoreObj.usersCount = data.usersCount;
        newStoreObj.paymentMethods = paymentMethods;
        newStoreObj.plans = plans;
        newStoreObj.savedCard = savedCard;
        newStoreObj.stripe_pk = stripe_pk;
        newStoreObj.discountApplied = data.discountApplied;
        newStoreObj.have_credit_card = data.have_credit_card;
        return { ...newStoreObj };
      },
      setInvoicesList: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        let invoicesList = data.map((invoice) => {
          invoice.amount = invoice.amount_in_cents / 100;
          invoice.amount = invoice.amount.toFixed(2);
          invoice.paid = invoice.paid === true ? "Yes" : "No";
          invoice.captured = invoice.captured === true ? "Yes" : "No";
          invoice.receipt_number = invoice.receipt_number
            ? invoice.receipt_number
            : "-";
          return invoice;
        });

        return { ...newStoreObj, invoicesList };
      },
      setLoading: (_, data) => {
        const newStoreObj = { ..._ };
        return { ...newStoreObj, loading: data };
      },
      setPlanLoading: (_, data) => {
        const newStoreObj = { ..._ };
        const { planName, status } = data;
        let plans = newStoreObj.plans.map((plan) => {
          if (plan.name === planName) {
            plan.loading = status;
          }
          return plan;
        });

        return { ...newStoreObj, plans };
      },
      setApplyCouponLoading: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          applyCouponLoading: data,
        };
      },
    },
    events: [
      "loadData",
      "subscribePlan",
      "unsubscribePlan",
      "stripeSuccess",
      "updateCreditCard",
      "subscribeTogglePlan",
    ],
  }
);
