import { sym_key, decrypt, sym_key_by_org_id_new } from "./apiUtils";
import { call } from "redux-saga/effects";
import { get_g_s } from "./session";

// get the organization for the current dashboard
// organization(){
//     return $(".organization-dashboard").data("id");
// }

// get the org sym key
// export function* org_sym_key(organization_id) {
//   let user_data = yield call(getLatestUserData);
//   return decrypt(
//     user_data.orgs_by_id[organization_id ? organization_id : "id"]
//       .organization_sym_key,
//     sym_key()
//   );
// }

export function* get_only_org_sym_key(organization_id, user_data) {
  let orgSymKey = user_data.orgs_by_id[organization_id].organization_sym_key
    ? user_data.orgs_by_id[organization_id].organization_sym_key
    : sym_key();
  return orgSymKey;
}

export function convert_encrypt_to_decrypt_based_on_type(arr, user_data) {
  // types can be class_name => USER / ORGANIZATION / MANAGER
  // 1.type=user          class_name=UserAccount
  // 2.type=organization  class_name=OrganizationAccount
  // 3.type=              class_name-OrganizationGroupAccount
  // type=> is missing if the class_name is OrganizationGroupAccount
  //        they are determining it by using this condition
  //        if ( user_data.orgs_by_id[v.organization_id] && !user_data.orgs_by_id[v.organization_id].admin)
  var accounts = [];
  let key;
  for (let i = 0; i < arr.length; i++) {
    let v = arr[i];
    if (v.type == "user") {
      key = sym_key();
    } else {
      if (
        user_data.orgs_by_id[v.organization_id] &&
        !user_data.orgs_by_id[v.organization_id].admin
      ) {
        key = get_g_s(v.organization_id, v.organization_group_id, user_data);
      } else {
        key = decrypt(
          sym_key_by_org_id_new(v.organization_id, user_data),
          sym_key()
        );
      }
    }
    accounts.push(decrypt(v, key));
  }
  return accounts;
}

// check if account exists in current acccounts array
export function check_if_exists_except(
  account_id,
  login,
  login_hostname,
  redirect_hostname,
  all_accounts
) {
  for (var i = 0; i < all_accounts.length; i++) {
    if (
      all_accounts[i].login === login && // same login
      all_accounts[i].login_page_hostname === login_hostname && // same page
      all_accounts[i].redirect_page_hostname === redirect_hostname && // same page
      all_accounts[i].id !== account_id // not same account id
    ) {
      return all_accounts[i];
    }
  }
  return false;
}

export function delete_param_for_org_grp_account(save) {
  delete save["organization_account[needs_to_be_completed]"];
}
