import { putResolve, take, call, spawn, select } from "redux-saga/effects";
import { authActions, authSelector } from "./store";
import {
  getLatestUserData,
  sym_key,
  myRSAGetPriKeyDecryptedAsync,
  encrypt,
  decrypt,
  process_accounts_in_groups_only,
  subtleRSADecrypt,
  update_invites_for_new_users,
} from "../../helpers/apiUtils";
import { api } from "../../helpers/api";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../helpers/sentryUtils";

export function* authSetUserInfoSaga() {
  while (true) {
    try {
      yield take(authActions.setUserFromMe().type);
      var user_me_data = yield call(getLatestUserData);
      if (
        !user_me_data.hasOwnProperty("error") &&
        !user_me_data.hasOwnProperty("error_type")
      ) {
        //getting gravat of user
        let options = { size: 50 };
        let gravatar_id = CryptoJS.MD5(user_me_data.user.email);
        let gravatar_url = `https://secure.gravatar.com/avatar/${gravatar_id}?s=${options["size"]}`;

        yield putResolve(authActions.setGravatarUrl(gravatar_url));

        var logging = {
          email: user_me_data.user.email,
          firstname: user_me_data.user.firstname,
          lastname: user_me_data.user.lastname,
          id: user_me_data.user.id,
          created_at: user_me_data.user.created_at,
        };
        const result = yield call(
          api,
          `/me/salts?email=${user_me_data.user.email}`
        );
        if (result.csrfToken && result.email && result.salt1 && result.salt2) {
          yield putResolve(authActions.setCsrfToken(result.csrfToken));

          const mineToProcess = yield call(
            api,
            `/invitations/mine_to_process`,
            "GET",
            null,
            null,
            result.csrfToken
          );

          // confirm new user invitation from owner or admin
          let companies = Object.values(user_me_data.orgs_by_id);
          let ownerOrAdmin = companies.filter(
            (company) =>
              (company.owner === true && company.admin === true) ||
              (company.owner === false && company.admin === true)
          );
          if (ownerOrAdmin && ownerOrAdmin.length > 0) {
            yield call(update_invites_for_new_users, user_me_data);
          }

          // update secret which is added by manager or member
          yield call(process_accounts_in_groups_only, user_me_data);

          try {
            if (mineToProcess && mineToProcess.invitations.length > 0) {
              var private_key_encrypted = mineToProcess.private_key_encrypted;
              var invitations = mineToProcess.invitations;
              var rsa_private_key = decrypt(private_key_encrypted, sym_key());

              for (let i = 0; i < invitations.length; i++) {
                var invite = invitations[i];

                let public_key_encrypted =
                  invite.share_type == "organization"
                    ? invite.organization_public_key_encrypted
                    : invite.group_public_key_encrypted;
                let decrypted_key = "";
                if (mineToProcess.asymmetric_key_algo === "RSA-OAEP") {
                  try {
                    if (invite.new_user_key) {
                      // new user invitaion with new_user_key
                      decrypted_key = yield subtleRSADecrypt(
                        invite.new_user_key,
                        invite.group_public_key_encrypted
                      );
                    } else {
                      // new user invitaion
                      decrypted_key = yield subtleRSADecrypt(
                        rsa_private_key,
                        public_key_encrypted
                      );
                    }
                  } catch (err) {
                    yield call(sentryErrorCatch, err, "#220", mineToProcess);
                    console.log("error in logoutUserSaga", err);
                  }
                } else {
                  try {
                    if (invite.new_user_key) {
                      // new user browser not support subtle but he has key
                      decrypted_key = "";
                    } else {
                      // RSA Decryption
                      decrypted_key = yield call(
                        myRSAGetPriKeyDecryptedAsync,
                        rsa_private_key,
                        public_key_encrypted
                      );
                    }
                  } catch (err) {
                    yield call(sentryErrorCatch, err, "#221", mineToProcess);
                    console.log("error in logoutUserSaga", err);
                  }
                }
                if (decrypted_key) {
                  switch (invite.share_type) {
                    case "organization":
                      const form_data = new FormData();

                      let organization_sym_key = encrypt(
                        decrypted_key,
                        sym_key()
                      );
                      form_data.append(
                        "organization_sym_key",
                        organization_sym_key
                      );
                      const do_process = yield call(
                        api,
                        `/invitations/${invite.id}/do_process`,
                        "POST",
                        null,
                        form_data,
                        result.csrfToken
                      );

                      if (do_process.success === true) {
                        //show notification
                        toast.success(do_process.message, {
                          className: "toast-success",
                        });
                      }
                      //console > do_process.message: "You have been given admin access to the TUNTUN company by shubham holkar"

                      break;
                    case "group":
                      let symmetric_user_key_encrypted = encrypt(
                        decrypted_key,
                        sym_key()
                      );

                      const form_data_group = new FormData();
                      form_data_group.append(
                        "organization_group_user[organization_group_id]",
                        invite.group_id
                      );
                      form_data_group.append(
                        "organization_id",
                        invite.organization_id
                      );
                      form_data_group.append(
                        "organization_group_user[symmetric_user_key_encrypted]",
                        symmetric_user_key_encrypted
                      );

                      const addusertogroup = yield call(
                        api,
                        `/organizations/${invite.organization_id}/addusertogroup`,
                        "POST",
                        null,
                        form_data_group,
                        result.csrfToken
                      );

                      if (addusertogroup.success === true) {
                        // show Notification
                        toast.success(addusertogroup.message, {
                          className: "toast-success",
                        });
                      }
                      break;
                  }
                }
              }
              if (invitations.length > 0) {
                user_me_data = yield call(getLatestUserData);
              }
            }
          } catch (err) {
            yield call(sentryErrorCatch, err, "#222", mineToProcess);
            console.log("error in logoutUserSaga", err);
          }
        }

        // storing user data to Auth/store for further refrences
        let final_user_me_data = user_me_data;
        if (final_user_me_data.invitations.ownership_invitations) {
          let ownership_invitations =
            final_user_me_data.invitations.ownership_invitations;
          ownership_invitations = ownership_invitations.filter(
            (invitation) => invitation.status === "Pending"
          );
          final_user_me_data.invitations["ownership_invitations"] =
            ownership_invitations;
          const ownershipInvitations =
            final_user_me_data.invitations["ownership_invitations"];

          const ownershipTransferedOrgs =
            final_user_me_data.invitations[
              "ownership_transfered_organizations"
            ];

          //getting respective org info into ownership invites
          final_user_me_data.ownershipInvitesWithOrgInfo =
            ownershipInvitations.map((invite) => {
              let foundOrg = ownershipTransferedOrgs.filter(
                (org) => org.id === invite.org_id
              )[0];
              if (foundOrg) {
                return {
                  ...invite,
                  orgName: foundOrg.name,
                  ownerFirstname: foundOrg.ownerFirstname,
                  ownerLastname: foundOrg.ownerLastname,
                };
              }
              return invite;
            });
        }

        yield putResolve(authActions.setMe(final_user_me_data));
        yield putResolve(authActions.setUserInfo(logging));
      }
      //setting the flag that we have checked user logged in status
      yield putResolve(authActions.setCheckingLogIn(true));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#113");
      console.log("error in authSetUserInfoSaga", err);
      toast.error("Something Went Wrong #113", {
        className: "toast-danger",
      });
    }
  }
}

export function* logoutUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(authActions.logoutUser().type);
      const { csrfToken } = yield select(authSelector((state) => state));

      yield call(api, `/users/sign_out`, "DELETE", null, null, csrfToken);
      //clearing session
      sessionStorage.clear();

      //clearing storage
      localStorage.clear();

      //clearing store information
      yield putResolve(authActions.resetUserInfo());

      if (data && data.notify) {
        window.location.href = `/login?notify=${data.notify}`;
      } else {
        window.location.href = "/login";
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#114");
      console.log("error in logoutUserSaga", err);
      toast.error("Something Went Wrong #114", {
        className: "toast-danger",
      });
    }
  }
}

export default function* authSagaRootSaga() {
  yield spawn(authSetUserInfoSaga);
  yield spawn(logoutUserSaga);
}
