import React from "react";
import { Redirect } from "react-router-dom";

import { Route } from "react-router-dom";
import personalSecretsPage from "../pages/personal-secrets-page/personal-secrets-page";

// pages
const ErrorPageNotFound = React.lazy(() =>
  import("../pages/error/PageNotFound")
);
const IgnoredLists = React.lazy(() =>
  import("../pages/ignored-lists-page/ignored-lists-page")
);

const Login = React.lazy(() => import("../pages/auth/login-page/login-page"));
const Register = React.lazy(() =>
  import("../pages/auth/register-page/register-page")
);
const ForgotPasswordPage = React.lazy(() =>
  import("../pages/auth/forgotten-password/forgotten-password")
);
const ResetPassword = React.lazy(() =>
  import("../pages/auth/forgotten-password/reset-password/reset-password")
);
const ResetPasswordLinkExpired = React.lazy(() =>
  import(
    "../pages/auth/forgotten-password/reset-password/reset-pass-link-expired"
  )
);

const ConfirmationEmailPage = React.lazy(() =>
  import("../pages/auth/confirmation-email/confirmation-email")
);
const DasboardPage = React.lazy(() =>
  import("../pages/dashboard-page/dashboard-page")
);
const CreateCompanyPage = React.lazy(() =>
  import("../pages/dashboard-page/create-company-page/create-company-page")
);
const secretsTab = React.lazy(() =>
  import("../pages/company-page/secret-tab/secret-tab")
);
const secretDetails = React.lazy(() =>
  import(
    "../pages/company-page/secret-tab/secret-tab-details-page/secret-tab-details-page"
  )
);
const personalSecretPage = React.lazy(() =>
  import("../pages/personal-secrets-page/personal-secrets-page")
);
const addPersonalSecretPage = React.lazy(() =>
  import(
    "../pages/personal-secrets-page/personal-secret-add/personal-secret-add-page"
  )
);
const personalSecretDetailsPage = React.lazy(() =>
  import(
    "../pages/personal-secrets-page/personal-secret-details-page/personal-secret-details-page"
  )
);
const UsersTab = React.lazy(() =>
  import("../pages/company-page/users-tab/users-tab")
);

const UserSettingPage = React.lazy(() =>
  import("../pages/user-setting-page/user-setting-page")
);

const diagnosticInfoPage = React.lazy(() =>
  import("../pages/user-setting-page/diagnosticInfo/diagnosticInfo")
);

const TeamsTab = React.lazy(() =>
  import("../pages/company-page/teams-tab/teams-tab")
);

const BillingTab = React.lazy(() =>
  import("../pages/company-page/billing-tab/billing-tab")
);
const ChangePlanPage = React.lazy(() =>
  import("../pages/company-page/billing-tab/change-plan/change-plan")
);
const StripeSuccessPage = React.lazy(() =>
  import("../pages/company-page/billing-tab/stripe-success")
);
const CompanySettingsTab = React.lazy(() =>
  import("../pages/company-page/setting-tab/setting-tab")
);
const ConfirmationsCancellationTwoFA = React.lazy(() =>
  import("../pages/user-setting-page/confirmations_cancellation_twofa")
);
const CompleteRegistration = React.lazy(() =>
  import("../pages/auth/register-page/complete-registration")
);
const editDeleteSecretTeamTab = React.lazy(() =>
  import(
    "../pages/company-page/teams-tab/secret-team-details-page/secret-team-details-page"
  )
);
const addTeam = React.lazy(() =>
  import("../pages/company-page/teams-tab/add-team")
);
const addTeamSecret = React.lazy(() =>
  import(
    "../pages/company-page/teams-tab/secret-team-add-page/secret-tab-add-page"
  )
);
const teamTab = React.lazy(() =>
  import("../pages/company-page/teams-tab/teams-tab")
);
const addUsers = React.lazy(() =>
  import("../pages/company-page/teams-tab/add-user")
);
const checkConfirmationEmail = React.lazy(() =>
  import("../components/check-confirmation-email")
);
const addSecretStep = React.lazy(() =>
  import("../pages/company-page/add-secret-step/addSecretStep")
);
const SignUp = React.lazy(() => import("../pages/auth/register-page/signup"));

const selectAccountType = React.lazy(() =>
  import("../pages/auth/register-page/select-account-type")
);
const microsoftLoading = React.lazy(() =>
  import("../pages/auth/microsoft-signup/microsoft-loading")
);

// const SelectPlan = React.lazy(() =>
//   import("../pages/company-page/select-plan/select-plan")
// );
// const setupBillingInformation = React.lazy(() =>
//   import("../pages/company-page/select-plan/setup-billing-information")
// );

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!rest.userInfo.hasOwnProperty("email")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};
// *** DO NOT CHANGE THE COMPANY PAGE TABS ROUTES SEQUENCE AS THEY ARE TIGHTLY BOUNDED WITH OTHER COMPONENTS
// root routes
const rootRoute = {
  path: "/",
  name: "Home",
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
};
const pageRoutes = {
  path: "/error-404",
  name: "Error - 404",
  component: ErrorPageNotFound,
  route: Route,
};
const secretsRoutes = {
  path: "/dashboard/companies/:companyid/secrets",
  name: "Companies",
  component: secretsTab,
  route: PrivateRoute,
};
const secretDetailsRoutes = {
  path: "/dashboard/companies/:organization_id/secrets/:secret_id",
  name: "Companies",
  component: secretDetails,
  route: PrivateRoute,
};
const usersRoutes = {
  path: "/dashboard/companies/:companyid/users",
  name: "Companies",
  component: UsersTab,
  route: PrivateRoute,
};
const companySettingsRoute = {
  path: "/dashboard/companies/:companyid/settings",
  name: "Company Setting Tab",
  component: CompanySettingsTab,
  route: PrivateRoute,
};

const confirmationsCancellationTwoFARoute = {
  path: "/users/confirmations_cancellation_twofa_inside",
  name: "Confirmations Cancellation Two FA Route",
  component: ConfirmationsCancellationTwoFA,
  route: Route,
};
const TeamsRoute = {
  path: "/dashboard/companies/:companyid/teams",
  name: "Companies Page",
  component: TeamsTab,
  route: PrivateRoute,
};

const BillingRoute = {
  path: "/dashboard/companies/:companyid/billing",
  name: "Billing Page",
  component: BillingTab,
  route: PrivateRoute,
};

const stripeSuccessRoute = {
  path: "/dashboard/companies/:companyid/billing/stripe_checkout_sessions/save",
  name: "StripeSuccessPage",
  component: StripeSuccessPage,
  route: PrivateRoute,
};
const homeAppRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  route: PrivateRoute,
  component: DasboardPage,
};
const addCompanyRoutes = {
  path: "/add-company",
  name: "Add Company",
  route: PrivateRoute,
  component: CreateCompanyPage,
};

//personal secret page
const personalSecretRoute = {
  path: "/user-accounts",
  name: "Personal Secrets Page",
  route: PrivateRoute,
  component: personalSecretPage,
};
const addPersonalSecretRoute = {
  path: "/user-account/create",
  name: "Personal Secret Add Page",
  route: PrivateRoute,
  component: addPersonalSecretPage,
};
const personalSecretDetailsRoute = {
  path: "/user-account/:secret_id/edit",
  name: "Personal Secret Details Page",
  route: PrivateRoute,
  component: personalSecretDetailsPage,
};

const ignoredListsRoute = {
  path: "/ignored-lists",
  name: "IgnoredLists",
  component: IgnoredLists,
  route: PrivateRoute,
};
const userSettingRoute = {
  path: "/me",
  name: "User Setting",
  component: UserSettingPage,
  route: PrivateRoute,
};

const diagnosticInfoRoute = {
  path: "/dashboard/debug",
  name: "Diagnostic Information",
  component: diagnosticInfoPage,
  route: PrivateRoute,
};

const completeRegistrationRoute = {
  path: "/users/confirmation",
  name: "Complete Registration Route",
  component: CompleteRegistration,
  route: Route,
};

const signUpRoute = {
  path: "/signup",
  name: "SignUp",
  component: SignUp,
  route: Route,
};

// Team Page Routes
const addTeamSecretRoute = {
  path: "/dashboard/companies/:companyid/teams/:teamid/secret/create",
  name: "Add Team Secret",
  route: PrivateRoute,
  component: addTeamSecret,
};
const editDeleteSecretTeamTabRoute = {
  path: "/dashboard/companies/:companyid/teams/:teamid/secret/:secretid/edit",
  name: "Edit Delete Secret Team Tab Page",
  route: PrivateRoute,
  component: editDeleteSecretTeamTab,
};
const addTeamRoute = {
  path: "/dashboard/companies/:companyid/team/create",
  name: "Add Team",
  route: PrivateRoute,
  component: addTeam,
};
const editTeamRoute = {
  path: "/dashboard/companies/:companyid/teams/:teamid/:operation",
  name: "Edit Team",
  route: PrivateRoute,
  component: addTeam,
};
const teamTabRoute = {
  path: "/dashboard/companies/:companyid/teams/:teamid",
  name: "Team Tab",
  route: PrivateRoute,
  component: teamTab,
};
const addUsersRoute = {
  path: "/dashboard/companies/:companyid/teams/:teamid/users/invite",
  name: "Team Tab",
  route: PrivateRoute,
  component: addUsers,
};
const changePlanBillingRoute = {
  path: "/dashboard/companies/:companyid/billing-change-plan",
  name: "Change Plan Pricing Page",
  route: PrivateRoute,
  component: ChangePlanPage,
};

// Onboarding process steps
const onBoardingAddScrectStepRoute = {
  path: "/dashboard/companies/:companyid/teams/:teamid/step1/add-secrets",
  name: "Add Secrets Step",
  route: PrivateRoute,
  component: addSecretStep,
};

const onBoardingInviteUsersStepRoute = {
  path: "/dashboard/companies/:companyid/teams/:teamid/step2/users/invite",
  name: "Add User Step",
  route: PrivateRoute,
  component: addUsers,
};

const selectAccountTypeRoute = {
  path: "/user/select-account-type",
  name: "Select Account Type",
  route: PrivateRoute,
  component: selectAccountType,
};

const microsoftLoadingRoute = {
  path: "/microsoft/loading",
  name: "Microsoft Loading",
  route: PrivateRoute,
  component: microsoftLoading,
};

// const selectPlanRoute = {
//   path: "/dashboard/companies/:companyid/select-plan/teams/:teamid",
//   name: "Change Plan Pricing Page",
//   route: PrivateRoute,
//   component: SelectPlan,
// };

// const setupBillingInformationRoute = {
//   path: "/dashboard/companies/:companyid/billing-information/teams/:teamid",
//   name: "Setup Billing Information",
//   route: PrivateRoute,
//   component: setupBillingInformation,
// };

// auth
const authRoutes = {
  path: "/",
  name: "Auth",
  children: [
    {
      path: "/login",
      name: "Login Page",
      component: Login,
      route: Route,
    },
    {
      path: "/register",
      name: "Register page",
      component: Register,
      route: Route,
    },
    {
      path: "/confirmation-email",
      name: "confirmation_email",
      component: ConfirmationEmailPage,
      route: Route,
    },
    {
      path: "/forgotten-password",
      name: "Forget Password",
      component: ForgotPasswordPage,
      route: Route,
    },
    {
      path: "/users/password/edit",
      name: "Reset Password",
      component: ResetPassword,
      route: Route,
    },
    {
      path: "/check-confirmation-email",
      name: "Check Confirmation Email",
      component: checkConfirmationEmail,
      route: Route,
    },
    {
      path: "/reset-password-link-expired",
      name: "Reset Password Link Expired",
      component: ResetPasswordLinkExpired,
      route: Route,
    },
  ],
};

const appRoutes = [
  microsoftLoadingRoute,
  signUpRoute,
  onBoardingAddScrectStepRoute,
  onBoardingInviteUsersStepRoute,
  // selectPlanRoute,
  // setupBillingInformationRoute,
  changePlanBillingRoute,
  addUsersRoute,
  editDeleteSecretTeamTabRoute,
  addTeamSecretRoute,
  editTeamRoute,
  teamTabRoute,
  addTeamRoute,
  secretDetailsRoutes,
  stripeSuccessRoute,
  addCompanyRoutes,
  TeamsRoute,
  BillingRoute,
  confirmationsCancellationTwoFARoute,
  diagnosticInfoRoute,
  companySettingsRoute,
  usersRoutes,
  secretsRoutes,
  ignoredListsRoute,
  homeAppRoutes,
  rootRoute,
  pageRoutes,
  userSettingRoute,
  authRoutes,
  personalSecretRoute,
  personalSecretDetailsRoute,
  addPersonalSecretRoute,
  completeRegistrationRoute,
  selectAccountTypeRoute,
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const allRoutes = [...appRoutes];
// const authProtectedRoutes = [homeAppRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, allFlattenRoutes };
