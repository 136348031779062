import { state } from "../../../store";
export const [
  createComapnyActions,
  createComapnySelector,
  createComapnyState,
] = state("createComapnyPage", {
  initial: {
    organizationName: "",
    industryType: "",
  },
  on: {
    inputChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      return { ...newStoreObj };
    },
  },
  events: ["createCompany"],
});
