import { state } from "../../../store";

export const [
  confirmationEmailPageActions,
  confirmationEmailPageSelector,
  confirmationEmailPageState,
] = state("confirmationEmailPage", {
  initial: {
    email: "",
    notification: "",
    sendConfirmationLoading: false,
    flag: false,
  },
  on: {
    inputChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      return { ...newStoreObj };
    },
    setNotification: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return { ...newStoreObj, notification: data };
    },
    resetNotification: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.flag = data;
      return newStoreObj;
    },
    setSendConfirmationLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        sendConfirmationLoading: data,
      };
    },
  },
  events: ["sendEmailConfirmation"],
});
