import { putResolve, call, spawn, take } from "redux-saga/effects";
import { ForgotPasswordPageActions } from "./store";
import { api } from "../../../helpers/api";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* ForgotPasswordPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        ForgotPasswordPageActions.submitForgotPassword().type
      );

      yield putResolve(
        ForgotPasswordPageActions.setResetAccountBtnLoading(true)
      );
      const form_data = new FormData();

      form_data.append("email", data.email);

      // saving to data to DB
      //backend API FILE > users_controller.rb ln: 475 commented redirection on it
      const result = yield call(
        api,
        `/reset-password`,
        "POST",
        null,
        form_data
      );

      if (result.success === true) {
        data.history.push(`/login?notify=${result.message}`);
      } else {
        //show error --> Email address is required or your account does not exist.

        yield putResolve(
          ForgotPasswordPageActions.setError(
            "Email address is required or your account does not exist."
          )
        );
      }
      yield putResolve(
        ForgotPasswordPageActions.setResetAccountBtnLoading(false)
      );
    } catch (err) {
      yield call(sentryErrorCatch, err, "#111")
      console.log(err);
      toast.error("Something Went Wrong #111", {
        className: "toast-danger",
      });
    }
  }
}
export default function* ForgotPasswordPageRootSaga() {
  yield spawn(ForgotPasswordPageSaga);
}
