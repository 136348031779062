import { state } from "../../../store";

export const [
  diagnosticPageActions,
  diagnosticPageSelector,
  diagnosticPageState,
] = state("diagnosticPage", {
  initial: {
    corruptCompanies: [],
    corruptTeams: [],
    corruptCompanySecrets: [],
    corruptTeamSecrets: [],
    corruptPersonalSecrets: [],
    loading: true,
    error: "",
  },
  on: {
    setCorruptedData: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.corruptCompanies = data.corruptCompaniesAll;
      newStoreObj.corruptTeams = data.corruptTeamsAll;
      newStoreObj.corruptCompanySecrets = data.corruptCompanySecretsAll;
      newStoreObj.corruptTeamSecrets = data.corruptTeamSecretsAll;
      newStoreObj.corruptPersonalSecrets = data.corruptPersonalSecretsAll;

      return newStoreObj;
    },

    setLoading: (_, data) => {
      return { ..._, loading: data };
    },
    setError: (_, data) => {
      let newStoreObj = { ..._, error: data };
      return newStoreObj;
    },
  },
  events: ["loadData"],
});
