import { decrypt, sym_key } from "./apiUtils";
import { org_sym_key } from "./apiUtils";
import { call } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";

export function* get_g_s(org_id, group_id, user_data) {
  let orgSymKey = yield call(org_sym_key, user_data, org_id);
  try {
    return decrypt(
      user_data.orgs_by_id[org_id].groups_by_id[group_id].symKey,
      orgSymKey
    );
  } catch (e) {
    try {
      return decrypt(
        user_data.orgs_by_id[org_id].groups_by_id[group_id].symKey,
        sym_key()
      );
    } catch (e) {
      return false;
    }
  }
}
