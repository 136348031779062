import React, { useEffect } from "react";
import { Routes } from "./routes/routes";
import { ToastContainer } from "react-toastify";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { connect } from "react-redux";
import { state } from "./store";
import { authSelector, authActions } from "./pages/auth/store";
import { subDays } from "./helpers";
import "react-toastify/dist/ReactToastify.css";

//Theme css
import "./assets/scss/Saas.scss";

function clearCookies() {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

function OpenIntercomComponent(props) {
  const { update } = useIntercom();
  useEffect(() => {
    if (!props.email) {
      clearCookies();
    } else {
      window.Intercom("shutdown");

      update({
        name: props.name,
        email: props.email,
        customAttributes: {
          created_at: props.created_at,
          user_id: props.userId,
          companies: props.companies,
          "Intention to use as": props.intention_to_use_as,
          user_hash: props.user_hash,
          company_owner: props.company_owner,
          "Company Size": props.CompanySize,
          "First Company CreatedAt": props.firstCompanyCreatedAt,
        },
      });
    }
  }, []);
  return <></>;
}
export default connect(
  state.select({
    me: authSelector((state) => state.me, {}),
  }),
  null
)(
  class App extends React.Component {
    render() {
      const { me } = this.props;

      let userData = me.user ? { ...me.user } : null;

      let companies = null;
      let firstCompanyCreatedAt;
      if (userData && me.orgs && me.orgs.length > 0) {
        let firstCompanyCreated = me.orgs.sort(function (a, b) {
          return new Date(a.created_at) - new Date(b.created_at);
        })[0];
        firstCompanyCreatedAt = new Date(firstCompanyCreated.created_at)
          .toLocaleString()
          .split(",")[0];

        companies = me.orgs.map((org) => {
          let amount;
          let paymentEndDate;
          let three_days_to_payment;
          let one_day_to_payment;
          if (org.upcoming_invoice) {
            amount =
              org.upcoming_invoice.total > 0
                ? org.upcoming_invoice.total / 100 // convert cents to doller
                : 0;
            paymentEndDate = new Date(org.upcoming_invoice.period_end * 1000)
              .toISOString()
              .slice(0, 19)
              .replace("T", " ");

            let period_end = new Date(paymentEndDate);
            three_days_to_payment = subDays(period_end, 3);
            one_day_to_payment = subDays(period_end, 1);
          }
          return {
            id: org.id,
            owner: org.owner_name,
            admins: org.admins,
            number_of_admins: org.number_of_admins,
            users: org.org_users
              ? org.org_users[0].map((user) => {
                  return {
                    email: user.email,
                    reset_password_sent_at: user.reset_password_sent_at,
                    remember_created_at: user.remember_created_at,
                    sign_in_count: user.sign_in_count,
                    current_sign_in_at: user.current_sign_in_at,
                    last_sign_in_at: user.last_sign_in_at,
                    current_sign_in_ip: user.current_sign_in_ip,
                    last_sign_in_ip: user.last_sign_in_ip,
                    created_at: user.created_at,
                    updated_at: user.updated_at,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    confirmed_at: user.confirmed_at,
                    confirmation_sent_at: user.confirmation_sent_at,
                    unconfirmed_email: user.unconfirmed_email,
                    stripe_token: user.stripe_token,
                    latest_update: user.latest_update,
                  };
                })
              : [],
            number_of_users: org.number_of_users,
            number_of_accounts: org.number_of_accounts,
            name: org.name,
            created_at: org.created_at,
            updated_at: org.updated_at,
            description: org.description,
            stripe_token: org.stripe_token,
            plan: org.plan,
            upcoming_invoice_total: amount ? amount : 0,
            "Have credit card": org.have_credit_card,
            three_days_to_payment: three_days_to_payment
              ? three_days_to_payment
              : "",
            one_day_to_payment: one_day_to_payment ? one_day_to_payment : "",
            payment_date: paymentEndDate ? paymentEndDate.split(" ")[0] : "",
          };
        });
      }

      return (
        <>
          <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM_APP_ID}
            autoBoot
          >
            <OpenIntercomComponent
              {...{
                key: Math.random(),
                appId: process.env.REACT_APP_INTERCOM_APP_ID,
                name: userData ? userData.firstname : undefined,
                email: userData ? userData.email : undefined,
                userId: userData ? userData.id : undefined,
                user_hash: userData ? userData.intercom_id : undefined,
                company_owner: userData ? userData.company_owner : undefined,
                created_at: userData ? userData.created_at : undefined,
                intention_to_use_as: userData
                  ? userData.intention_to_use_as
                  : null,

                companies,
                CompanySize: userData ? userData.organizations_size : undefined,
                firstCompanyCreatedAt: firstCompanyCreatedAt
                  ? firstCompanyCreatedAt
                  : undefined,
              }}
            />
          </IntercomProvider>

          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes></Routes>
        </>
      );
    }
  }
);
