import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { settingPageActions } from "./store";
import {
  getLatestUserData,
  generateNewUserInfoObject,
  generateNewPasswordObject,
  sym_key,
  encrypt,
  decrypt,
  org_sym_key,
  sym_key_by_org_id,
} from "../../helpers/apiUtils";
import { api } from "../../helpers/api";
import { toast } from "react-toastify";
import { authSelector, authActions } from "../auth/store";
import sentryErrorCatch from "../../helpers/sentryUtils";

function* loadDataSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(settingPageActions.loadData().type);

      const { me } = yield select(authSelector((state) => state));

      yield putResolve(settingPageActions.load(me.user));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#187");
      toast.error("Something Went Wrong #187", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}

function* UpdateProfileSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingPageActions.updateProfile().type
      );

      yield putResolve(settingPageActions.setUpdateProfileLoading(true));

      let { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();
      form_data.append("utf8", "✓");
      form_data.append("_method", "patch");
      form_data.append("authenticity_token", csrfToken);
      for (let key in data) {
        form_data.append(`user[${key}]`, data[key]);
      }

      let apiData = yield call(
        api,
        `/me/me_v2.json`,
        "POST",
        null,
        form_data,
        null
      );

      let me = yield call(getLatestUserData);
      yield putResolve(authActions.setMe(me));
      yield putResolve(settingPageActions.setUpdateProfileLoading(false));
      if (apiData.status === true) {
        // toaster
        toast.success(apiData.notice, {
          className: "toast-success",
        });
        const currentEmail = me.user.email;
        const newEmail = data.email;
        if (currentEmail !== newEmail) {
          yield putResolve(authActions.logoutUser({ notify: apiData.notice }));
        }
      } else if (
        apiData.status === false &&
        apiData.errors &&
        apiData.errors.email &&
        apiData.errors.email.length > 0
      ) {
        toast.error(apiData.errors.email[0], {
          className: "toast-danger",
        });
      } else {
        yield call(sentryErrorCatch, "API response is empty", "#188");
        toast.error("Something Went Wrong #188", {
          className: "toast-danger",
        });
      }

      // yield putResolve(settingPageActions.load(me.user));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#189");
      toast.error("Something Went Wrong #189", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}

function* UpdatePasswardSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingPageActions.updatePassword().type
      );
      yield putResolve(settingPageActions.setUpdatePasswordLoading(true));

      let { csrfToken } = yield select(authSelector((state) => state));
      const me = yield call(getLatestUserData);
      const user_me_data = me;
      var salts = yield call(
        api,
        `/me/salts?email=${user_me_data.user.email}`,
        null,
        null,
        null,
        csrfToken
      );

      salts["currentpassword"] = data.currentpassword;

      var args = yield call(generateNewPasswordObject, salts);

      var result = args.result;
      if (!result) {
        toast.error("Incorrect Current Password", {
          className: "toast-danger",
        });
      } else {
        var ui = {
          firstname: data.firstname,
          lastname: data.lastname,
          password: data.newpassword,
          email: data.email,
        };

        let UIObj = yield call(generateNewUserInfoObject, ui);
        // const { me } = yield select(authSelector((state) => state));

        UIObj["private_key_encrypted"] = me.user.private_key_encrypted;
        UIObj["public_key"] = me.user.pubKeyEncrypted;

        UIObj.old_hash = args.hash;

        var symKey = sym_key();

        // new sym key
        var new_sym_key = UIObj.symKey;

        // re-encrypt rsa key with new symkey
        UIObj.private_key_encrypted = encrypt(
          decrypt(me.user.private_key_encrypted, symKey),
          new_sym_key
        );
        UIObj.public_key = me.user.pubKeyEncrypted;

        // Now pull all user accounts and re-encrypt with new symkey
        var user_accounts = [];

        for (let i = 0; i < me.user.accounts.length; i++) {
          let secret = me.user.accounts[i];
          try {
            user_accounts.push(yield call(decrypt, secret, symKey));
          } catch (err) {
            console.log("Personal Secrets Corrupted ", err);
            yield call(sentryErrorCatch, err, "#235");
            yield call(sentryErrorCatch, err, "#235");
          }
        }

        var user_accounts_update = [];
        for (var i = 0; i < user_accounts.length; i++) {
          // new account holder
          var account = {};
          for (var arg in user_accounts[i]) {
            // only encrypt non-ids and special variables

            if (
              !arg.match(/^created_at$|^updated_at$|^id$|.*?_id$|^auto_login$/)
            ) {
              try {
                account[arg] = encrypt(user_accounts[i][arg], new_sym_key);
              } catch (e) {
                yield call(sentryErrorCatch, e, "#207");
              }
            } else {
              account[arg] = user_accounts[i][arg];
            }
          }
          // now need to save each user account
          user_accounts_update.push((true, {}, account));
        }

        var ignore_list = me.ignore;
        var ignore_lists_update = [];
        for (var domain = 0; domain < ignore_list.length; domain++) {
          var to_ignore = {};
          for (var key in ignore_list[domain]) {
            if (!key.match(/^created_at$|^updated_at$|^id$|.*?_id$/)) {
              try {
                to_ignore[key] = encrypt(
                  decrypt(ignore_list[domain][key], symKey),
                  new_sym_key
                );
              } catch (e) {
                yield call(sentryErrorCatch, e, "#208");
              }
            } else {
              to_ignore[key] = ignore_list[domain][key];
            }
          }
          ignore_lists_update.push((true, {}, to_ignore));
        }

        var organization_groups_update = [];
        var organizations_update = [];
        var shared_groups_update = [];

        for (let org in me.orgs_by_id) {
          try {
            var Org_sym_key = yield call(org_sym_key, me, org);
          } catch (e) {
            yield call(sentryErrorCatch, e, "#209");
          }
          var is_admin = me.orgs_by_id[org].admin;

          var groups = me.orgs_by_id[org].groups;
          var is_owner = me.orgs_by_id[org].owner;

          if (is_admin) {
            // org sym keys update
            try {
              organizations_update.push({
                organization_sym_key: encrypt(Org_sym_key, new_sym_key),
                id: org,
              });
            } catch (e) {
              yield call(sentryErrorCatch, e, "#210");
            }
          }

          // get groups and re-encrypt with new sym_key

          for (var i = 0; i < groups.length; i++) {
            var group = groups[i];
            var group_accounts = group.accounts;

            if (is_owner && is_admin) {
              try {
                var group_sym = decrypt(group.symKey, Org_sym_key);
                // add to info
                organization_groups_update.push({
                  group_sym_key: encrypt(group_sym, Org_sym_key),
                  group_id: group.id,
                  organization_id: org,
                });
              } catch (e) {
                yield call(sentryErrorCatch, e, "#211");
              }
            } else if (is_admin) {
            } else {
              try {
                var group_sym = decrypt(group.symKey, sym_key());
                // update group accounts
                shared_groups_update.push({
                  organization_id: org,
                  symmetric_user_key_encrypted: encrypt(group_sym, new_sym_key),
                  group_id: group.id,
                });
              } catch (e) {
                yield call(sentryErrorCatch, e, "#212");
              }
            }
          }
        }

        // delete UIObj.symKey;
        delete UIObj.firstname;
        delete UIObj.lastname;
        delete UIObj.public_key;

        const form_data = new FormData();

        for (let key in UIObj) {
          form_data.append(`user[${key}]`, UIObj[key]);
        }
        for (var domain = 0; domain < ignore_lists_update.length; domain++) {
          for (var key in ignore_lists_update[domain]) {
            form_data.append(
              `ignore_list[${domain}][${key}]`,
              ignore_lists_update[domain][key]
            );
          }
        }
        for (var domain = 0; domain < user_accounts_update.length; domain++) {
          for (var key in user_accounts_update[domain]) {
            form_data.append(
              `user_accounts_update[${domain}][${key}]`,
              user_accounts_update[domain][key]
            );
          }
        }

        for (
          var domain = 0;
          domain < organization_groups_update.length;
          domain++
        ) {
          for (var key in organization_groups_update[domain]) {
            form_data.append(
              `groups[${domain}][${key}]`,
              organization_groups_update[domain][key]
            );
          }
        }

        for (var domain = 0; domain < shared_groups_update.length; domain++) {
          for (var key in shared_groups_update[domain]) {
            form_data.append(
              `shared_groups[${domain}][${key}]`,
              shared_groups_update[domain][key]
            );
          }
        }

        for (var domain = 0; domain < organizations_update.length; domain++) {
          for (var key in organizations_update[domain]) {
            form_data.append(
              `organizations_update[${domain}][${key}]`,
              organizations_update[domain][key]
            );
          }
        }

        let result1 = yield call(
          api,
          `/me/${me.user.id}.json`,
          "PUT",
          null,
          form_data,
          salts.csrfToken
        );

        if (result1.status === true) {
          alert("Password has been reset, please login with your new password");
          yield call(
            api,
            `/users/sign_out`,
            "DELETE",
            null,
            null,
            salts.csrfToken
          );

          window.location.href = "/login";
        }
      }
      yield putResolve(settingPageActions.setUpdatePasswordLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#190");
      toast.error("Something Went Wrong #190", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}

function* exportPersonalSecretsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingPageActions.exportSecret().type
      );
      yield putResolve(settingPageActions.setExportPersonalSecretLoading(true));
      let { csrfToken } = yield select(authSelector((state) => state));
      let apiData = yield call(
        api,
        `/user_accounts.json?only=personal`,
        null,
        null,
        null,
        csrfToken
      );

      if (apiData.length === 0) {
        toast.error("No personal secrets available", {
          className: "toast-danger",
        });
      } else {
        var key;
        var user_accounts = [];
        for (var i = 0; i < apiData.length; i++) {
          try {
            if (apiData[i].organization_id != null) {
              key = decrypt(
                sym_key_by_org_id(apiData[i].organization_id),
                sym_key()
              );
            } else {
              key = sym_key();
            }
          } catch (e) {
            yield call(sentryErrorCatch, e, "#213");
          }

          try {
            let account = {
              auto_login: apiData[i].auto_login,
              cipher: decrypt(apiData[i].cipher, key),
              login: decrypt(apiData[i].login, key),
              login_origin: decrypt(apiData[i].login_origin, key),
              login_page_hostname: decrypt(apiData[i].login_page_hostname, key),
              login_page_title: decrypt(apiData[i].login_page_title, key),
              login_string: decrypt(apiData[i].login_page_title, key),
              notes: decrypt(apiData[i].notes, key),
              page_title: decrypt(apiData[i].page_title, key),
              redirect_origin: decrypt(apiData[i].redirect_origin, key),
              redirect_page_hostname: decrypt(
                apiData[i].redirect_page_hostname,
                key
              ),
              redirect_page_title: decrypt(apiData[i].redirect_page_title, key),
              url: decrypt(apiData[i].url, key),
            };

            user_accounts[i] = account;
          } catch (e) {
            let corruptAccount = {
              auto_login: apiData[i].auto_login,
              cipher: "",
              login: "",
              login_origin: "",
              login_page_hostname: "",
              login_page_title: "",
              login_string: "",
              notes: "",
              page_title: "",
              redirect_origin: "",
              redirect_page_hostname: "",
              redirect_page_title: "",
              url: "",
            };
            user_accounts[i] = corruptAccount;
          }
        }

        //convert_to_csv
        var attributes = [
          "auto_login",
          "page_title",
          "redirect_page_title",
          "cipher",
          "login",
          "notes",
          "url",
        ];
        var csv_content = attributes.join(",");
        csv_content += "\n";
        for (var i = 0; i < user_accounts.length; i++) {
          var c = [];
          for (var j = 0; j < attributes.length; j++) {
            c[j] = user_accounts[i][attributes[j]];
          }
          csv_content += c.join(",");
          csv_content += "\n";
        }

        //download_csv
        var encodedContent = encodeURIComponent(csv_content);

        var encodedURI = "data:text/csv;charset=utf-8," + encodedContent;

        var link = document.createElement("a");
        link.setAttribute("href", encodedURI);
        link.setAttribute("download", "user_accounts.csv");
        link.click();
        yield putResolve(
          settingPageActions.setExportPersonalSecretLoading(false)
        );
        toast.success("Secrets exported!", {
          className: "toast-success",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#191");
      toast.error("Something Went Wrong #191", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}

function* deleteAccountSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingPageActions.deleteAccount().type
      );
      let { csrfToken } = yield select(authSelector((state) => state));
      const form_data = new FormData();

      form_data.append("_method", "delete");
      form_data.append("authenticity_token", csrfToken);
      const result = yield call(
        api,
        `/users.json`,
        "POST",
        null,
        form_data,
        null
      );
      window.location.href = "/login?notify=Account Deleted";
    } catch (err) {
      yield call(sentryErrorCatch, err, "#192");
      toast.error("Something Went Wrong #192", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}

function* cancelTwoFASuccessfullySaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        settingPageActions.cancelTwoFASuccessfully().type
      );

      const { csrfToken } = yield select(authSelector((state) => state));
      const result = yield call(
        api,
        `/users/confirmations_cancellation_twofa_inside?token=${data.token}`,
        "GET",
        null,
        null,
        csrfToken
      );
      if (result.status === true) {
        toast.success(result.message, {
          className: "toast-success",
        });
        data.history.push("/login");
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#193");
      toast.error("Something Went Wrong #193", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}

export default function* settingPageRootSaga() {
  yield spawn(loadDataSaga);
  yield spawn(UpdateProfileSaga);
  yield spawn(UpdatePasswardSaga);
  yield spawn(exportPersonalSecretsSaga);
  yield spawn(deleteAccountSaga);
  yield spawn(cancelTwoFASuccessfullySaga);
}
