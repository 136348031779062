import { state } from "../../../../store";

export const [
  secretTabAddActions,
  secretTabAddSelector,
  secretTabAddState,
] = state("secretTabAdd", {
  initial: {
    domains: { items: [], loading: false },
    loading: false,
    selectApplicationError: false,
    selectApplication: "",
    page_title: "",
    url: "",
    login: "",
    cipher: "",
    notes: "",
  },
  on: {
    load: (_, data) => {
      const newStoreObj = { ..._ };
      newStoreObj.domains.items = data;
      return newStoreObj;
    },
    setLoading: (_, data) => {
      const newStoreObj = { ..._ };
      return { ...newStoreObj, loading: data };
    },
  },
  events: ["loadData", "addSecretAuto", "addSecretCustom", "existingSecret","loadTeamsData","importSecretsBulk"],
});
