import React from "react";

/**
 * Renders the preloader
 */
const Loader = (props) => {
  return (
    <div
      className="preloader"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className="status">
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
