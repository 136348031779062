import { take, call, spawn, select, putResolve } from "redux-saga/effects";
import { teamTabEditDeleteActions, teamTabEditDeleteSelector } from "./store";
import {
  create_sym_key,
  encrypt,
  sym_key,
  decryptEncryptionHelper,
  remove_placeholders_from_login_string,
  sym_key_by_org_id_decrypt_new,
  replace_login_and_password_for_dd_login_string,
  getLatestUserData,
} from "../../../../helpers/apiUtils";
import { api } from "../../../../helpers/api";
import { authActions, authSelector } from "../../../auth/store";
import { toast } from "react-toastify";
import { secretActions } from "../../../../components/secrets/store";
import {
  decrypt_organization_secret,
  decrypt_organization_secret_without_latest_me,
} from "../../../../helpers/apiUtils";
import { teamsTabActions, teamsTabSelector } from "../store";
import {
  get_group,
  get_org,
} from "../../../../helpers/ckService/UserDataHelper";
import {
  decrypt,
  decryptObjectMarkingCorrupt,
  isCorruptAfterDecryption,
} from "../../../../helpers/ckService/EncryptionHelper";
import { getGroupAccount } from "../../../../helpers/ckService/AccountService";
import {
  check_if_exists_except,
  convert_encrypt_to_decrypt_based_on_type,
  delete_param_for_org_grp_account,
} from "../../../../helpers/organization";
import { ckServiceSymKey } from "../../../../helpers/ckService/AccountEncryptionRules";
import sentryErrorCatch from "../../../../helpers/sentryUtils";

// Display secret by Id
function* teamSecretByIdSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamTabEditDeleteActions.teamSecretById().type
      );
      yield putResolve(teamTabEditDeleteActions.setLoading(true));

      const { me, csrfToken } = yield select(authSelector((state) => state));
      const user_data = me;

      const apiData = yield call(
        api,
        `/groups/${data.organization_group_id}/accounts/${data.secret_id}.json`, //groupid and secretID
        "GET",
        null,
        null,
        csrfToken
      );
      const decryptedSecret = yield call(
        decrypt_organization_secret,
        user_data,
        apiData
      );

      // decryptedSecret["id"] = data.account_id;
      yield putResolve(secretActions.loadSecret(decryptedSecret));
      yield putResolve(teamTabEditDeleteActions.loadSecret(decryptedSecret));
      yield putResolve(teamTabEditDeleteActions.setLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#126");
      // toaster
      toast.error("Something went wrong #126", {
        className: "toast-danger",
      });
      console.log("error in teamSecretByIdSaga", err);
    }
  }
}

//delete team secret

function* deleteOrganizationTeamSecretSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamTabEditDeleteActions.deleteOrganizationTeamSecret().type
      );
      // yield putResolve(secretActions.setDeleteButtonLoading(true));
      yield putResolve(
        teamsTabActions.updatingSecret({
          secretId: data.account_id,
          flag: true,
        })
      );
      const { me, csrfToken } = yield select(authSelector((state) => state));
      const team = yield select(
        teamsTabSelector((state) => state.currentCompany.team)
      );
      let user_data = me;
      let apiData;
      const form_data = new FormData();
      var url = "";

      if (csrfToken) {
        var organizationId = parseInt(data.organization_id);
        var groupId = parseInt(data.group_id);
        var accountId = parseInt(data.account_id);

        var account = yield call(
          getGroupAccount,
          organizationId,
          groupId,
          accountId,
          user_data
        );

        // var ck_user;
        let orgSymKey = get_org(user_data);
        let grpSymKey = get_group(user_data);
        var isCorrupt = isCorruptAfterDecryption(
          account,
          //commonkey,
          //cksession,
          orgSymKey,
          grpSymKey
        );
        form_data.append("corrupt", isCorrupt);
        url = `/groups/${data.group_id}/accounts/${data.account_id}`;
      }

      // perosnal secret API
      apiData = yield call(api, url, "DELETE", null, form_data, csrfToken);

      if (apiData.success === true) {
        //toaster
        toast.success("Secret Delete Successfully", {
          className: "toast-success",
        });
        yield putResolve(
          teamsTabActions.updatingSecret({
            secretId: data.account_id,
            flag: false,
          })
        );
        yield putResolve(
          teamsTabActions.deleteSecret({ secretId: data.account_id })
        );

        // yield putResolve(secretActions.setDeleteButtonLoading(false));
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#127");
      // toaster
      toast.error("Something went wrong #127", {
        className: "toast-danger",
      });
      console.log("deleteOrganizationSecretSaga", err);
    }
  }
}

function* generatePayload(data, decryptedSecret, userRole) {
  let newSecret = {};
  newSecret = {
    account_id: decryptedSecret.account_id, //"125",
    custom: decryptedSecret.auto_login ? false : true,
    custom_title: decryptedSecret.custom_title,
    group_id: decryptedSecret.group_id,
    login: data.login,
    login_hostname: "Custom", //data.login_page_hostname,
    notes: data.notes,
    organization_id: decryptedSecret.organization_id,
    page_title: decryptedSecret.page_title,
    password: data.password,
    redirect_hostname: undefined,
    type: "organization", //manager (In custom record and auto login record)
    url: data.url,
    history: data.history,
    currentTeamId: data.teamId,
  };

  if (userRole === "manager") {
    newSecret["account_id"] = decryptedSecret.id;
  }

  return newSecret;
}

function* updateOrganizationTeamSecretSaga() {
  while (true) {
    try {
      let { payload: data } = yield take(
        teamTabEditDeleteActions.updateOrganizationTeamSecret().type
      );

      const secretIdForUpdatingUI = data.id;
      yield putResolve(
        teamsTabActions.updatingSecret({
          secretId: data.id,
          flag: true,
        })
      );

      const { team } = yield select(
        teamsTabSelector((state) => state.currentCompany)
      );
      const { item } = yield select(
        teamTabEditDeleteSelector((state) => state.userAccount)
      );

      const { userRole } = yield select(teamsTabSelector((state) => state));
      data = yield call(generatePayload, data, item, userRole);
      // login, login_hostname, redirect_hostname, account_id, type required
      // message
      if (
        !data.login ||
        !data.login_hostname ||
        !data.id ||
        !data.type ||
        !data.redirect_hostname
      ) {
        const { me, csrfToken } = yield select(authSelector((state) => state));

        let user_data = me;
        let url = "";
        if (
          me.orgs_by_id[data.organization_id] &&
          !me.orgs_by_id[data.organization_id].admin
        ) {
          url = `/me/all_accounts?group_id=${data.group_id}`;
        } else {
          url = `/me/all_accounts/`;
        }
        var urlAjax = yield call(api, url, "GET", null, null, csrfToken);

        if (urlAjax.length > 0) {
          // Convert all encrypted account to decrypted account
          // according to type
          // user - manager - owner - admin
          let accounts;
          if (userRole === "owner" || userRole === "admin") {
            // decrypt org secret (Owner /Admin)
            accounts = convert_encrypt_to_decrypt_based_on_type(urlAjax, me);
          } else {
            urlAjax = urlAjax.filter(
              (secret) =>
                secret.class_name != "UserAccount" &&
                secret.class_name != "OrganizationAccount"
            );
            accounts = urlAjax.map((secret) => {
              let accountId = parseInt(secret.id);
              let groupId = parseInt(secret.organization_group_id);
              let organizationId = parseInt(secret.organization_id);
              let groupAccounts =
                user_data.orgs_by_id[organizationId].groups_by_id[groupId]
                  .accounts;

              //getting current account object
              let organizationAccount = groupAccounts.filter(
                (account) => account.id === accountId
              );

              if (organizationAccount) {
                organizationAccount = organizationAccount[0];
                let organization_id = get_org(
                  user_data,
                  organizationId
                ).organization_sym_key;
                let groupSymKey = get_group(
                  user_data,
                  groupId,
                  organizationId
                ).symKey;

                //return object with all info with symkey of account
                let symKey = ckServiceSymKey(
                  organizationAccount,
                  organization_id,
                  groupSymKey
                );
                //Decrypting the secret and also checking for corrupt secrets
                let decryptedAccount = decryptObjectMarkingCorrupt(
                  organizationAccount,
                  symKey
                );
                return decryptedAccount;
              }
            });
          }

          if (
            check_if_exists_except(
              data.account_id,
              data.login,
              data.login_hostname,
              data.redirect_hostname,
              accounts
            ) !== false
          ) {
            alert("dublicate record");
            //Toaster
            //message => duplicate record found
            return;
          }

          var to_save = {
            login: data.login,
            page_title: data.page_title,
            cipher: data.password,
            notes: data.notes,
            custom_title: data.custom_title,
          };

          // save all shit
          ///====> not requred for personal secret

          if (data.save_all) {
            to_save = Object.assign(to_save, {
              login_origin: data.login_url,
              redirect_origin: data.redirect_origin,
              url: data.url,
              needs_to_be_completed: false,
              login_page_hostname: data.login_hostname,
              login_string: remove_placeholders_from_login_string(
                data.login_string,
                data.login,
                data.password
              ),
              redirect_page_title: data.redirect_page_title,
              login_page_title: data.login_page_title,
              redirect_page_hostname: data.redirect_hostname,
              //auto_login: !args.custom
            });
          }
          ///====> not requred for personal secret

          if (data.custom) {
            to_save.login_page_hostname = data.page_title;
            to_save.login_page_title = data.page_title;
            to_save.redirect_page_title = data.page_title;
            to_save.redirect_page_hostname = data.page_title;
            to_save.url = data.url;
            to_save.login_string = "";
          } else {
            if (!data.save_all) {
              // find this account in current accounts and lets update the login_string
              var account_ref = accounts.filter(
                (account) =>
                  account.id == parseInt(data.account_id) &&
                  (account.type == data.type ||
                    account.organization_id == data.organization_id ||
                    account.organization_group_id == parseInt(data.group_id))
              );

              if (account_ref.length == 1) {
                if (
                  account_ref[0].login_string &&
                  account_ref[0].login &&
                  to_save.login
                ) {
                  to_save.login_string =
                    replace_login_and_password_for_dd_login_string(
                      account_ref[0].login_string,
                      account_ref[0].login,
                      to_save.login
                    );
                }
                if (
                  to_save.login_string &&
                  account_ref[0].cipher &&
                  to_save.cipher
                ) {
                  to_save.login_string =
                    replace_login_and_password_for_dd_login_string(
                      to_save.login_string,
                      account_ref[0].cipher,
                      to_save.cipher
                    );
                }
              } else {
                //toaster
                //
                alert(
                  "Oh no an error occurred! We're on it! More than one account with the same id...impossible"
                );
                return;
              }
            }
          }

          //Arrange the data to be given to form data and encrypt it.
          // rename
          var save = {};
          for (let x in to_save) {
            save[data.type + "_" + "account[" + x + "]"] = to_save[x];
          }

          // encrypt
          let save_enc = {};

          // setup url
          url = {};
          if (
            user_data.orgs_by_id[data.organization_id] &&
            !user_data.orgs_by_id[data.organization_id].admin
          ) {
            data.type = "manager";
          }

          switch (data.type) {
            case "user":
              url.path = `/user_accounts/${data.account_id}.json`;
              save_enc = encrypt(save, sym_key());
              break;
            case "organization":
              save_enc = encrypt(
                save,
                sym_key_by_org_id_decrypt_new(data.organization_id, user_data)
              );

              url.organization_id = data.organization_id;
              url.path = `/organizations/${data.organization_id}/accounts/${data.account_id}.json`;
              break;

            case "manager":
              let org = user_data.orgs_by_id[data.organization_id];
              let group = org.groups.filter(
                (group) => group.id === data.group_id
              )[0];
              var key = group.symKey;

              var decrypt_org_group_symkey = decrypt(key, sym_key());
              delete_param_for_org_grp_account(save);
              save_enc = encrypt(save, decrypt_org_group_symkey);

              url.organization_id = data.organization_id;

              url.path = `/organizations/${data.organization_id}/groups/${data.group_id}/accounts/${data.account_id}/update_manager.json`;

              // If manager, handle saving within this callback.
              //instance.saveAccount(args, argsdup, to_save, url, save_enc);
              break;
          }

          const form_data = new FormData();
          for (let key in save_enc) {
            form_data.append(key, save_enc[key]);
          }

          let apiData;
          apiData = yield call(
            api,
            url.path,
            "PUT",
            null,
            form_data,
            csrfToken
          );
          let orgSecretUpdate;
          if (apiData.success === true) {
            if (data.type == "organization" && apiData.groups) {
              // rename for group save
              var save = {};

              // save it all
              var save = {};
              for (var arg in to_save) {
                if (arg != "needs_to_be_completed") {
                  save["organization_group_account[" + arg + "]"] =
                    to_save[arg];
                }
              }

              var groups = apiData.organization_group_accounts;

              for (var g in groups) {
                if (g === "isArray") {
                  //argsdup.success(d);
                  continue;
                }

                // encrypt
                var gsk = decrypt(
                  groups[g].k,
                  sym_key_by_org_id_decrypt_new(url.organization_id, user_data)
                );

                delete_param_for_org_grp_account(save);

                var g_save = encrypt(save, gsk);

                // id of account just saved
                g_save["organization_group_account[organization_account_id]"] =
                  apiData.organization_account.id;

                const group_form_data = new FormData();
                //Form Data for Group
                for (let key in g_save) {
                  group_form_data.append(key, g_save[key]);
                }

                orgSecretUpdate = yield call(
                  api,
                  `/organizations/${url.organization_id}/groups/${groups[g].group_id}/accounts/${groups[g].organization_group_account}.json`, //orgid and account_id
                  "put",
                  null,
                  group_form_data,
                  csrfToken
                );
                console.log("orgSecretUpdate org.grp.acc", orgSecretUpdate);
              }
            }
            toast.success("Secret Update Successfully", {
              className: "toast-success",
            });
            let decryptedSecret;
            apiData = yield call(
              api,
              `/groups/${data.group_id}/accounts/${secretIdForUpdatingUI}.json`, //groupid and secretID
              "GET",
              null,
              null,
              csrfToken
            );

            try {
              if (orgSecretUpdate.success === true) {
                decryptedSecret = yield call(
                  decrypt_organization_secret_without_latest_me, //decrypt_organization_secret,
                  me,
                  orgSecretUpdate.organization_group_account
                );
                decryptedSecret.visible = true;

                yield putResolve(
                  teamsTabActions.updatingSecret({
                    secretId: secretIdForUpdatingUI,
                    flag: false,
                    secretToUpdate: decryptedSecret,
                  })
                );

                const user_me_data = yield call(getLatestUserData);

                yield putResolve(authActions.setMe(user_me_data));
              }
            } catch (error) {
              //incase something goes wrong with new decryption without fetching latest me
              const user_me_data = yield call(getLatestUserData);

              decryptedSecret = yield call(
                decrypt_organization_secret,
                user_me_data,
                apiData
              );
              decryptedSecret.visible = true;

              yield putResolve(authActions.setMe(user_me_data));

              yield putResolve(
                teamsTabActions.toggleViewSecret(secretIdForUpdatingUI)
              );
              yield putResolve(
                teamsTabActions.updatingSecret({
                  secretId: secretIdForUpdatingUI,
                  flag: false,
                  secretToUpdate: decryptedSecret,
                })
              );
            }
          } else {
            yield call(sentryErrorCatch, "API response is empty", "#128");
            //toaster;
            toast.error("Something went wrong #128", {
              className: "toast-danger",
            });
          }
        } else {
          //toaster;
          toast.error("No Secret Accounts", {
            className: "toast-danger",
          });
        }
      } else {
        yield call(sentryErrorCatch, "Condition false", "#129");
        //toaster;
        toast.error("Something went wrong #129", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#130");
      //toaster;
      toast.error("Something went wrong #130", {
        className: "toast-danger",
      });
      console.log("error in updateOrganizationTeamSecretSaga", err);
    }
  }
}

export default function* editDeleteSecretTeamTabRootSaga() {
  yield spawn(teamSecretByIdSaga);
  yield spawn(deleteOrganizationTeamSecretSaga);
  yield spawn(updateOrganizationTeamSecretSaga);
}
