import { state } from "../../store";
import zxcvbn from "zxcvbn";

export const [
  settingPageActions,
  settingPageSelector,
  settingPageState,
] = state("settingPage", {
  initial: {
    data: [],
    loading: true,
    error: "",
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    password_reminder_question: "",
    currentpassword: "",
    newpasswordConfirmation: "",
    passwordError: null,
    confirmPassowrdError: "",
    progressCount: 0,
    progressColor: "",
    progressStatus: "",
    timeToCrack: "0 Seconds",
    suggestionList: [],
    updateProfileLoading: false,
    updatePasswordLoading: false,
    exportPersonalSecretLoading: false,
  },
  on: {
    load: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.data = data;

      newStoreObj.email = data.email;
      newStoreObj.firstname = data.firstname;
      newStoreObj.lastname = data.lastname;
      newStoreObj.password_reminder_question = data.password_reminder_question;
      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    setError: (_, data) => {
      let newStoreObj = { ..._, error: data };
      return newStoreObj;
    },
    setUpdateProfileLoading: (_, data) => {
      let newStoreObj = { ..._, updateProfileLoading: data };
      return newStoreObj;
    },
    setUpdatePasswordLoading: (_, data) => {
      let newStoreObj = { ..._, updatePasswordLoading: data };
      return newStoreObj;
    },
    inputChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      return { ...newStoreObj };
    },
    passwordChangeHandler: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.password = data;

      let pass_strength = data.length ? zxcvbn(data) : { score: 7 };
      switch (pass_strength.score) {
        case 0:
          newStoreObj.progressCount = 0;
          newStoreObj.progressColor = "danger";
          newStoreObj.progressStatus = "WEAK (0/4)";
          break;
        case 1:
          newStoreObj.progressCount = 25;
          newStoreObj.progressColor = "danger";
          newStoreObj.progressStatus = "POOR (1/4)";

          break;
        case 2:
          newStoreObj.progressCount = 50;
          newStoreObj.progressColor = "danger";
          newStoreObj.progressStatus = "OK (2/4)";

          break;
        case 3:
          newStoreObj.progressCount = 75;
          newStoreObj.progressColor = "success";
          newStoreObj.progressStatus = "GOOD (3/4)";

          break;
        case 4:
          newStoreObj.progressCount = 100;
          newStoreObj.progressColor = "success";
          newStoreObj.progressStatus = "GREAT (4/4)";
          break;
        default:
          newStoreObj.progressCount = 0;
          newStoreObj.progressColor = "";
          newStoreObj.progressStatus = "";
      }

      newStoreObj.suggestionList = pass_strength
        ? pass_strength.feedback
          ? pass_strength.feedback.suggestions
            ? pass_strength.feedback.suggestions
            : []
          : []
        : [];

      newStoreObj.timeToCrack = pass_strength.crack_times_display
        ? pass_strength.crack_times_display.offline_slow_hashing_1e4_per_second
          ? pass_strength.crack_times_display
              .offline_slow_hashing_1e4_per_second
          : 0
        : 0;
      return { ...newStoreObj };
    },
    setPasswordError: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, passwordError: data };
    },
    setConfirmPassowrdError: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, confirmPassowrdError: data };
    },
    setExportPersonalSecretLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        exportPersonalSecretLoading: data,
      };
    },
  },
  events: [
    "loadData",
    "updateProfile",
    "updatePassword",
    "exportSecret",
    "deleteAccount",
    "viewInfo",
    "cancelTwoFASuccessfully",
  ],
});
