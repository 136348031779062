import { state } from "../../store";
import { search } from "../../helpers";

export const [sitesActions, sitesSelector, sitesState] = state("sites", {
  initial: {
    ignoredSites: { items: [], loading: false },
    filterIgnoredSites: { items: [], loading: false },
    searchText: "",
    searchField: ["hostname"],
    removeSitesLoading: [],
  },
  on: {
    load: (_, data) => {
      const newStoreObj = { ..._ };
      newStoreObj.ignoredSites.items = data;
      newStoreObj.ignoredSites.loading = true;
      newStoreObj.filterIgnoredSites.items = data;
      newStoreObj.filterIgnoredSites.loading = true;
      let result = [...data];
      newStoreObj.removeSitesLoading = result.fill(false);
      return newStoreObj;
    },
    inputChangeHandler: (_, result) => {
      const newStoreObj = { ..._ };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      newStoreObj.filterIgnoredSites.items = [
        ...search(newStoreObj.ignoredSites.items)(newStoreObj.searchText)(
          newStoreObj.searchField
        ),
      ];
      return newStoreObj;
    },
    setRemoveSiteLoading: (_, data) => {
      const newStoreObj = { ..._ };
      newStoreObj.removeSitesLoading[data.index] = data.flag;
      newStoreObj.removeSitesLoading = [...newStoreObj.removeSitesLoading];
      return newStoreObj;
    },
  },
  events: ["loadData", "removeSite"],
});
