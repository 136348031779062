import { state } from "../../store";

export const [
  dashboardPageActions,
  dashboardPageSelector,
  dashboardPageState,
] = state("dashboardPage", {
  initial: {
    modalStatus: false,
  },
  on: {
    setModalStatus: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.modalStatus = !newStoreObj.modalStatus;
      return newStoreObj;
    },
  },
  events: [
    "acceptInvitation",
    "acceptOwnershipInvitationHandler",
    "dontShow",
    //"loadExtensionInstalledValue",
  ],
});
