import { state } from "../../store";

const initialUserInfo = {
  me: "",
  userInfo: {},
  checkingLogIn: false,
  csrfToken: "",
  userTokenInfo: "",
};

export const [authActions, authSelector, authState] = state("auth", {
  initial: {
    me: "",
    userInfo: {},
    checkingLogIn: false,
    csrfToken: "",
    gravatarUrl: "",
    userTokenInfo: "",
  },
  on: {
    setMe: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, me: data };
    },
    setUserInfo: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, userInfo: data };
    },
    setCheckingLogIn: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, checkingLogIn: data };
    },
    setCsrfToken: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, csrfToken: data };
    },
    resetUserInfo: (_) => {
      return { ...initialUserInfo };
    },
    setGravatarUrl: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, gravatarUrl: data };
    },
    setUserTokenInfo: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, userTokenInfo: data };
    },
    setAcceptNotificationLoading: (_, id) => {
      const newStoreObj = {
        ..._,
      };

      let invitations = newStoreObj.me.invitations;

      invitations.to_accept = invitations.to_accept.map((invi) => {
        if (invi.id === id) {
          invi.loading = true;
        }
        return invi;
      });

      return {
        ...newStoreObj,
        me: { ...newStoreObj.me, invitations: { ...invitations } },
      };
    },
    setAcceptOwnershipLoading: (_, id) => {
      const newStoreObj = {
        ..._,
      };

      let invitations = newStoreObj.me.ownershipInvitesWithOrgInfo;

      invitations = invitations.map((invi) => {
        if (invi.id === id) {
          invi.loading = true;
        }
        return invi;
      });
      return {
        ...newStoreObj,
        me: {
          ...newStoreObj.me,
          ownershipInvitesWithOrgInfo: [...invitations],
        },
      };
    },
  },
  events: ["setUserFromMe", "logoutUser"],
});
