import { call } from "redux-saga/effects";

export function* api(
  apiUri,
  method = "GET",
  body = null,
  formData = null,
  csrf = null
) {
  const headers = {};

  const uri = process.env.REACT_APP_API_ENDPOINT
    ? process.env.REACT_APP_API_ENDPOINT + apiUri
    : apiUri;
  if (csrf) {
    headers["X-CSRF-Token"] = csrf;
  }

  let response;

  if (body) {
    headers["Content-Type"] = "application/json";

    response = yield call(fetch, uri, {
      method: method,
      body: JSON.stringify(body),
      headers,
    });
  } else if (formData) {
    var formDataMethod = formData.get("_method");
    const channel = yield call(makeRequest, method, uri, formData, csrf);
    if (apiUri === "/users.json" && formDataMethod === "delete") {
      return null;
    } else {
      return JSON.parse(channel);
    }
  } else {
    response = yield call(fetch, uri, {
      method: method || "GET",
      headers,
      credentials: "include",
    });
  }
  if (apiUri !== "/users/sign_out") {
    const json = yield call(retrieveJSON, response);
    return json;
  }
  return null;
}

export function retrieveJSON(response) {
  return response.json();
}
function makeRequest(method, url, formData, csrf) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();

    xhr.open(method, url, true);
    if (csrf) {
      //Send the proper header information along with the request
      xhr.setRequestHeader("X-CSRF-Token", csrf);
    }
    xhr.withCredentials = true;

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        resolve(xhr.response);
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send(formData);
  });
}
