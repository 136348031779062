import { state } from "../../../store";
import { search, sorting } from "../../../helpers";

export const [secretTabActions, secretTabSelector, secretTabState] = state(
  "secretTab",
  {
    initial: {
      organizationSecrets: { items: [], loading: true },
      filterSecrets: { items: [], loading: true },
      sortSecrets: {
        login_page_hostname: { ascending: false },
        url: { ascending: false },
        login: { ascending: false },
      },
      searchField: ["login_page_hostname", "url", "login"],
      searchText: "",
      selectedSecret: { item: {}, loading: false },
    },
    on: {
      loadSecretTabData: (_, data) => {
        const newStoreObj = { ..._ };

        newStoreObj.organizationSecrets.loading = false;
        newStoreObj.organizationSecrets.items = data;
        newStoreObj.filterSecrets.loading = false;
        newStoreObj.filterSecrets.items = data;
        return newStoreObj;
      },
      sorting: (_, result) => {
        let newStoreObj = { ..._ };

        newStoreObj.sortSecrets[result.fieldName].ascending = !newStoreObj
          .sortSecrets[result.fieldName].ascending;
        result["sortType"] =
          newStoreObj.sortSecrets[result.fieldName].ascending;
        newStoreObj.filterSecrets.items = [
          ...sorting(newStoreObj.organizationSecrets.items, result),
        ];
        return { ...newStoreObj };
      },
      searching: (_, result) => {
        const newStoreObj = { ..._ };
        const { fieldName, value } = result;
        newStoreObj[fieldName] = value;
        newStoreObj.filterSecrets.items = [
          ...search(newStoreObj.organizationSecrets.items)(
            newStoreObj.searchText
          )(newStoreObj.searchField),
        ];
        return newStoreObj;
      },
      setSelectedSecret: (_, result) => {
        let newStoreObj = { ..._ };
        newStoreObj.selectedSecret.item = result;
        newStoreObj.selectedSecret.loading = true;
        return newStoreObj;
      },
    },
    events: ["loadData", "organizationSecretDetails"],
  }
);
