import { state } from "../../../store";
import zxcvbn from "zxcvbn";

export const [registerActions, registerSelector, registerState] = state(
  "registerPage",
  {
    initial: {
      firstName: "",
      lastName: "",
      emailId: "",
      password: null,
      confirmPassowrd: "",
      tos: false,
      error: "",
      passwordError: null,
      confirmPassowrdError: "",
      progressCount: 0,
      progreesColor: "",
      progressStatus: "",
      timeToCrack: "0 Seconds",
      suggestionList: [],
      registerProgress: "",
      modalStatus: false,
      plan: "",
      emailWarning: "",
      tokenLoading: false,
    },
    on: {
      inputChangeHandler: (_, result) => {
        const newStoreObj = {
          ..._,
        };
        const { fieldName, value } = result;

        if (fieldName === "tos") {
          newStoreObj[fieldName] = !newStoreObj[fieldName];
        } else {
          newStoreObj[fieldName] = value;
        }
        return { ...newStoreObj };
      },
      passwordChangeHandler: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.password = data;

        let pass_strength = data.length ? zxcvbn(data) : { score: 7 };
        switch (pass_strength.score) {
          case 0:
            newStoreObj.progressCount = 0;
            newStoreObj.progreesColor = "danger";
            newStoreObj.progressStatus = "WEAK (0/4)";
            break;
          case 1:
            newStoreObj.progressCount = 25;
            newStoreObj.progreesColor = "danger";
            newStoreObj.progressStatus = "POOR (1/4)";

            break;
          case 2:
            newStoreObj.progressCount = 50;
            newStoreObj.progreesColor = "danger";
            newStoreObj.progressStatus = "OK (2/4)";

            break;
          case 3:
            newStoreObj.progressCount = 75;
            newStoreObj.progreesColor = "success";
            newStoreObj.progressStatus = "GOOD (3/4)";

            break;
          case 4:
            newStoreObj.progressCount = 100;
            newStoreObj.progreesColor = "success";
            newStoreObj.progressStatus = "GREAT (4/4)";
            break;
          default:
            newStoreObj.progressCount = 0;
            newStoreObj.progreesColor = "";
            newStoreObj.progressStatus = "";
        }

        newStoreObj.suggestionList = pass_strength
          ? pass_strength.feedback
            ? pass_strength.feedback.suggestions
              ? pass_strength.feedback.suggestions
              : []
            : []
          : [];

        newStoreObj.timeToCrack = pass_strength.crack_times_display
          ? pass_strength.crack_times_display
              .offline_slow_hashing_1e4_per_second
            ? pass_strength.crack_times_display
                .offline_slow_hashing_1e4_per_second
            : 0
          : 0;

        return { ...newStoreObj };
      },
      resetProgressCount: (_) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.progressCount = 0;
        return newStoreObj;
      },
      setError: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, error: data };
      },
      setPasswordError: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, passwordError: data };
      },
      setEmailWarning: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, emailWarning: data };
      },
      setConfirmPassowrdError: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, confirmPassowrdError: data };
      },
      setRegisterProgress: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, registerProgress: data };
      },
      setModalStatus: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.modalStatus = data;
        return newStoreObj;
      },
      setTokenLoading: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.tokenLoading = data;
        return newStoreObj;
      },
    },
    events: [
      "registerUser",
      "completeRegistration",
      "setAccountType",
      "getMicrosoftRefreshToken",
      "getGoogleRefreshToken",
    ],
  }
);
