// @flow
import React, { Component, Suspense } from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { state } from "../store";

import "react-toastify/dist/ReactToastify.css";

import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  teamsTabSelector,
  teamsTabActions,
} from "../pages/company-page/teams-tab/store";
import { authSelector } from "../pages/auth/store";

import "react-toastify/dist/ReactToastify.css";

const Topbar = React.lazy(() => import("../components/Topbar"));
const Navbar = React.lazy(() => import("../components/Navbar"));

const loading = () => <div className="text-center"></div>;

export default withRouter(
  connect(
    state.select({
      currentCompany: teamsTabSelector((state) => state.currentCompany, {}),
      currentCompanyPlan: teamsTabSelector(
        (state) => state.currentCompanyPlan,
        false
      ),
      invitationSentModal: teamsTabSelector(
        (state) => state.invitationSentModal,
        false
      ),
      organization: teamsTabSelector(
        (state) => state.currentCompany.organization,
        {}
      ),
      orgs: authSelector((state) => state.me.orgs_by_id, []),
      me: authSelector((state) => state.me, []),
    }),
    state.actions(teamsTabActions, [
      "setCurrentCompanyPlan",
      "setInvitationSentModal",
    ])
  )(
    class HorizontalLayout extends Component {
      constructor(props) {
        super(props);

        this.openMenu = this.openMenu.bind(this);

        this.state = {
          isMenuOpened: false,
        };
      }

      openMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isMenuOpened: !this.state.isMenuOpened });
      };
      render() {
        const {
          currentCompany,
          location,
          organization,
          me,
          currentCompanyPlan,
          setCurrentCompanyPlan,
          setInvitationSentModal,
          invitationSentModal,
        } = this.props;
        if (
          currentCompany.organization &&
          me?.orgs_by_id[currentCompany?.organization?.id]
        ) {
          let currentCompanyPlanName =
            me?.orgs_by_id[currentCompany?.organization?.id]?.plan;
          if (
            currentCompanyPlanName === "GrowthPlanTrial" ||
            currentCompanyPlanName === "user"
          ) {
            setCurrentCompanyPlan(true);
          } else {
            setCurrentCompanyPlan(false);
          }
          let currentCompanySubscribeState =
            me.orgs_by_id[currentCompany.organization.id].subscribe_state;
          var expiredPlan =
            currentCompanySubscribeState === "block" ? true : false;
        }
        // get the child view which we would like to render
        const children = this.props.children || null;
        let isCompanyPage = this.props.location.pathname.includes("companies");

        let onBoardingProcess = false;
        if (
          location.pathname.includes("add-company") ||
          location.pathname.includes("billing-information") ||
          location.pathname.includes("step1") ||
          location.pathname.includes("step2")
        ) {
          onBoardingProcess = true;
        }

        return (
          <div className="app">
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div className="wrapper">
              <div className="content-page">
                <div className="content">
                  <Suspense fallback={loading()}>
                    <Topbar
                      {...this.props}
                      isMenuOpened={this.state.isMenuOpened}
                      openLeftMenuCallBack={this.openMenu}
                      navCssClasses="topnav-navbar"
                    />
                  </Suspense>

                  {isCompanyPage === true && onBoardingProcess === false && (
                    <Row className="mt-4">
                      <button
                        className="button-menu-mobile open-left disable-btn hamburger-style"
                        onClick={this.openMenu}
                      >
                        <i className="mdi mdi-menu font-20"></i>
                      </button>
                    </Row>
                  )}

                  {isCompanyPage === true && onBoardingProcess === false && (
                    <Suspense fallback={loading()}>
                      <Navbar
                        {...this.props}
                        expiredPlan={expiredPlan}
                        isMenuOpened={this.state.isMenuOpened}
                        openMenuCallback={this.openMenu}
                        currentCompanyPlan={currentCompanyPlan}
                      />
                    </Suspense>
                  )}
                  {onBoardingProcess === true && (
                    <Row className="justify-content-center mt-5 mb-0 ml-4 mr-4">
                      <Col lg={7} md={10} sm={11}>
                        <div className="horizontal-steps mt-3 pb-4">
                          <div className="horizontal-steps-content">
                            <div
                              className={
                                location.pathname.includes("add-company")
                                  ? "step-item current"
                                  : "step-item"
                              }
                            >
                              <span>Create Company</span>
                            </div>
                            {/* <div
                              className={
                                location.pathname.includes("select-plan")
                                  ? "step-item current"
                                  : "step-item"
                              }
                            >
                              <span>Choose Plan</span>
                            </div> */}
                            {/* <div
                              className={
                                location.pathname.includes(
                                  "billing-information"
                                )
                                  ? "step-item current"
                                  : "step-item"
                              }
                            >
                              <span>Setup Billing Information</span>
                            </div> */}
                            <div
                              className={
                                location.pathname.includes("step1")
                                  ? "step-item current"
                                  : "step-item"
                              }
                            >
                              <span>Add Secrets</span>
                            </div>
                            <div
                              className={
                                location.pathname.includes("step2")
                                  ? "step-item current"
                                  : "step-item"
                              }
                            >
                              <span>Invite Users</span>
                            </div>
                          </div>
                          <div
                            className="process-line"
                            style={
                              location.pathname.includes("add-company")
                                ? { width: "0%" }
                                : location.pathname.includes("step1")
                                ? { width: "58%" }
                                : location.pathname.includes("step2")
                                ? { width: "100%" }
                                : null
                            }
                          ></div>
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Container fluid>
                    <Suspense fallback={loading()}>{children}</Suspense>
                  </Container>
                </div>
              </div>
            </div>

            {/* Educate User after sending and invitation */}
            <Modal
              isOpen={invitationSentModal}
              toggle={() => setInvitationSentModal(!invitationSentModal)}
              className={this.state.className}
            >
              <ModalHeader
                className="border-bottom-0 pb-0"
                toggle={() => setInvitationSentModal(false)}
              ></ModalHeader>
              <ModalBody className="text-center pt-0">
                <i className="uil-check-circle text-success invitation-sent-icon-size"></i>
                <h3>Invitation sent successfully.</h3> <br />
                <p>
                  <b>NOTE: </b>
                  You can always send an invitation link (clicking the vertical
                  three dots to the right of their email).
                </p>
              </ModalBody>
            </Modal>
          </div>
        );
      }
    }
  )
);
