import { state } from "../../store";
const secretFilter = (text) => (item) => {
  if (item.page_title && item.page_title.toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item.login && item.login.toLowerCase().indexOf(text) !== -1) {
    return item;
  }
};
const initialState = {
  progressBar: { toggle: false, width: 0 },
  passwordDiabledEnable: false,
  disabledToggle: true,
  userAccount: { item: {}, loading: false },
  copyToClipboard: { value: "", copied: false },
  existingSecret: { items: [], loading: false },
  personalSecret: { items: [], loadind: false },
  filteredExistingSecret: { items: [], loading: false },
  filteredPersonalSecret: { items: [], loadind: false },
  selectedExistingSecret: [],
  addSecretButtonLoading: false,
  updateButtonLoading: false,
  deleteButtonLoading: false,
  // for secretAdd.js
  domains: { items: [], loading: false },
  selectApplicationError: false,
  domainId: "",
  page_title: "",
  url: "",
  login: "",
  cipher: "",
  notes: "",
  from: "",
  importSecretModalView: false,
  selectedTeamsOptions: [],
  useTeamsColumnInCsv: false,
  importSecretFile: "",
  importSecretError: [],
  importSecretLoading: false,
  companyTeams: [],
  company: {},
  searchText: "",
};
export const [secretActions, secretSelector, secretState] = state("secrets", {
  initial: initialState,
  on: {
    loadSecret: (_, result) => {
      const newStoreObj = { ..._ };
      newStoreObj.userAccount.item = result;
      newStoreObj.userAccount.loading = true;
      newStoreObj.progressBar = { toggle: false, width: 0 };
      newStoreObj.passwordDiabledEnable = false;
      newStoreObj.disabledToggle = true;
      newStoreObj.copyToClipboard.value = result.cipher;
      return newStoreObj;
    },
    loadAddSecret: (_, result) => {
      const newStoreObj = { ..._ };
      newStoreObj.domains.items = result.domains;
      newStoreObj.from = result.from;
      newStoreObj.domains.loading = true;
      newStoreObj.existingSecret.items = result.existingSecret;
      newStoreObj.personalSecret.items = result.personalSecret;
      newStoreObj.filteredExistingSecret.items = result.existingSecret;
      newStoreObj.filteredPersonalSecret.items = result.personalSecret;
      newStoreObj.addSecretButtonLoading = false;
      newStoreObj.selectApplicationError = false;
      newStoreObj.domainId = "";
      return { ...newStoreObj };
    },
    setTeamsData: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      let companyTeams = data.teams;
      let organization = data.organization;
      return {
        ...newStoreObj,
        companyTeams: companyTeams,
        company: organization,
      };
    },
    bulkSecretsInputChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };

      const { fieldName, value } = result;

      if (fieldName === "useTeamsColumnInCsv") {
        newStoreObj[fieldName] = !newStoreObj[fieldName];
        newStoreObj.selectedTeamsOptions = [];
      } else if (fieldName === "selectedTeamsOptions") {
        newStoreObj.useTeamsColumnInCsv = false;
        newStoreObj[fieldName] = value;
      } else {
        newStoreObj[fieldName] = value;
      }
      newStoreObj.importSecretError = [];
      return { ...newStoreObj };
    },
    inputChangeHandler: (_, result) => {
      const newStoreObj = { ..._ };
      const { fieldName, value } = result;
      newStoreObj.userAccount.item[fieldName] = value;
      return newStoreObj;
    },
    addInputChangeHandler: (_, result) => {
      const newStoreObj = { ..._ };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      newStoreObj.selectApplicationError = false;
      return newStoreObj;
    },
    enableInputField: (_) => {
      let newStoreObj = { ..._ };
      newStoreObj.disabledToggle = !newStoreObj.disabledToggle;
      return newStoreObj;
    },

    passwordDisplay: (_) => {
      let newStoreObj = { ..._ };
      newStoreObj.progressBar.toggle = !newStoreObj.progressBar.toggle;
      return { ...newStoreObj };
    },
    validateSelectAnApplication: (_) => {
      const newStoreObj = { ..._ };
      newStoreObj.selectApplicationError = true;
      return newStoreObj;
    },
    updateProgress: (_) => {
      let newStoreObj = { ..._ };
      if (newStoreObj.progressBar.width > 100) {
        newStoreObj.progressBar.width = 0;
      } else {
        newStoreObj.progressBar.width += 10;
      }
      return { ...newStoreObj };
    },
    setCopyToClipboard: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.copyToClipboard.copied = data.copied;
      return { ...newStoreObj };
    },
    setSelectedExistingSecret: (_, data) => {
      let newStoreObj = { ..._ };
      let alreadyExist = newStoreObj.selectedExistingSecret.some(
        (secret) => secret.id === data.id
      );
      if (alreadyExist === false) {
        newStoreObj.selectedExistingSecret = [
          ...newStoreObj.selectedExistingSecret,
          data,
        ];
      } else {
        newStoreObj.selectedExistingSecret =
          newStoreObj.selectedExistingSecret.filter(
            (secret) => secret.id != data.id
          );
      }
      return { ...newStoreObj };
    },
    resetSelectedExistingSecret: (_) => {
      let newStoreObj = { ..._ };
      newStoreObj.selectedExistingSecret = [];
      return { ...newStoreObj };
    },

    setAddSecretButtonLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        addSecretButtonLoading: data,
      };
    },
    setUpdateButtonLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        updateButtonLoading: data,
      };
    },
    setDeleteButtonLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        deleteButtonLoading: data,
      };
    },
    resetImportSecretModal: (_) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        selectedTeamsOptions: [],
        useTeamsColumnInCsv: false,
        importSecretFile: "",
        importSecretError: [],
        importSecretLoading: false,
      };
    },
    setImportSecretModal: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        importSecretModalView: data,
      };
    },
    setImportSecretError: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        importSecretError: [...data],
      };
    },
    setImportSecretLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        importSecretLoading: data,
      };
    },
    searchSecret: (_, text) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.searchText = text;
      let existingSecrets = JSON.parse(
        JSON.stringify(newStoreObj.existingSecret)
      );
      let personalSecret = JSON.parse(
        JSON.stringify(newStoreObj.personalSecret)
      );
      let filteredExistingValues = existingSecrets;
      let filteredPersonalValues = personalSecret;
      if (text !== "") {
        filteredExistingValues.items = existingSecrets.items.filter(
          secretFilter(newStoreObj.searchText.trim().toLowerCase())
        );
        filteredPersonalValues.items = personalSecret.items.filter(
          secretFilter(newStoreObj.searchText.trim().toLowerCase())
        );
      }
      return {
        ...newStoreObj,
        filteredExistingSecret: filteredExistingValues,
        filteredPersonalSecret: filteredPersonalValues,
      };
    },
  },
  events: [
    "loadData",
    "personalSecretById",
    "deletePersonalSecret",
    "updatePersonalSecret",
  ],
});
