import { putResolve, call, spawn, take, select, all } from "redux-saga/effects";
import { personalsecretActions, personalsecretSelector } from "./store";
import { api } from "../../helpers/api";
import { sym_key } from "../../helpers/apiUtils";

import { toast } from "react-toastify";
import { decrypt } from "../../helpers/ckService/EncryptionHelper";
import sentryErrorCatch from "../../helpers/sentryUtils";

//Display all personal secret
function* personalSecretPageSaga() {
  while (true) {
    try {
      yield take(personalsecretActions.loadData().type);
      const { searchText } = yield select(
        personalsecretSelector((state) => state)
      );

      let result = [];

      let personalSecrets = yield call(api, `/user_accounts.json`);
      for (let i = 0; i < personalSecrets.length; i++) {
        let secret = personalSecrets[i];
        try {
          result.push(decrypt(secret, sym_key()));
        } catch (err) {
          yield call(sentryErrorCatch, err, "#206")
          console.log("error in personalSecretPageSaga:corrupted secrets");
        }
      }

      yield putResolve(personalsecretActions.loadPageSecretData(result));

      let payload = {
        fieldName: "searchText",
        value: searchText,
      };
      yield putResolve(personalsecretActions.searching(payload));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#175")
      toast.error("Something went wrong #175", {
        className: "toast-danger",
      });
      console.log("error in personalSecretPageSaga", err);
    }
  }
}

function* detailsPersonalSecretSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        personalsecretActions.detailsPersonalSecret().type
      );

      const { personalSecrets } = yield select(
        personalsecretSelector((state) => state)
      );
      let secret = personalSecrets.items.filter(
        (secret) => secret.id === data.secretId
      )[0];

      yield putResolve(personalsecretActions.setSelectedSecret(secret));

      if (secret.class_name === "UserAccount") {
        data.history.push(`/user-account/${secret.id}/edit`);
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#176")
      toast.error("Something went wrong #176", {
        className: "toast-danger",
      });
    }
  }
}

export default function* personalSecretPageRootSaga() {
  yield spawn(personalSecretPageSaga);
  yield spawn(detailsPersonalSecretSaga);
}
