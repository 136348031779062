import { state } from "../../../store";
const secretFilter = (text) => (item) => {
  if (item.page_title && item.page_title.toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item.login && item.login.toLowerCase().indexOf(text) !== -1) {
    return item;
  }
};
const initialState = {
  currentCompany: {},
  originalCurrentCompany: {},
  userRole: "",
  teamName: "",
  activeTab: "",
  addTeamError: "",
  loading: true,
  secretLoading: true,
  teamsLoading: true,
  selectedInvites: [],
  selectedExistingInvites: [],
  selectedBulkInvites: [],
  inviteUserError: "",
  showBillingModal: false,
  addEditTeamLoading: false,
  inviteUserLoading: false,
  selectAllState: false,
  errors: [],
  editSecretLoading: false,
  searchText: "",
  modalState: false,
  multi_level_admin_or_30_days: false,
  growth_plan_14_days: false,
  currentCompanyPlan: false,
  billingModalToggle: false,
  invitationSentModal: false,
  selectedUserFromContactList: [],
  filteredUserFromContactList: [],
  contactSearchStr: "",
  contactsNotFound: false,
  contactLoading: false,
  oauthAccountConnected: false,
  tokenLoading: false,
};
export const [teamsTabActions, teamsTabSelector, teamsTabState] = state(
  "teamsTab",
  {
    initial: { ...initialState },
    on: {
      setLoading: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          loading: data,
        };
      },
      resetTeamsTabState: (_) => {
        return initialState;
      },
      setSecretLoading: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          secretLoading: data,
        };
      },
      setTeamsLoading: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          teamsLoading: data,
        };
      },
      setCurrentCompanyPlan: (_, value) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          currentCompanyPlan: value,
        };
      },
      setModalState: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          modalState: data,
        };
      },
      setBillingModalToggle: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          billingModalToggle: data,
        };
      },
      setBulkInviteCheckState: (_, email) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.selectedBulkInvites = newStoreObj.selectedBulkInvites.map(
          (user) => {
            if (user.email === email) {
              user.selected = !user.selected;
            }
            return user;
          }
        );
        return {
          ...newStoreObj,
        };
      },
      setAddEditTeamLoading: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          addEditTeamLoading: data,
        };
      },
      setShowBillingModal: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          showBillingModal: data,
        };
      },
      setSubscribeState: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        let currentCompany = {};
        currentCompany.subscribe_state = data;

        return {
          ...newStoreObj,
          currentCompany,
        };
      },
      setCurrentCompany: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.multi_level_admin_or_30_days =
          data.multi_level_admin_or_30_days;
        newStoreObj.growth_plan_14_days = data.growth_plan_14_days;
        newStoreObj.searchText = "";
        return {
          ...newStoreObj,
          currentCompany: data,
          originalCurrentCompany: data,
        };
      },
      resetCompanySecrets: (_) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, secrets: [] },
          originalCurrentCompany: {
            ...newStoreObj.originalCurrentCompany,
            secrets: [],
            searchText: "",
          },
        };
      },

      resetAddTeam: (_) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          teamName: "",
          addTeamError: "",
        };
      },
      resetSelectedInvites: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          selectedInvites: [],
          inviteUserError: "",
        };
      },
      resetSelectedBulkInvites: (_) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          selectedBulkInvites: [],
          modalState: false,
        };
      },
      setActiveTab: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, activeTab: data };
      },
      setAddTeamError: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, addTeamError: data };
      },
      setInviteUserError: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, inviteUserError: data };
      },
      resetSelectedExistingInvites: (_) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, selectedExistingInvites: [] };
      },
      setInvalidEmailErrors: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, errors: data };
      },
      inputChangeHandler: (_, result) => {
        const newStoreObj = {
          ..._,
        };

        const { fieldName, value } = result;
        newStoreObj[fieldName] = value;
        newStoreObj.errors = [];
        return { ...newStoreObj };
      },
      setUserRole: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return { ...newStoreObj, userRole: data };
      },
      deleteInvite: (_, id) => {
        const newStoreObj = {
          ..._,
        };
        let org_invites = newStoreObj.currentCompany["org_invites"];

        org_invites = org_invites.filter((invite) => invite.id !== id);

        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, org_invites },
        };
      },
      removeSecret: (_, id) => {
        const newStoreObj = {
          ..._,
        };
        let secrets = newStoreObj.currentCompany["secrets"];

        secrets = secrets.filter((secret) => secret.account_id !== id);

        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, secrets },
          originalCurrentCompany: { ...newStoreObj.currentCompany, secrets },
        };
      },
      removeUser: (_, id) => {
        const newStoreObj = {
          ..._,
        };
        let users = newStoreObj.currentCompany["users"];

        users = users.filter((user) => user.id !== id);
        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, users },
        };
      },
      removeManager: (_, id) => {
        const newStoreObj = {
          ..._,
        };
        let managers = newStoreObj.currentCompany["managers"];

        managers = managers.filter((user) => user.id !== id);

        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, managers },
        };
      },
      removeTeam: (_, id) => {
        const newStoreObj = {
          ..._,
        };
        let teams = newStoreObj.currentCompany["teams"];

        teams = teams.filter((team) => team.id !== id);
        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, teams },
        };
      },
      newTeam: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        let teams = newStoreObj.currentCompany["teams"];

        teams = [...teams, data];

        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, teams },
        };
      },
      newInvitations: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        let org_invites = newStoreObj.currentCompany["org_invites"];

        org_invites = [...org_invites, data];

        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, org_invites },
        };
      },
      changedPasswordVisibility: (_, data) => {
        const newStoreObj = {
          ..._,
        };

        const { id, flag } = data;
        let secrets = newStoreObj.currentCompany["secrets"];

        secrets = secrets.map((secret) => {
          if (secret.id === id) {
            secret.password_visible = flag;
          }
          return secret;
        });

        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, secrets },
        };
      },
      ChangedTeam: (_, data) => {
        const newStoreObj = {
          ..._,
        };

        let teams = newStoreObj.currentCompany["teams"];

        teams = teams.map((team) => {
          if (team.id === data.id) {
            return data;
          }
          return team;
        });

        return {
          ...newStoreObj,
          currentCompany: { ...newStoreObj.currentCompany, teams },
        };
      },
      userPromotedToManager: (_, data) => {
        const newStoreObj = {
          ..._,
        };

        const { user, organizationGroup } = data;
        let organization_group_users =
          newStoreObj.currentCompany.organization_group_users;
        organization_group_users = organization_group_users.map((group) => {
          if (group.id === organizationGroup.id) {
            return organizationGroup;
          } else {
            return group;
          }
        });

        return {
          ...newStoreObj,
          currentCompany: {
            ...newStoreObj.currentCompany,
            organization_group_users,
            managers: [...newStoreObj.currentCompany.managers, user],
          },
        };
      },
      userDemotedToNormalUser: (_, data) => {
        const newStoreObj = {
          ..._,
        };

        const { user, organizationGroup } = data;
        let organization_group_users =
          newStoreObj.currentCompany.organization_group_users;
        organization_group_users = organization_group_users.map((group) => {
          if (group.id === organizationGroup.id) {
            return organizationGroup;
          } else {
            return group;
          }
        });

        let managers = newStoreObj.currentCompany.managers;

        managers = managers.filter((manager) => manager.id !== user.id);

        return {
          ...newStoreObj,
          currentCompany: {
            ...newStoreObj.currentCompany,
            organization_group_users,
            managers,
          },
        };
      },
      searchSecret: (_, text) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.searchText = text;
        let secrets = newStoreObj.originalCurrentCompany["secrets"];
        let filteredValues = secrets;
        if (newStoreObj.searchText.length) {
          filteredValues = secrets.filter(
            secretFilter(newStoreObj.searchText.trim().toLowerCase())
          );
        }

        return {
          ...newStoreObj,
          currentCompany: {
            ...newStoreObj.currentCompany,
            secrets: filteredValues,
          },
        };
      },
      setInviteUserLoading: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          inviteUserLoading: data,
        };
      },
      setInvitationSentModal: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          invitationSentModal: data,
        };
      },
      setSelectedUserFromContactList: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          selectedUserFromContactList: data,
        };
      },
      searchInContactList: (_, searchStr) => {
        const newStoreObj = {
          ..._,
        };
        let filteredUser = newStoreObj.selectedUserFromContactList.filter(
          (user) => {
            return (
              user.email.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
            );
          }
        );

        return {
          ...newStoreObj,
          filteredUserFromContactList: filteredUser,
          contactSearchStr: searchStr,
        };
      },
      setContactsNotFound: (_, value) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          contactsNotFound: value,
        };
      },
      setContactLoading: (_, value) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          contactLoading: value,
        };
      },
      setOauthAccountConnected: (_, value) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          oauthAccountConnected: value,
        };
      },
      setTokenLoading: (_, value) => {
        const newStoreObj = {
          ..._,
        };
        return {
          ...newStoreObj,
          tokenLoading: value,
        };
      },
      toggleViewSecret: (_, secretId) => {
        let newStoreObj = {
          ..._,
        };
        let currentCompany = newStoreObj.currentCompany;

        const secrets = currentCompany.secrets.map((secret) => {
          if (secret.id === secretId) {
            secret.visible = secret.visible ? !secret.visible : true;
          } else {
            secret.visible = false;
          }
          return secret;
        });

        currentCompany.secrets = secrets;
        newStoreObj.currentCompany = currentCompany;
        return {
          ...newStoreObj,
          currentCompany: { ...currentCompany },
        };
      },
      updatingSecret: (_, data) => {
        const { secretId, flag, secretToUpdate } = data;
        let newStoreObj = {
          ..._,
        };
        let currentCompany = newStoreObj.currentCompany;

        let secrets = currentCompany.secrets.map((secret) => {
          if (secret.id === secretId) {
            secret.updating = flag; //secret.updating ? !secret.updating : true;
            if (secretToUpdate) {
              return secretToUpdate;
            }
          } else {
            secret.updating = false;
          }
          return secret;
        });
        currentCompany.secrets = secrets;
        newStoreObj.currentCompany = currentCompany;
        return {
          ...newStoreObj,
          currentCompany: { ...currentCompany },
        };
      },
      deleteSecret: (_, data) => {
        const { secretId } = data;
        let newStoreObj = {
          ..._,
        };
        let currentCompany = newStoreObj.currentCompany;

        let secrets = currentCompany.secrets.filter((secret) => {
          if (secret.id !== secretId) {
            return secret;
          }
        });
        currentCompany.secrets = secrets;
        newStoreObj.currentCompany = currentCompany;
        return {
          ...newStoreObj,
          currentCompany: { ...currentCompany },
        };
      },
      addSecret: (_, data) => {
        let newStoreObj = {
          ..._,
        };
        let currentCompany = newStoreObj.currentCompany;

        let secrets = [...currentCompany.secrets, data];
        currentCompany.secrets = secrets;
        newStoreObj.currentCompany = currentCompany;
        return {
          ...newStoreObj,
          currentCompany: { ...currentCompany },
          originalCurrentCompany: { ...currentCompany },
        };
      },
    },
    events: [
      "companyLoadData",
      "removeInvitation",
      "unlinkSecret",
      "setPasswordVisibility",
      "deleteUser",
      "promoteToManager",
      "demoteToNormalUser",
      "deleteTeam",
      "updateTeam",
      "addTeam",
      "inviteUsers",
      "fetchContactList",
      "getAllContactsFromGoogleAndSave",
      "getMicrosoftRefreshToken",
    ],
  }
);
