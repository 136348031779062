import { state } from "../../../store";
export const [
  settingsTabActions,
  settingsTabSelector,
  settingsTabState,
] = state("SettingTab", {
  initial: {
    company: {},
    organizationName: "",
    industryType: "",
    updateCompanyLoading: false,
    transferOwnershipLoading: false,
    cancelTransferOwnershipLoading: false,
    transferOwnershipModal: false,
    companyUsers: [],
    companyTeams: [],
    transferOwnershipUserId: "",
    transferedOwnership: "",
    selectedTeamsOptions: [],
    useTeamsColumnInCsv: false,
    importSecretFile: "",
    importSecretError: [],
    importSecretLoading: false,
    importSecretModal: false,
    loading: false,
    deleteCompanyLoading: false,
    exportSecretLoading: false,
  },
  on: {
    inputChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };

      const { fieldName, value } = result;

      if (fieldName === "useTeamsColumnInCsv") {
        newStoreObj[fieldName] = !newStoreObj[fieldName];
        newStoreObj.selectedTeamsOptions = [];
      } else if (fieldName === "selectedTeamsOptions") {
        newStoreObj.useTeamsColumnInCsv = false;
        newStoreObj[fieldName] = value;
      } else {
        newStoreObj[fieldName] = value;
      }
      newStoreObj.importSecretError = [];
      return { ...newStoreObj };
    },
    setData: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      let organization = data.organization;
      let orgUsers = data.orgUsers;

      let transferedOwnership = data.current_transfer_invitation;
      if (transferedOwnership && transferedOwnership.status) {
        let invitedUser = orgUsers.filter(
          (user) => user.id === transferedOwnership.invitee_id
        )[0];
        let value = `(${invitedUser.firstname} ${invitedUser.lastname}) ${invitedUser.email}`;
        invitedUser.value = value;
        transferedOwnership.invitedUser = invitedUser;
      }
      let companyTeams = data.teams;

      return {
        ...newStoreObj,
        company: organization,
        companyUsers: orgUsers,
        transferedOwnership,
        companyTeams,
      };
    },
    setTransferOwnershipModal: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        transferOwnershipModal: data,
      };
    },
    setCompanyLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        updateCompanyLoading: data,
      };
    },
    resetImportSecretModal: (_) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        selectedTeamsOptions: [],
        useTeamsColumnInCsv: false,
        importSecretFile: "",
        importSecretError: [],
        importSecretLoading: false,
      };
    },
    setImportSecretLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        importSecretLoading: data,
      };
    },
    setImportSecretError: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        importSecretError: [...data],
      };
    },
    setCurrentCompany: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        company: data,
      };
    },
    setImportSecretModal: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        importSecretModal: data,
      };
    },
    setTransferOwnershipLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        transferOwnershipLoading: data,
      };
    },
    setCancelTransferOwnershipLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      return {
        ...newStoreObj,
        cancelTransferOwnershipLoading: data,
      };
    },
    setLoading: (_, data) => {
      const newStoreObj = { ..._ };
      return { ...newStoreObj, loading: data };
    },
    setDeleteCompanyLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        deleteCompanyLoading: data,
      };
    },
    setExportSecretLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        exportSecretLoading: data,
      };
    },
  },
  events: [
    "loadData",
    "updateOrganization",
    "exportSecrets",
    "transferOwnership",
    "cancelTransferOwnership",
    "deleteCompany",
    "importSecrets",
  ],
});
