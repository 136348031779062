import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { ResetPasswordActions, ResetPasswordSelector } from "./store";
import { api } from "../../../../helpers/api";
import {
  generateNewUserInfoObject,
  encrypt,
} from "../../../../helpers/apiUtils";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../../helpers/sentryUtils";

function* loadUserInfoSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        ResetPasswordActions.loadUserInfo().type
      );

      const result = yield call(
        api,
        `/users/password/edit?reset_password_token=${data.reset_password_token}`
      );
      yield putResolve(ResetPasswordActions.setLoading(false));

      if (result.name) {
        const diff =
          Math.abs(
            new Date(result.reset_password_sent_at).getTime() -
              new Date().getTime()
          ) / 3600000;
        const canReset = diff <= 1 ? true : false;
        if (canReset) {
          yield putResolve(ResetPasswordActions.setUserInfo(result));
        } else {
          window.location.href = "/reset-password-link-expired";
        }
      }
      if (result.error) {
        //show in notification
        window.location.href = "/reset-password-link-expired";
        toast.error("Invalid Token Error", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#109");
      console.log("error in loadUserInfoSaga ", err);
      toast.error("Something Went Wrong #109", {
        className: "toast-danger",
      });
    }
  }
}

//backend passwords_controller.rb > ln: 8 block update with
// def edit
//   @user = User.find_by_reset_password_token(params[:reset_password_token])

//   if @user
//     render json: { name: @user.name }
//   else
//     render json: { error: "invalid token"}, status: 500
//   end
//   # begin
//   #   @name = User.find_by_reset_password_token(params[:reset_password_token]).name
//   # rescue
//   #   redirect_to controller: :users, action: :password_reset_form, :expired => true
//   # end
//   # super
// end

async function getKeys(UIObj) {
  let keyss = null;
  var counter = 0;

  if (window.crypto && window.crypto.subtle) {
    keyss = await window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        // Consider using a 4096-bit key for systems that require long-term security
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256",
      },
      true,
      ["encrypt", "decrypt"]
    );
  } else {
    keyss = await window.myRSAManager.generateKey(
      2048,
      "3",
      UIObj,
      function (c) {
        // status messages
        counter++;
        if (counter < 8 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Starting the registration process...";
        } else if (counter < 16 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "This may take a couple of minutes...";
        } else if (counter < 24 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Firing up encryption engine...";
        } else if (counter < 32 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Verifying secure connection...";
        } else if (counter < 40 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Connection secured and ready...";
        } else if (counter < 48 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Generating initial encryption keys....";
        } else if (counter < 56 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Encryption keys successfully created...";
        } else if (counter < 64 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Securing user sensitive information...";
        } else if (counter < 72 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Setting up secure environment...";
        } else if (counter < 80 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Validating generated keys...";
        } else if (counter < 88 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "All keys have been created...";
        } else if (counter < 96 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Checking for any vulnerabilities...";
        } else if (counter < 104 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Preparing your confirmation email...";
        } else if (counter < 112 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Wrapping up encryption process...";
        } else if (counter < 120 * 1000) {
          document.getElementById("ResetPasswordProgress").innerHTML =
            "Tieing up loose ends...";
        }
      },
      function (RSAObj, UIObj) {}
    );
  }
  return { RSAObj: keyss, UIObj: UIObj };
}

function* resetPasswordPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        ResetPasswordActions.resetPasswordUser().type
      );
      // 1. get new salts, private key, passwordHash, symKey
      var args = {
        first_name: "",
        last_name: "",
        password: data.password,
        email: "",
      };

      // 1. get new salts, passwordHash, symKey
      let UIObj = yield call(generateNewUserInfoObject, args);

      //getting RSA KEYS
      var keyss = yield call(getKeys, UIObj);
      if (keyss) {
        const { RSAObj, UIObj } = keyss;
        // Extract the public/private RSA keys from newly generated RSAObj
        if (window.crypto && window.crypto.subtle) {
          var rsaPubKey = JSON.stringify(
            yield window.crypto.subtle.exportKey("jwk", RSAObj.publicKey)
          );
          var rsaPrivKey = JSON.stringify(
            yield window.crypto.subtle.exportKey("jwk", RSAObj.privateKey)
          );
          UIObj.asymmetricKeyAlgo = "RSA-OAEP";
        } else {
          var rsaPubKey = window.base64x_encode(RSAObj.publicKeyBytes());
          var rsaPrivKey = window.base64x_encode(RSAObj.privateKeyBytes());
          UIObj.asymmetricKeyAlgo = null;
        }

        // Add the RSA public key and the encrypted RSA private key to UIObj
        UIObj.rsaPublicKey = rsaPubKey;

        // Encrypt the private RSA key using AES with the new user's symmetric AES key
        UIObj.rsaPrivateKey = encrypt(rsaPrivKey, UIObj.symKey);

        // creating form data
        const form_data = new FormData();

        form_data.append("authenticity_token", data.authenticity_token);
        form_data.append(
          "user[reset_password_token]",
          data.reset_password_token
        );
        form_data.append("user[password]", UIObj.passwordHash);
        form_data.append("user[password_confirmation]", UIObj.passwordHash);
        form_data.append("user[public_key]", UIObj.rsaPublicKey);
        form_data.append("user[private_key_encrypted]", UIObj.rsaPrivateKey);
        form_data.append("user[asymmetric_key_algo]", UIObj.asymmetricKeyAlgo);
        form_data.append("user[salt1]", UIObj.salt1);
        form_data.append("user[salt2]", UIObj.salt2);

        // saving to data to DB
        const result = yield call(
          api,
          `/users/password.json`,
          "PUT",
          null,
          form_data
        );
        //succes response from API
        // email: "shubham.holkar@vshsolutions.com"
        // notice: "Account has been reset, please login with new password"
        // success: true

        if (result.success && result.success === true && result.email) {
          data.history.push("/login?reset=true&email=" + result.email);
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#110");
      console.log(err);
      toast.error("Something Went Wrong #110", {
        className: "toast-danger",
      });
    }
  }
}

export default function* ResetPasswordPageRootSaga() {
  yield spawn(resetPasswordPageSaga);
  yield spawn(loadUserInfoSaga);
}
