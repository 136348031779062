import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { twoFactorAuthActions } from "./store";
import { getLatestUserData } from "../../../helpers/apiUtils";
import { api } from "../../../helpers/api";
import { toast } from "react-toastify";
import { authSelector, authActions } from "../../auth/store";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* enableOtpSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        twoFactorAuthActions.enableOtp().type
      );

      let { csrfToken } = yield select(authSelector((state) => state));
      let apiData = yield call(
        api,
        `/me/enable_otp`,
        "POST",
        null,
        null,
        csrfToken
      );
      const user_me_data = yield call(getLatestUserData);
      yield putResolve(authActions.setMe(user_me_data));
      yield putResolve(twoFactorAuthActions.load(user_me_data.user));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#194")
      toast.error("Something Went Wrong #194", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}
function* checkingOtpSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        twoFactorAuthActions.checkOtp().type
      );

      let { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();
      form_data.append("code", parseInt(data.code));

      let apiData = yield call(
        api,
        `/me/checking_otp`,
        "POST",
        null,
        form_data,
        csrfToken
      );

      if (apiData.status === true) {
        const user_me_data = yield call(getLatestUserData);

        yield putResolve(authActions.setMe(user_me_data));

        yield putResolve(twoFactorAuthActions.load(user_me_data.user));

        toast.success("OTP Verified", {
          className: "toast-success",
        });
      } else {
        toast.error("Wrong OTP", {
          className: "toast-danger",
        });
      }
      yield putResolve(twoFactorAuthActions.setModalStatus());
    } catch (err) {
      yield call(sentryErrorCatch, err, "#195")
      toast.error("Something Went Wrong #195", {
        className: "toast-danger",
      });
      console.log(err);
    }
  }
}
function* disableOtpSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        twoFactorAuthActions.disableOtp().type
      );

      let { csrfToken } = yield select(authSelector((state) => state));
      let result = yield call(
        api,
        `/me/initiate_cancellation_2fa`,
        "POST",
        null,
        null,
        csrfToken
      );

      if (result.message === "Rest Two Factor Authentication") {
        let me = yield call(getLatestUserData);
        yield putResolve(authActions.setMe(me));
        yield putResolve(twoFactorAuthActions.load(me.user));

        // toaster
        toast.success(
          "We sent you an email. Please follow the instructions to disable 2FA",
          {
            className: "toast-success",
          }
        );
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#196")
      toast.error("Something Went Wrong #196", {
        className: "toast-danger",
      });

      console.log(err);
    }
  }
}
export default function* twoFactorAuthRootSaga() {
  yield spawn(enableOtpSaga);
  yield spawn(checkingOtpSaga);
  yield spawn(disableOtpSaga);
}
