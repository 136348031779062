import { state } from "../../../store";
export const [loginActions, loginSelector, loginState] = state("loginPage", {
  initial: {
    emailId: "",
    password: "",
    error: "",
    twoFAUser: false,
    faCode: "",
    faCodeError: "",
    lostTwoFADeviceStatus: "",
    loginButtonLoading: false,
    resettingPassword: false,
  },
  on: {
    inputChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      return { ...newStoreObj };
    },
    setError: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, error: data };
    },
    setResettingPassword: (_, value) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, resettingPassword: value };
    },
    setTwoFAUser: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, twoFAUser: data };
    },

    setfaCodeError: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, faCodeError: data };
    },
    setLostTwoFADeviceStatus: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, lostTwoFADeviceStatus: data };
    },
    setLoginButtonLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        loginButtonLoading: data,
      };
    },
  },
  events: [
    "loginUser",
    "faCheckingOtp",
    "lostTwoFADevice",
    "setIntentionToUseAs",
  ],
});
