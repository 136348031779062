import { call } from "redux-saga/effects";
import { getLatestUserData } from "../apiUtils";

export function* getUserAccount(accountId, user_data) {
  var accounts = user_data.user.accounts;
  var account = undefined;

  for (let i = 0; i < accounts.length; i++) {
    let acc = accounts[i];
    if (acc.id === accountId) {
      return acc;
    }
  }
  return account;
}

export function* getGroupAccount(
  organizationId,
  groupId,
  accountId,
  user_data
) {
  var groupAccounts =
    user_data.orgs_by_id[organizationId].groups_by_id[groupId].accounts;
  var groupAccount = undefined;

  for (let i = 0; i < groupAccounts.length; i++) {
    let grpAccount = groupAccounts[i];
    if (grpAccount.id === accountId) {
      return grpAccount;
    }
  }
  return groupAccount;
}

export function* getOrganizationAccount(organizationId, accountId) {
  var user_data = yield call(getLatestUserData);
  return user_data.orgs_by_id[organizationId].all_accounts_by_id[accountId];
}
