// @flow
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import { state } from "../store";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn:
    "https://42a5e65463b5469e9f379ef0bc092465@o7583.ingest.sentry.io/5541040",
  environment: process.env.REACT_APP_SENTRY_ENV,
});

const sagaMiddleware = createSagaMiddleware({
  onError(err) {
    Sentry.captureException(err);
  },
});

const bootstrap = ({ middleware = [], initialState } = {}) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    state.reducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware, (store) => (next) => (action) => {
        /* istanbul ignore if */
        if (process.env.NODE_ENV === "development") {
          console.log(action);
        }
        return next(action);
      })
    )
  );

  return store;
};

export function configureStore() {
  const store = bootstrap({
    initialState: {},
    middleware: [sagaMiddleware],
  });
  sagaMiddleware.run(sagas);
  return store;
}
