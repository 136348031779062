import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { api } from "../../../../helpers/api";
import { authActions, authSelector } from "../../../auth/store";
import { toast } from "react-toastify";
import {
  organizationSecretDetailsActions,
  organizationSecretDetailsSelector,
} from "./store";
import { secretActions } from "../../../../components/secrets/store";
import {
  sym_key_by_org_id_decrypt_new,
  remove_placeholders_from_login_string,
  replace_login_and_password_for_dd_login_string,
  sym_key_by_org_id_new,
  encrypt,
  decrypt,
  decrypt_organization_secret,
  sym_key,
  getLatestUserData,
} from "../../../../helpers/apiUtils";
import { get_g_s } from "../../../../helpers/session";
import { org_sym_key } from "../../../../helpers/apiUtils";
import {
  getGroupAccount,
  getOrganizationAccount,
} from "../../../../helpers/ckService/AccountService";
import {
  get_group,
  get_org,
} from "../../../../helpers/ckService/UserDataHelper";
import {
  decryptObjectMarkingCorrupt,
  isCorruptAfterDecryption,
} from "../../../../helpers/ckService/EncryptionHelper";
import { teamsTabSelector } from "../../teams-tab/store";
import { ckServiceSymKey } from "../../../../helpers/ckService/AccountEncryptionRules";
import { secretTabActions } from "../store";
import sentryErrorCatch from "../../../../helpers/sentryUtils";

// Display secret by Id
function* OrganizationSecretByIdSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        organizationSecretDetailsActions.organizationSecretById().type
      );
      yield putResolve(organizationSecretDetailsActions.setLoading(true));
      var url;
      let apiData;
      let user_data = yield call(getLatestUserData);
      const { csrfToken } = yield select(authSelector((state) => state));

      let decryptedSecret;
      const { userRole } = yield select(teamsTabSelector((state) => state));

      if (userRole === "owner" || userRole === "admin") {
        apiData = yield call(
          api,
          `/organizations/${data.organization_id}/accounts/${data.id}.json`, // orgid and secretID
          "GET",
          null,
          null,
          csrfToken
        );
        let orgSymKey = yield call(
          org_sym_key,
          user_data,
          data.organization_id
        );
        decryptedSecret = decrypt(apiData, orgSymKey);
      } else {
        //let group_id = data.organization_id;
        apiData = yield call(
          api,
          `/groups/${data.organization_group_id}/accounts/${data.id}.json`,
          "GET",
          null,
          null,
          csrfToken
        );
        decryptedSecret = yield call(
          decrypt_organization_secret,
          user_data,
          apiData
        );
      }
      yield putResolve(organizationSecretDetailsActions.setLoading(false));
      yield putResolve(authActions.setMe(user_data));
      yield putResolve(
        organizationSecretDetailsActions.loadSecret(decryptedSecret)
      );
      yield putResolve(secretActions.loadSecret(decryptedSecret));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#138");
      // toaster
      toast.error("Something went wrong #138", {
        className: "toast-danger",
      });
      console.log("error in OrganizationSecretByIdSaga", err);
    }
  }
}

//delete organization secret
function* deleteOrganizationSecretSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        organizationSecretDetailsActions.deleteOrganizationSecret().type
      );
      yield putResolve(secretActions.setDeleteButtonLoading(true));
      const { me, csrfToken } = yield select(authSelector((state) => state));
      const { userRole } = yield select(teamsTabSelector((state) => state));
      let user_data = me;
      let apiData;
      const form_data = new FormData();
      var url = "";

      if (userRole === "owner" || userRole === "admin") {
        var account = undefined;
        var is_org_account = data.organization_id != "";
        if (is_org_account) {
          url = `/organizations/${data.organization_id}/accounts/${data.account_id}`;
          var organizationId = parseInt(data.organization_id);
          var accountId = parseInt(data.account_id);
          account = yield call(
            getOrganizationAccount,
            organizationId,
            accountId,
            user_data
          );
        }

        // var ck_user;
        let orgSymKey = get_org(user_data);
        let grpSymKey = get_group(user_data);
        var isCorrupt = isCorruptAfterDecryption(
          account,
          // commonkey,
          // cksession,
          orgSymKey,
          grpSymKey
        );

        form_data.append("corrupt", isCorrupt);
      } else {
        var organizationId = parseInt(data.organization_id);
        var groupId = parseInt(data.group_id);
        var accountId = parseInt(data.account_id);

        var account = yield call(
          getGroupAccount,
          organizationId,
          groupId,
          accountId,
          user_data
        );

        // var ck_user;
        let orgSymKey = get_org(user_data);
        let grpSymKey = get_group(user_data);
        var isCorrupt = isCorruptAfterDecryption(
          account,
          //commonkey,
          //cksession,
          orgSymKey,
          grpSymKey
        );

        form_data.append("corrupt", isCorrupt);
        url = `/groups/${data.group_id}/accounts/${data.account_id}`;
      }

      // perosnal secret API
      apiData = yield call(api, url, "DELETE", null, form_data, csrfToken);

      if (apiData.success === true) {
        yield putResolve(secretActions.setDeleteButtonLoading(false));
        var user_me_data = yield call(getLatestUserData);
        yield putResolve(authActions.setMe(user_me_data));
        data.history.go(-1);
        //toaster
        toast.success("Secret Delete Successfully", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#139");
      // toaster
      toast.error("Something went wrong #139", {
        className: "toast-danger",
      });
      console.log("error in deleteOrganizationSecretSaga", err);
    }
  }
}

function* generatePayload(data, decryptedSecret, userRole) {
  let payload;
  if (userRole === "owner" || userRole === "admin") {
    //come here when user is owner or admin
    // payload for secret tab (same payload for Auto Login record and custom record)
    if (data.auto_login === true) {
      payload = {
        organization_id: decryptedSecret.organization_id, //.toString(),
        page_title: data.page_title,
        custom_title: decryptedSecret.custom_title,
        login_hostname: data.login_page_hostname,
        redirect_hostname: decryptedSecret.redirect_page_hostname,
        password: data.password,
        account_id: decryptedSecret.id, //.toString(),
        url: data.url,
        custom: decryptedSecret.auto_login ? false : true,
        login: data.login,
        notes: data.notes,
        group_id: decryptedSecret.group_id,
        type: "organization",
      };
    } else {
      payload = {
        organization_id: decryptedSecret.organization_id, //.toString(),
        page_title: data.page_title,
        custom_title: decryptedSecret.custom_title,
        login_hostname: data.login_page_hostname,
        redirect_hostname: undefined, //undefined (In custom record)
        password: data.password,
        account_id: decryptedSecret.id, //.toString(),
        url: data.url,
        custom: decryptedSecret.auto_login ? false : true,
        login: data.login,
        notes: data.notes,
        group_id: decryptedSecret.group_id,
        type: "organization",
      };
    }
  } else {
    //come here when user is manager (for member edit secret is not allowed)
    //payload for secret tab (same payload for Auto Login record and custom record)
    if (data.auto_login === true) {
      payload = {
        account_id: decryptedSecret.id,
        custom: decryptedSecret.auto_login ? false : true,
        custom_title: decryptedSecret.custom_title,
        group_id: decryptedSecret.group_id,
        login: data.login,
        login_hostname: data.login_page_hostname,
        notes: data.notes,
        organization_id: decryptedSecret.organization_id,
        page_title: data.page_title,
        password: data.password,
        redirect_hostname: undefined, //undefine (In custom record also for auto-login record)
        url: data.url,
        type: "organization", //"manager", (In custom record also for auto-login record)
      };
    } else {
      payload = {
        account_id: decryptedSecret.id,
        custom: decryptedSecret.auto_login ? false : true,
        custom_title: decryptedSecret.custom_title,
        group_id: decryptedSecret.group_id,
        login: data.login,
        login_hostname: undefined, //undefine (In custom record)
        notes: data.notes,
        organization_id: decryptedSecret.organization_id,
        page_title: data.page_title,
        password: data.password,
        redirect_hostname: undefined, //undefine (In custom record also for auto-login record)
        url: data.url,
        type: "organization", //"manager", (In custom record also for auto-login record)
      };
    }
  }
  return payload;
}

function* updateOrganizationSecretSaga() {
  while (true) {
    try {
      const { payload: dataPayload } = yield take(
        organizationSecretDetailsActions.updateOrganizationSecret().type
      );

      yield putResolve(secretActions.setUpdateButtonLoading(true));

      const { userRole } = yield select(teamsTabSelector((state) => state));

      const { organizationAccount } = yield select(
        organizationSecretDetailsSelector((state) => state)
      );
      let data = yield call(
        generatePayload,
        dataPayload,
        organizationAccount.item,
        userRole
      );

      // login, login_hostname, redirect_hostname, account_id, type required
      // message
      if (
        !data.login ||
        !data.login_hostname ||
        !data.id ||
        !data.type ||
        !data.redirect_hostname
      ) {
        const { me, csrfToken } = yield select(authSelector((state) => state));

        let user_data = me;
        let url = "";
        if (
          me.orgs_by_id[data.organization_id] &&
          !me.orgs_by_id[data.organization_id].admin
        ) {
          url = `/me/all_accounts?group_id=${data.group_id}`;
        } else {
          url = `/me/all_accounts/`;
        }
        var urlAjax = yield call(api, url, "GET", null, null, csrfToken);

        if (urlAjax.length > 0) {
          // Convert all encrypted account to decrypted account
          // according to type
          var accounts;
          if (userRole === "owner" || userRole === "admin") {
            // decrypt org secret (Owner /Admin)
            accounts = convert_encrypt_to_decrypt_based_on_type(urlAjax, me);
          } else {
            // decrypt org secret (Manager)
            urlAjax = urlAjax.filter(
              (secret) =>
                secret.class_name != "UserAccount" &&
                secret.class_name != "OrganizationAccount"
            );
            accounts = urlAjax.map((secret) => {
              let accountId = parseInt(secret.id);
              let groupId = parseInt(secret.organization_group_id);
              let organizationId = parseInt(secret.organization_id);
              let groupAccounts =
                user_data.orgs_by_id[organizationId].groups_by_id[groupId]
                  .accounts;

              //getting current account object
              let organizationAccount;
              organizationAccount = groupAccounts.filter(
                (account) => account.id === accountId
              );
              if (organizationAccount.length === 0) {
                let userAccount = user_data.user.accounts;
                //getting current account object
                organizationAccount = userAccount.filter(
                  (account) => account.id === accountId
                );
              }
              let groupSymKey = get_group(user_data, groupId, organizationId)
                .symKey;
              //return object with all info with symkey of account
              let symKey = ckServiceSymKey(secret, organizationId, groupSymKey);
              //Decrypting the secret and also checking for corrupt secrets
              let decryptedAccount = decryptObjectMarkingCorrupt(
                secret,
                symKey
              );
              return decryptedAccount;
            });
          }

          if (
            check_if_exists_except(
              data.account_id,
              data.login,
              data.login_hostname,
              data.redirect_hostname,
              accounts
            ) !== false
          ) {
            dataPayload.history.go(-1);
            yield putResolve(secretActions.setUpdateButtonLoading(false));
            toast.error("Account already added.", {
              className: "toast-danger",
            });
          } else {
            var to_save = {
              login: data.login,
              page_title: data.page_title,
              cipher: data.password,
              notes: data.notes,
              custom_title: data.custom_title,
            };

            // save all shit
            ///====> not requred for personal secret
            if (data.save_all) {
              to_save = Object.assign(to_save, {
                login_origin: data.login_url,
                redirect_origin: data.redirect_origin,
                url: data.url,
                needs_to_be_completed: false,
                login_page_hostname: data.login_hostname,
                login_string: remove_placeholders_from_login_string(
                  data.login_string,
                  data.login,
                  data.password
                ),
                redirect_page_title: data.redirect_page_title,
                login_page_title: data.login_page_title,
                redirect_page_hostname: data.redirect_hostname,
                //auto_login: !args.custom
              });
            }
            ///====> not requred for personal secret
            if (data.custom) {
              to_save.login_page_hostname = data.page_title;
              to_save.login_page_title = data.page_title;
              to_save.redirect_page_title = data.page_title;
              to_save.redirect_page_hostname = data.page_title;
              to_save.url = data.url;
              to_save.login_string = "";
            } else {
              if (!data.save_all) {
                // find this account in current accounts and lets update the login_string
                var account_ref = accounts.filter(
                  (account) =>
                    account.id == parseInt(data.account_id) &&
                    (account.type == data.type ||
                      account.organization_id == data.organization_id ||
                      account.organization_group_id == parseInt(data.group_id))
                );

                if (account_ref.length == 1) {
                  to_save.login_string = replace_login_and_password_for_dd_login_string(
                    account_ref[0].login_string,
                    account_ref[0].login,
                    to_save.login
                  );
                  to_save.login_string = replace_login_and_password_for_dd_login_string(
                    to_save.login_string,
                    account_ref[0].cipher,
                    to_save.cipher
                  );
                } else {
                  //toaster
                  //
                  alert(
                    "Oh no an error occurred! We're on it! More than one account with the same id...impossible"
                  );
                  return;
                }
              }
            }

            //Arrange the data to be given to form data and encrypt it.
            // rename
            var save = {};
            for (let x in to_save) {
              save[data.type + "_" + "account[" + x + "]"] = to_save[x];
            }

            // encrypt
            let save_enc = {};

            // setup url
            url = {};
            if (
              user_data.orgs_by_id[data.organization_id] &&
              !user_data.orgs_by_id[data.organization_id].admin
            ) {
              data.type = "manager";
            }

            switch (data.type) {
              case "user":
                url.path = `/user_accounts/${data.account_id}.json`;
                save_enc = encrypt(save, sym_key());
                break;
              case "organization":
                save_enc = encrypt(
                  save,
                  sym_key_by_org_id_decrypt_new(data.organization_id, user_data)
                );

                url.organization_id = data.organization_id;
                url.path = `/organizations/${data.organization_id}/accounts/${data.account_id}.json`;
                break;

              case "manager":
                let org = user_data.orgs_by_id[data.organization_id];
                let group = org.groups.filter(
                  (group) => group.id === data.group_id
                )[0];
                var key = group.symKey;

                var decrypt_org_group_symkey = decrypt(key, sym_key());
                delete_param_for_org_grp_account(save);
                save_enc = encrypt(save, decrypt_org_group_symkey);

                url.organization_id = data.organization_id;

                url.path = `/organizations/${data.organization_id}/groups/${data.group_id}/accounts/${data.account_id}/update_manager.json`;

                // If manager, handle saving within this callback.
                //instance.saveAccount(args, argsdup, to_save, url, save_enc);
                break;
            }

            const form_data = new FormData();
            for (let key in save_enc) {
              form_data.append(key, save_enc[key]);
            }

            let apiData;
            apiData = yield call(
              api,
              url.path,
              "PUT",
              null,
              form_data,
              csrfToken
            );

            if (apiData.success === true) {
              if (data.type == "organization" && apiData.groups) {
                // rename for group save
                var save = {};

                // save it all
                var save = {};
                for (var arg in to_save) {
                  if (arg != "needs_to_be_completed") {
                    save["organization_group_account[" + arg + "]"] =
                      to_save[arg];
                  }
                }

                var groups = apiData.organization_group_accounts;

                for (var g in groups) {
                  if (g === "isArray") {
                    //argsdup.success(d);
                    continue;
                  }

                  // encrypt
                  var gsk = decrypt(
                    groups[g].k,
                    sym_key_by_org_id_decrypt_new(
                      url.organization_id,
                      user_data
                    )
                  );

                  delete_param_for_org_grp_account(save);

                  var g_save = encrypt(save, gsk);

                  // id of account just saved
                  g_save[
                    "organization_group_account[organization_account_id]"
                  ] = apiData.organization_account.id;

                  const group_form_data = new FormData();
                  //Form Data for Group
                  for (let key in g_save) {
                    group_form_data.append(key, g_save[key]);
                  }

                  yield call(
                    api,
                    `/organizations/${url.organization_id}/groups/${groups[g].group_id}/accounts/${groups[g].organization_group_account}.json`, //orgid and account_id
                    "put",
                    null,
                    group_form_data,
                    csrfToken
                  );
                }
                yield putResolve(secretActions.setUpdateButtonLoading(false));
              }
              //toaster;
              toast.success("Secret Update Successfully", {
                className: "toast-success",
              });
              yield putResolve(secretActions.setUpdateButtonLoading(false));
              // const user_me_data = yield call(getLatestUserData);
              // yield putResolve(authActions.setMe(user_me_data));
              yield putResolve(
                secretTabActions.loadData({ companyid: data.organization_id })
              );
              dataPayload.history.go(-1);
            } else {
              yield call(sentryErrorCatch, "API response is empty", "#140");
              //toaster;
              toast.error("Something went wrong #140", {
                className: "toast-danger",
              });
            }
          }
        } else {
          //toaster;
          toast.error("No Secret Accounts", {
            className: "toast-danger",
          });
        }
      } else {
        yield call(sentryErrorCatch, "Condition false", "#141");
        //toaster;
        toast.error("Something went wrong #141", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#142");
      //toaster;
      toast.error("Something went wrong #142", {
        className: "toast-danger",
      });
    }
  }
}

function delete_param_for_org_grp_account(save) {
  delete save["organization_account[needs_to_be_completed]"];
}

// check if account exists in current acccounts array
function check_if_exists_except(
  account_id,
  login,
  login_hostname,
  redirect_hostname,
  all_accounts
) {
  for (var i = 0; i < all_accounts.length; i++) {
    if (
      all_accounts[i].login === login && // same login
      all_accounts[i].login_page_hostname === login_hostname && // same page
      all_accounts[i].redirect_page_hostname === redirect_hostname && // same page
      all_accounts[i].id !== account_id // not same account id
    ) {
      return all_accounts[i];
    }
  }
  return false;
}

function convert_encrypt_to_decrypt_based_on_type(arr, user_data) {
  // types can be class_name => USER / ORGANIZATION / MANAGER
  // 1.type=user          class_name=UserAccount
  // 2.type=organization  class_name=OrganizationAccount
  // 3.type=              class_name-OrganizationGroupAccount
  // type=> is missing if the class_name is OrganizationGroupAccount
  //        they are determining it by using this condition
  //        if ( user_data.orgs_by_id[v.organization_id] && !user_data.orgs_by_id[v.organization_id].admin)
  var accounts = [];
  let key;
  for (let i = 0; i < arr.length; i++) {
    let v = arr[i];

    if (v.type == "user") {
      key = sym_key();
    } else {
      if (
        user_data.orgs_by_id[v.organization_id] &&
        !user_data.orgs_by_id[v.organization_id].admin
      ) {
        key = get_g_s(v.organization_id, v.organization_group_id, user_data);
      } else {
        key = decrypt(
          sym_key_by_org_id_new(v.organization_id, user_data),
          sym_key()
        );
      }
    }
    accounts.push(decrypt(v, key));
  }
  return accounts;
}

export default function* secretTabDetailsRootSaga() {
  yield spawn(OrganizationSecretByIdSaga);
  yield spawn(updateOrganizationSecretSaga);
  yield spawn(deleteOrganizationSecretSaga);
}
