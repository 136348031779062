//EDIT DELETE

import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { secretActions } from "./store";
import sentryErrorCatch from "../../helpers/sentryUtils";
import { toast } from "react-toastify";

function* dummySaga() {
  while (true) {
    try {
      const { payload: data } = yield take(secretActions.loadData().type);
      yield true;
    } catch (err) {
      yield call(sentryErrorCatch, err, "#201")
      toast.error("Something Went Wrong #201", {
        className: "toast-danger",
      });
    }
  }
}

export default function* dummyRootSaga() {
  yield spawn(dummySaga);
}
