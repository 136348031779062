//window.ck.Services.AccountEncryptionRules
import { sym_key, decrypt } from "../apiUtils";
export function ckServiceSymKey(account, orgSymKey, grpSymKey) {
  var userSymKey = sym_key();

  var result;
  switch (account.class_name) {
    case "OrganizationAccount":
      result = decrypt(orgSymKey, userSymKey);

      break;
    case "OrganizationGroupAccount":
      var groupSymKey = grpSymKey;
      try {
        var orgKey = decrypt(orgSymKey, userSymKey);

        result = decrypt(groupSymKey, orgKey);
      } catch (e) {
        result = decrypt(groupSymKey, userSymKey);
      }
      break;
    default:
      result = userSymKey;
      break;
  }

  return result;
}

//decrypt the sym key of organization groupt account
export function decrypt_org_group_account_symkey(orgSymKey, grpSymKey) {
  var groupSymKey = grpSymKey;
  var userSymKey = sym_key();
  var result;
  try {
    var orgKey = decrypt(orgSymKey, userSymKey);

    result = decrypt(groupSymKey, orgKey);
  } catch (e) {
    result = decrypt(groupSymKey, userSymKey);
  }
  return result;
}
