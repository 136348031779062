//window.ck.Services.UserDataHelper = UserDataHelper;

export function get_org(userData, organizationId) {
  return userData.orgs_by_id[organizationId];
}

export function get_group(userData, id, organizationId) {
  for (organizationId in userData.orgs_by_id) {
    var organizationData = userData.orgs_by_id[organizationId];
    if (!!organizationData.groups_by_id[id]) {
      return organizationData.groups_by_id[id];
    }
  }
}

export function getUserRole(orgs, team) {
  if (orgs && team) {
    let role;
    orgs.forEach((org) => {
      if (org.id === team.organization_id) {
        org.groups.forEach((group) => {
          if (group.id === team.id) {
            role = group.role;
          }
        });
      }
    });
    return role;
  }
}

export function getExistingSecretInTeam(user_data, orgId, secretId) {
  let org = get_org(user_data, orgId);
  let groups = org.groups;
  let finalSecret = [];
  groups.forEach((group) => {
    group.accounts.forEach((account) => {
      if (account.account_id === secretId) {
        finalSecret.push(group);
      }
    });
  });
  return finalSecret;
}

export function is_not_admin(organization_id, user_data) {
  return (
    user_data.orgs_by_id[organization_id] &&
    !user_data.orgs_by_id[organization_id].admin
  );
}
