import { state } from "../../../store";
export const [userActions, userSelector, userState] = state("user", {
  initial: {
    users: { items: [], loading: false },
    owner: { items: {}, loading: false },
    multi_level_admin_or_30_days: false,
    userInvitations: { items: [], loading: false },
    email: "",
    organization_id: "",
    expandedRows: [],
    grantPermissionLoading: [],
    removePermissionLoading: [],
  },
  on: {
    loadUserData: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.users.items = data.users;
      newStoreObj.users.loading = true;
      newStoreObj.owner.items = data.owner;
      newStoreObj.owner.loading = true;
      newStoreObj.organization_id = data.organization.id;
      newStoreObj.multi_level_admin_or_30_days =
        data.multi_level_admin_or_30_days;
      newStoreObj.growth_plan_14_days = data.growth_plan_14_days;
      newStoreObj.userInvitations.items = data.userInvitation;
      let result = [...data.users];
      newStoreObj.grantPermissionLoading = result.fill(false);
      newStoreObj.removePermissionLoading = result.fill(false);
      return newStoreObj;
    },
    removeUser: (_, id) => {
      const newStoreObj = {
        ..._,
      };
      let users = newStoreObj.users.items;
      users = users.filter((users) => users.id !== id);
      return {
        ...newStoreObj,
        users: { ...newStoreObj.users, items: users },
      };
    },

    grantPermission: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      // newStoreObj.users.items = data.users;
      let users = newStoreObj.users.items;
      let index = users.findIndex((users) => users.id === data.id);
      users[index].permission = "removePermission";
      users[index].invitationId = data.invitationId;
      newStoreObj.users.items = [...users];
      return {
        ...newStoreObj,
      };
    },

    removeAccess: (_, id) => {
      const newStoreObj = {
        ..._,
      };
      let users = newStoreObj.users.items;
      let index = users.findIndex((users) => users.id === id);
      users[index].permission = "grantPermission";
      newStoreObj.users.items = [...users];
      return { ...newStoreObj };
    },

    removeTeamUser: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      if (newStoreObj.users.items[data.userIndex].team.length === 1) {
        newStoreObj.users.items.splice(data.userIndex, 1);
      } else {
        newStoreObj.users.items[data.userIndex].team.splice(data.teamIndex, 1);
      }
      return {
        ...newStoreObj,
        users: { items: [...newStoreObj.users.items], loading: true },
      };
    },

    displayInfoBox: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.userInfo = data;
      return newStoreObj;
    },

    displayInfo: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      const currentExpandedRows = newStoreObj.expandedRows;
      const isRowCurrentlyExpanded = currentExpandedRows.includes(data.id);
      const newExpandedRows = isRowCurrentlyExpanded
        ? currentExpandedRows.filter((id) => id !== data.id)
        : currentExpandedRows.concat(data.id);

      newStoreObj.expandedRows = newExpandedRows;
      return newStoreObj;
    },
    setShowBillingModal: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        showBillingModal: data,
      };
    },
    setGrantAdminPermissionLoading: (_, data) => {
      const newStoreObj = { ..._ };
      newStoreObj.grantPermissionLoading[data.index] = data.flag;
      newStoreObj.grantPermissionLoading = [
        ...newStoreObj.grantPermissionLoading,
      ];
      return newStoreObj;
    },
    setRemovePermissionLoading: (_, data) => {
      const newStoreObj = { ..._ };
      newStoreObj.removePermissionLoading[data.index] = data.flag;
      newStoreObj.removePermissionLoading = [
        ...newStoreObj.removePermissionLoading,
      ];
      return newStoreObj;
    },
  },
  events: [
    "loadData",
    "deleteUser",
    "grantPermission",
    "removePermission",
    "removeTeam",
    "redirectURL",
  ],
});
