import { call, select, spawn, take, putResolve } from "redux-saga/effects";
import { registerActions } from "./store";
import { api } from "../../../helpers/api";
import {
  generateNewUserInfoObject,
  encrypt,
  getLatestUserData,
} from "../../../helpers/apiUtils";
import { toast } from "react-toastify";
import { authActions, authSelector } from "../store";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* getMicrosoftRefreshTokenSaga() {
  while (true) {
    try {
      const { payload: code } = yield take(
        registerActions.getMicrosoftRefreshToken().type
      );
      yield putResolve(registerActions.setTokenLoading(true));
      let formData = new FormData();

      formData.append("code", code);

      const result = yield call(
        api,
        `/microsoft_refresh_token.json`,
        "POST",
        null,
        formData,
        null
      );

      if (result.tokens.refresh_token) {
        sessionStorage.clear();
        sessionStorage.setItem(
          "microsoftRefreshToken",
          result.tokens.refresh_token
        );
        sessionStorage.setItem("firstName", result.user.value[0].givenName);
        sessionStorage.setItem("lastName", result.user.value[0].surname);
        sessionStorage.setItem(
          "emailAddress",
          result.user.value[0].emailAddress
        );
      }
      yield putResolve(registerActions.setTokenLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#241");
      toast.error("Something Went Wrong #241", {
        className: "toast-danger",
      });
      console.log("err in getMicrosoftRefreshTokenSaga", err);
    }
  }
}

function* getGoogleRefreshTokenSaga() {
  while (true) {
    try {
      const { payload: code } = yield take(
        registerActions.getGoogleRefreshToken().type
      );
      yield putResolve(registerActions.setTokenLoading(true));

      // Get access token and refresh token
      async function refreshToken(url) {
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({
            code,
            client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_SECRET,
            grant_type: "authorization_code",
            redirect_uri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI,
          }),
        });
        return response.json();
      }
      refreshToken("https://oauth2.googleapis.com/token").then((data) => {
        if (data.refresh_token) {
          sessionStorage.clear();
          sessionStorage.setItem("googleRefreshToken", data.refresh_token);
        }
        // Get user profile
        async function userInfo(url) {
          const response = await fetch(url, {
            method: "GET",
          });
          return response.json();
        }
        userInfo(
          `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${data.access_token}`
        ).then((response) => {
          if (data.refresh_token) {
            sessionStorage.setItem("firstName", response.given_name);
            sessionStorage.setItem("lastName", response.family_name);
            sessionStorage.setItem("emailAddress", response.email);
          }
        });
      });
      yield putResolve(registerActions.setTokenLoading(false));
    } catch (err) {
      yield putResolve(registerActions.setTokenLoading(false));
      yield call(sentryErrorCatch, err, "#243");
      toast.error("Something Went Wrong #243", {
        className: "toast-danger",
      });
      console.log("err in getGoogleRefreshTokenSaga", err);
    }
  }
}

async function getKeys(UIObj) {
  let keyss = null;
  var counter = 0;
  if (window.crypto && window.crypto.subtle) {
    keyss = await window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        // Consider using a 4096-bit key for systems that require long-term security
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256",
      },
      true,
      ["encrypt", "decrypt"]
    );
  } else {
    keyss = await window.myRSAManager.generateKey(
      2048,
      "3",
      UIObj,
      function (c) {
        // status messages
        counter++;
        if (counter < 8 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "your tip has been submitted!";
          document.getElementById("registerProgress").innerHTML =
            "Starting the registration process...";
        } else if (counter < 16 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "This may take a couple of minutes...";
        } else if (counter < 24 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Firing up encryption engine...";
        } else if (counter < 32 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Verifying secure connection...";
        } else if (counter < 40 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Connection secured and ready...";
        } else if (counter < 48 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Generating initial encryption keys....";
        } else if (counter < 56 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Encryption keys successfully created...";
        } else if (counter < 64 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Securing user sensitive information...";
        } else if (counter < 72 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Setting up secure environment...";
        } else if (counter < 80 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Validating generated keys...";
        } else if (counter < 88 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "All keys have been created...";
        } else if (counter < 96 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Checking for any vulnerabilities...";
        } else if (counter < 104 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Preparing your confirmation email...";
        } else if (counter < 112 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Wrapping up encryption process...";
        } else if (counter < 120 * 1000) {
          document.getElementById("registerProgress").innerHTML =
            "Tieing up loose ends...";
        }
      },
      function (RSAObj, UIObj) {}
    );
  }
  return { RSAObj: keyss, UIObj: UIObj };
}

function* registerPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(registerActions.registerUser().type);
      yield putResolve(registerActions.setModalStatus(true));
      // Generate new user information object from username and password
      var UIObj = yield call(generateNewUserInfoObject, data);

      //show message on button > checking email...

      const result = yield call(api, `/me/salts?email=${data.email}`);

      if (result.error && result.error.length) {
        //getting RSA KEYS
        var keyss = yield call(getKeys, UIObj);

        if (keyss) {
          const { RSAObj, UIObj } = keyss;
          // Extract the public/private RSA keys from newly generated RSAObj
          if (window.crypto && window.crypto.subtle) {
            var rsaPubKey = JSON.stringify(
              yield window.crypto.subtle.exportKey("jwk", RSAObj.publicKey)
            );
            var rsaPrivKey = JSON.stringify(
              yield window.crypto.subtle.exportKey("jwk", RSAObj.privateKey)
            );
            UIObj.asymmetricKeyAlgo = "RSA-OAEP";
          } else {
            var rsaPubKey = window.base64x_encode(RSAObj.publicKeyBytes());
            var rsaPrivKey = window.base64x_encode(RSAObj.privateKeyBytes());
            UIObj.asymmetricKeyAlgo = null;
          }

          // Add the RSA public key and the encrypted RSA private key to UIObj

          UIObj.rsaPublicKey = rsaPubKey;

          // Encrypt the private RSA key using AES with the new user's symmetric AES key

          UIObj.rsaPrivateKey = encrypt(rsaPrivKey, UIObj.symKey);

          // Get google contacts
          let getGoogleContacts;
          let getGoogleOtherContacts;
          if (UIObj.googleRefreshToken != null) {
            let getAccessToken = yield call(
              accessToken,
              "https://oauth2.googleapis.com/token",
              UIObj.googleRefreshToken
            );

            getGoogleContacts = yield call(
              userContacts,
              `https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses&access_token=${getAccessToken.access_token}`
            );
            getGoogleOtherContacts = yield call(
              userContacts,
              `https://people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses&access_token=${getAccessToken.access_token}`
            );
          }
          let contacts = [];

          getGoogleContacts &&
            getGoogleContacts.connections &&
            getGoogleContacts.connections.forEach(
              ({ emailAddresses, names }) => {
                if (
                  emailAddresses &&
                  emailAddresses[0].value &&
                  emailAddresses[0].value.length
                ) {
                  contacts.push({
                    name: names ? names[0].displayName : null,
                    email: emailAddresses[0].value,
                  });
                }
              }
            );

          getGoogleOtherContacts &&
            getGoogleOtherContacts.otherContacts &&
            getGoogleOtherContacts.otherContacts.forEach(
              ({ emailAddresses, names }) => {
                if (
                  emailAddresses &&
                  emailAddresses[0].value &&
                  emailAddresses[0].value.length
                ) {
                  contacts.push({
                    name: names ? names[0].displayName : null,
                    email: emailAddresses[0].value,
                  });
                }
              }
            );

          // set sign_up_with
          let sign_up_with;
          if (UIObj.googleRefreshToken !== null) {
            sign_up_with = "google";
          } else if (UIObj.microsoftRefreshToken !== null) {
            sign_up_with = "microsoft";
          } else {
            sign_up_with = "email";
          }

          // creating form data
          const form_data = new FormData();

          form_data.append("user[firstname]", UIObj.firstname);
          form_data.append("user[lastname]", UIObj.lastname);
          form_data.append("user[email]", UIObj.email);
          form_data.append("user[password]", UIObj.passwordHash);
          form_data.append("public_key", UIObj.rsaPublicKey);
          form_data.append("private_key", UIObj.rsaPrivateKey);
          form_data.append("asymmetric_key_algo", UIObj.asymmetricKeyAlgo);
          form_data.append("salt1", UIObj.salt1);
          form_data.append("salt2", UIObj.salt2);
          form_data.append("plan", UIObj.plan);
          form_data.append("need_to_choose_intent", false);
          // Oauth form_data
          form_data.append("googleRefreshToken", UIObj.googleRefreshToken);
          form_data.append(
            "microsoftRefreshToken",
            UIObj.microsoftRefreshToken
          );
          // google contacts
          form_data.append("googleContacts", JSON.stringify(contacts));
          form_data.append("sign_up_with", sign_up_with);

          // saving to data to DB
          const users = yield call(api, `/users.json`, "POST", null, form_data);
          //clearing session
          sessionStorage.clear();
          if (users && users.authentication_token) {
            window.dataLayer.push({
              event: "gtmEventClick",
              action: "User Registered",
            });
            window.dataLayer.push({
              event: "GAdsConversionEvent",
            });
            yield putResolve(registerActions.setModalStatus(false));
            if (users.sign_up_with === "email") {
              data.history.push(
                `/check-confirmation-email?emailID=${UIObj.email}`
              );
            } else {
              data.history.push(
                `/login?notify=You have been successfully registered, please login below!`
              );
            }
          } else {
            yield call(sentryErrorCatch, "API response is empty", "#106");
            toast.error("Something Went Wrong #106", {
              className: "toast-danger",
            });
            data.history.push("/register");
          }

          //storing session
          // window.sessionStorage.setItem("me", JSON.stringify(UIObj));
        }
      } else {
        yield putResolve(registerActions.setModalStatus(false));

        data.history.push("/login?used-email=true");
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#107");
      console.log(err);
      toast.error("Something Went Wrong #107", {
        className: "toast-danger",
      });
    }
  }
}

function* setAccountTypeSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        registerActions.setAccountType().type
      );
      const { csrfToken } = yield select(authSelector((state) => state));
      const { selected, history } = data;
      let formData = new FormData();
      let setAccountTypePayload = {
        intention_to_use_as: selected,
        need_to_choose_intent: true,
      };
      for (let key in setAccountTypePayload) {
        formData.append(key, setAccountTypePayload[key]);
      }
      yield call(
        api,
        `/me/set_intention_to_use_as.json`,
        "POST",
        null,
        formData,
        csrfToken
      );

      const user_me_data = yield call(getLatestUserData);
      yield putResolve(authActions.setMe(user_me_data));

      if (selected === "personal") {
        history.push(`/dashboard`);
      } else {
        history.push(`/add-company`);
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#236");
      console.log("in setAccountTypeSaga ", err);
      toast.error("Something Went Wrong #236", {
        className: "toast-danger",
      });
    }
  }
}

function* completeRegistrationSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        registerActions.completeRegistration().type
      );

      const { csrfToken } = yield select(authSelector((state) => state));
      const result = yield call(
        api,
        `/users/confirmation?confirmation_token=${data.token}`,
        "GET",
        null,
        null,
        csrfToken
      );

      if (result.success === true) {
        window.dataLayer.push({
          event: "gtmEventClick",
          action: "Email Verified ",
        });

        yield putResolve(
          authActions.logoutUser({
            history: data.history,
            notify: result.notice,
          })
        );

        data.history.push(`/login?notify=${result.notice}`);
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#108");
      toast.error("Something Went Wrong #108", {
        className: "toast-danger",
      });
      console.log("err in completeRegistrationSaga", err);
    }
  }
}

// Fetch google contacts
async function userContacts(url) {
  const response = await fetch(url, {
    method: "GET",
  });
  return response.json();
}
async function accessToken(url, refreshToken) {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_SECRET,
      refresh_token: refreshToken,
      grant_type: "refresh_token",
    }),
  });
  return response.json();
}

export default function* registerPageRootSaga() {
  yield spawn(registerPageSaga);
  yield spawn(completeRegistrationSaga);
  yield spawn(setAccountTypeSaga);
  yield spawn(getMicrosoftRefreshTokenSaga);
  yield spawn(getGoogleRefreshTokenSaga);
}
