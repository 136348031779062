import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { billingChangePlanActions } from "./store";
import { api } from "../../../../helpers/api";
import { authSelector, authActions } from "../../../auth/store";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../../helpers/sentryUtils";
import { loadStripe } from "@stripe/stripe-js";
import {
  makeAllAdminsToMemeber,
  makeAllManagersToMemeber,
} from "../../../../helpers/apiUtils";
import { getLatestUserData } from "../../../../helpers/apiUtils";
function* billingPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        billingChangePlanActions.loadData().type
      );
      yield putResolve(billingChangePlanActions.setLoading(true));

      let result = yield call(
        api,
        `/dashboard/companies/${data.companyid}/billing.json`
      );

      if (result && result.organization) {
        let data = {};
        data.organization = result.organization;
        data.subscribedPlans = result.subscribedPlans;
        data.have_credit_card = result.have_credit_card;
        data.growthPlan = result.growthPlan;
        data.teamManagementPlan = result.teamManagementPlan;
        data.usersCount = result.usersCount;
        data.growthPlanAnnual = result.growthPlanAnnual;
        data.teamPasswordManagementPlanAnnual =
          result.teamPasswordManagementPlanAnnual;

        if (result.subscribedPlans && result.subscribedPlans.length > 0) {
          let subscribedPlan = result.subscribedPlans[0];
          data.subscribedPlanInfo = subscribedPlan;
          if (
            subscribedPlan.stripe_plan_id ===
              result.teamManagementPlan.stripe_plan_name ||
            subscribedPlan.stripe_plan_id ===
              result.teamManagementPlan.stripe_user
          ) {
            data.selectedPlan = "startup";
            data.teamPasswordManagementPlanMonthlySelected = true;
            data.currentPlanName = "startupMonthly";
          }
          if (
            subscribedPlan.stripe_plan_id ===
              data.teamPasswordManagementPlanAnnual.stripe_plan_name ||
            subscribedPlan.stripe_plan_id ===
              data.teamPasswordManagementPlanAnnual.stripe_user
          ) {
            data.selectedPlan = "startup";
            data.teamPasswordManagementPlanMonthlySelected = false;
            data.currentPlanName = "startupAnnually";
          }

          if (
            subscribedPlan.stripe_plan_id ===
              data.growthPlan.stripe_plan_name ||
            subscribedPlan.stripe_plan_id === data.growthPlan.stripe_user
          ) {
            data.selectedPlan = "growth";
            data.growthPlanMonthlySelected = true;
            data.currentPlanName = "growthMonthly";
          }
          if (
            subscribedPlan.stripe_plan_id ===
              data.growthPlanAnnual.stripe_plan_name ||
            subscribedPlan.stripe_plan_id === data.growthPlanAnnual.stripe_user
          ) {
            data.selectedPlan = "growth";
            data.growthPlanMonthlySelected = false;
            data.currentPlanName = "growthAnnually";
          }
        } else {
          data.selectedPlan = "free";
          data.currentPlanName = "free";
        }

        data.teamManagementPlan.planName = "startup";
        data.growthPlan.planName = "growth";
        data.growthPlanAnnual.planName = "growthPlanAnnual";
        data.teamPasswordManagementPlanAnnual.planName =
          "teamPasswordManagementPlanAnnual";
        let paymentMethods =
          result.methods.length > 0 ? result.methods[0] : null;
        let savedCard;
        if (paymentMethods) {
          savedCard = true;
        } else {
          savedCard = false;
        }

        data.savedCard = savedCard;
        data.stripe_pk = result.stripe_pk;
        yield putResolve(billingChangePlanActions.loadBillingData(data));

        let admins = [];
        if (result.admins && result.admins.length > 0) {
          let admin = result.admins.map((user) => {
            user.invitationId = 0;
            return user;
          })[0];
          if (admin) {
            admins.push(admin);
          }
        }
        if (result.userInvitation && result.userInvitation.length > 0) {
          let adminInvitation = result.userInvitation.filter((user) => {
            if (user.admin === true) {
              user.invitationId = user.id;
              return user;
            }
          })[0];
          if (adminInvitation) {
            admins.push(adminInvitation);
          }
        }
        yield putResolve(billingChangePlanActions.setAdmins(admins));
        yield putResolve(
          billingChangePlanActions.setManagers(result.organization_group_users)
        );
      }
      yield putResolve(billingChangePlanActions.setLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#155");
      console.log("error in billingPageSaga", err);
      toast.error("Something Went Wrong #155", {
        className: "toast-danger",
      });
    }
  }
}
function* subscribeTogglePlanSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        billingChangePlanActions.subscribeTogglePlan().type
      );
      const { csrfToken } = yield select(authSelector((state) => state));
      if (data.planToSubscribe === "growth") {
        yield putResolve(billingChangePlanActions.setGrowthPlanLoading(true));
      }
      if (data.planToSubscribe === "startup") {
        yield putResolve(
          billingChangePlanActions.setTeamManagementPlanLoading(true)
        );
      }

      if (data.savedCard === false) {
        const form_data = new FormData();

        form_data.append("stripe_plan_id", data.subscribe.stripe_plan_name);

        let result = yield call(
          api,
          `/dashboard/companies/${data.organizationId}/billing/stripe_checkout_sessions.json`,
          "POST",
          null,
          form_data,
          csrfToken
        );

        if (result && result.stripe_checkout_session_id) {
          localStorage.setItem("subscribeAmount", data.subscribe.monthly_cost);

          const stripePromise = loadStripe(data.stripe_pk);
          const stripe = yield stripePromise;
          stripe
            .redirectToCheckout({
              sessionId: result.stripe_checkout_session_id,
            })
            .then(function (stripeResult) {
              console.log(
                "error in subscribePlanSaga",
                stripeResult.error.message
              );
            });
        }
      } else {
        let stripe_plan_id = data.subscribe.stripe_plan_name; //subscribedPlanInfo.stripe_plan_id;
        const subscriptionFormData = new FormData();

        subscriptionFormData.append("stripe_plan_id", stripe_plan_id);

        let subscriptionResult = yield call(
          api,
          `/organizations/${data.organizationId}/subscriptions.json`,
          "POST",
          null,
          subscriptionFormData,
          csrfToken
        );

        if (subscriptionResult.success === true) {
          // unsubscribing user from other plan

          let unsubscribe_stripe_plan_id = data.unsubscribe.stripe_plan_id;

          let unsubscribeResult = yield call(
            api,
            `/organizations/${data.organizationId}/subscriptions/${unsubscribe_stripe_plan_id}`,
            "DELETE",
            null,
            null,
            csrfToken
          );

          window.dataLayer.push({
            event: "gtmEventClick",
            action: "Subscribed Plan",
            subscribedPlanName: unsubscribe_stripe_plan_id,
            subscribedAmount: data.monthly_cost,
          });
          window.dataLayer.push({
            event: "GAdsConversionEvent",
          });

          //show notification
          toast.success(subscriptionResult.message, {
            className: "toast-success",
          });
        } else {
          //show notification
          toast.error(subscriptionResult.message, {
            className: "toast-danger",
          });
        }
      }

      if ((data.planToSubscribe = "growth")) {
        yield putResolve(billingChangePlanActions.setGrowthPlanLoading(false));
      }
      if ((data.planToSubscribe = "startup")) {
        yield putResolve(
          billingChangePlanActions.setTeamManagementPlanLoading(false)
        );
      }
      var user_data = yield call(getLatestUserData);
      yield putResolve(authActions.setMe(user_data));
      data.history.push(`/dashboard/companies/${data.organizationId}/billing`);
    } catch (err) {
      yield putResolve(billingChangePlanActions.setGrowthPlanLoading(false));
      yield putResolve(
        billingChangePlanActions.setTeamManagementPlanLoading(false)
      );
      yield call(sentryErrorCatch, err, "#156");
      console.log("error in subscribePlanSaga", err);
      toast.error("Something Went Wrong #156", {
        className: "toast-danger",
      });
    }
  }
}
function* subscribeFreePlanSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        billingChangePlanActions.subscribeFreePlan().type
      );
      const { csrfToken } = yield select(authSelector((state) => state));
      yield putResolve(billingChangePlanActions.setFreePlanLoading(true));

      let stripe_plan_id = data.plan.stripe_plan_id;

      let result = yield call(
        api,
        `/organizations/${data.organizationId}/subscriptions/${stripe_plan_id}`,
        "DELETE",
        null,
        null,
        csrfToken
      );

      if (result.success === true) {
        window.dataLayer.push({
          event: "gtmEventClick",
          action: "Unsubscribed Plan ",
          subscribedPlanName: stripe_plan_id,
        });
        toast.success("Successfully Selected Free Plan", {
          className: "toast-success",
        });

        if (data.userRole === "admin" && data.src === "PlanThroughPopup") {
          var user_data = yield call(getLatestUserData);
          yield putResolve(authActions.setMe(user_data));
          try {
            var teamId = user_data.orgs_by_id[data.organizationId].groups[0].id;
            data.history.push(
              `/dashboard/companies/${data.organizationId}/teams/${teamId}`
            );
          } catch (e) {
            data.history.push(`/dashboard`);
          }
        } else {
          data.history.push(
            `/dashboard/companies/${data.organizationId}/billing`
          );
        }
      } else {
        toast.error(result.message, {
          className: "toast-danger",
        });
      }
      var user_data = yield call(getLatestUserData);
      yield putResolve(authActions.setMe(user_data));
      yield putResolve(billingChangePlanActions.setFreePlanLoading(false));
    } catch (err) {
      yield putResolve(billingChangePlanActions.setFreePlanLoading(false));
      yield call(sentryErrorCatch, err, "#157");
      console.log("error in unsubscribePlanSaga", err);
      toast.error("Something Went Wrong #157", {
        className: "toast-danger",
      });
    }
  }
}

function* removeAllAdminPermissionSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        billingChangePlanActions.removeAllAdminPermission().type
      );

      for (let i = 0; i < data.admins.length; i++) {
        let admin = data.admins[i];
        yield call(makeAllAdminsToMemeber, {
          id: admin.id,
          invitationId: admin.invitationId,
          email: admin.email,
          companyid: data.organization_id,
          index: i,
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#154");
      console.log("error in  redirectURLSaga", err);
      toast.error("Something Went Wrong #154", {
        className: "toast-danger",
      });
    }
  }
}

function* removeAllManagerPermissionSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        billingChangePlanActions.removeAllManagerPermission().type
      );

      for (let i = 0; i < data.managers.length; i++) {
        let manager = data.managers[i];
        yield call(makeAllManagersToMemeber, { manager });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#154");
      console.log("error in  redirectURLSaga", err);
      toast.error("Something Went Wrong #154", {
        className: "toast-danger",
      });
    }
  }
}

export default function* billingChangePlanPageRootSaga() {
  yield spawn(billingPageSaga);
  yield spawn(subscribeTogglePlanSaga);
  yield spawn(subscribeFreePlanSaga);
  yield spawn(removeAllAdminPermissionSaga);
  yield spawn(removeAllManagerPermissionSaga);
}
