import { putResolve, take, call, spawn, select } from "redux-saga/effects";
import { teamsTabActions, teamsTabSelector } from "./store";
import { api } from "../../../helpers/api";
import {
  encrypt,
  create_group_key,
  decrypt,
  sym_key,
  getLatestUserData,
  rsaEncrypt,
  process_accounts_in_groups_only,
  subtleRSAEncrypt,
  update_invites_for_new_users,
} from "../../../helpers/apiUtils";
import {
  ckServiceSymKey,
  decrypt_org_group_account_symkey,
} from "../../../helpers/ckService/AccountEncryptionRules";
import { get_org, get_group } from "../../../helpers/ckService/UserDataHelper";
import { decryptObjectMarkingCorrupt } from "../../../helpers/ckService/EncryptionHelper";
import { authSelector, authActions } from "../../auth/store";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../helpers/sentryUtils";

export function* companyLoadPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamsTabActions.companyLoadData().type
      );
      const { me } = yield select(authSelector((state) => state));
      const { searchText } = yield select(teamsTabSelector((state) => state));
      if (data.secretLoad === true) {
        yield putResolve(teamsTabActions.setSecretLoading(true));
      } else {
        yield putResolve(teamsTabActions.setLoading(true));
      }

      let user_data = me;
      // confirm new user invitation from owner or admin
      const { userRole } = yield select(teamsTabSelector((state) => state));
      if (userRole === "owner" || userRole === "admin") {
        yield call(update_invites_for_new_users, me);
      }

      // update secret which is added by manager or member
      yield call(process_accounts_in_groups_only, user_data);

      let uri;

      if (data.ref === "teamList") {
        let teamId = data.id;
        if (teamId) {
          uri = `/dashboard/companies/${data.organization_id}/teams/${data.id}.json`;
        } else {
          uri = `/dashboard/companies/${data.organization_id}/teams.json`;
        }
      } else {
        let teamId =
          data.groups && data.groups.length > 0 ? data.groups[0].id : null;
        if (teamId) {
          uri = `/dashboard/companies/${data.id}/teams/${data.groups[0].id}.json`;
        } else {
          uri = `/dashboard/companies/${data.id}/teams.json`;
        }
      }

      let result = yield call(api, uri);
      //checking if team has secrets to decrypt
      try {
        if (result && result.secrets) {
          let organizationId = data.organization_id
            ? data.organization_id
            : data.id;
          let organization_sym_key = get_org(
            user_data,
            organizationId
          ).organization_sym_key;
          let groupId = result.team.id;
          let groupSymKey = get_group(
            user_data,
            groupId,
            organizationId
          ).symKey;

          let decryptedResult = result.secrets.map((secret, index) => {
            let accountId = parseInt(secret.id);
            let groupId = parseInt(secret.organization_group_id);
            let organizationId = parseInt(secret.organization_id);
            let groupAccounts =
              user_data.orgs_by_id[organizationId].groups_by_id[groupId]
                .accounts;

            //getting current account object
            let organizationAccount = groupAccounts.filter(
              (account) => account.id === accountId
            );

            if (organizationAccount) {
              organizationAccount = organizationAccount[0];

              //return object with all info with symkey of account
              let symKey;
              try {
                symKey = ckServiceSymKey(
                  organizationAccount,
                  organization_sym_key,
                  groupSymKey
                );
              } catch (error) {
                symKey = "";
              }

              //Decrypting the secret and also checking for corrupt secrets
              let decryptedAccount = decryptObjectMarkingCorrupt(
                organizationAccount,
                symKey
              );

              if (
                decryptedAccount.page_title === "" ||
                decryptedAccount.login === ""
              ) {
                decryptedAccount.corrupt = true;
              }
              return decryptedAccount;
            }
          });
          //replacing the original secrets array from api with decrypted array
          result["secrets"] = decryptedResult;
        }
      } catch (e) {
        result["secrets"] = [];
      }

      if (result && result.managers) {
        result["managers"] = result.managers.managers;
      }

      // let redirecting = false;
      // if (
      //   userRole === "owner" ||
      //   (userRole === "admin" &&
      //     result &&
      //     result.organization &&
      //     result.organization.have_credit_card === true)
      // ) {
      //   //getting billing info
      //   let billingResult = yield call(
      //     api,
      //     `/dashboard/companies/${result.organization.id}/billing.json`
      //   );
      //   //card details
      //   let paymentMethods =
      //     billingResult.methods && billingResult.methods.length > 0
      //       ? billingResult.methods[0]
      //       : null;

      //   if (paymentMethods) {
      //     result.organization.savedCard = true;
      //   } else {
      //     result.organization.savedCard = false;
      //     if (result.organization.have_credit_card === true) {
      //       redirecting = true;
      //       data.history.push(
      //         `/dashboard/companies/${result.organization.id}/select-plan/teams/${result.team.id}`
      //       );
      //     }
      //   }
      // }

      // if (redirecting === false) {
      //   yield putResolve(teamsTabActions.setLoading(false));
      // }

      yield putResolve(teamsTabActions.setLoading(false));
      yield putResolve(teamsTabActions.setSecretLoading(false));
      yield putResolve(teamsTabActions.setTeamsLoading(false));
      //yield putResolve(authActions.setMe(user_data));
      yield putResolve(teamsTabActions.setCurrentCompany({ ...result }));
      
    } catch (err) {
      yield call(sentryErrorCatch, err, "#115");
      console.log("error in companyLoadPageSaga", err);
      toast.error("Something went wrong #115", {
        className: "toast-danger",
      });
    }
  }
}

export function* removeInvitationSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamsTabActions.removeInvitation().type
      );

      const { csrfToken } = yield select(authSelector((state) => state));

      let result = yield call(
        api,
        `/invitations/${data.id}`,
        "DELETE",
        null,
        null,
        csrfToken
      );

      if (result.success === true) {
        yield putResolve(teamsTabActions.deleteInvite(data.id));
        yield putResolve(
          teamsTabActions.companyLoadData({
            id: data.group_id,
            organization_id: data.organization_id,
            ref: "teamList",
          })
        );
        var user_me_data = yield call(getLatestUserData);
        yield putResolve(authActions.setMe(user_me_data));
        toast.success("Invitation removed successfully!", {
          className: "toast-success",
        });
      } else {
        toast.error("Failed to Remove", {
          className: "toast-danger",
        });
      }
      //else show toaster about failed to remove
    } catch (err) {
      yield call(sentryErrorCatch, err, "#116");
      console.log("error in removeInvitationSaga", err);
      toast.error("Something went wrong #116", {
        className: "toast-danger",
      });
    }
  }
}

export function* unlinkSecretSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(teamsTabActions.unlinkSecret().type);

      const { csrfToken } = yield select(authSelector((state) => state));

      let result = yield call(
        api,
        `/organizations/${data.organization_id}/groups/${data.organization_group_id}/accounts/${data.id}`,
        "DELETE",
        null,
        null,
        csrfToken
      );

      if (result.success === true && result.organization_account) {
        let accountId = result.organization_account.id;
        yield putResolve(teamsTabActions.removeSecret(accountId));
        toast.success("Secret remove successfully!", {
          className: "toast-success",
        });
        data.history.go(-1);
      } else {
        toast.error("Failed to Remove", {
          className: "toast-danger",
        });
      }
      //else show toaster about failed to remove
    } catch (err) {
      yield call(sentryErrorCatch, err, "#117");
      console.log("error in unlinkSecretSaga", err);
      toast.error("Something went wrong #117", {
        className: "toast-danger",
      });
    }
  }
}
export function* setPasswordVisibilitySaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamsTabActions.setPasswordVisibility().type
      );

      const { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();

      form_data.append(
        "organization_group_account[password_visible]",
        data.flag
      );

      let result = yield call(
        api,
        `/organizations/${data.organization_id}/groups/${data.organization_group_id}/accounts/${data.id}.json`,
        "PUT",
        null,
        form_data,
        csrfToken
      );

      if (result.success === true && result.organization_group_account) {
        let flag = result.organization_group_account.password_visible;
        let id = result.organization_group_account.id;
        yield putResolve(
          teamsTabActions.changedPasswordVisibility({ id, flag })
        );
        if (result.organization_group_account.password_visible === true) {
          toast.success("Password visibility Enabled!", {
            className: "toast-success",
          });
        } else {
          toast.success("Password visibility Disabled!", {
            className: "toast-success",
          });
        }
      } else {
        toast.error("Failed to Change password visibility", {
          className: "toast-danger",
        });
      }
      //else show toaster about failed to remove
    } catch (err) {
      yield call(sentryErrorCatch, err, "#118");
      console.log("error in setPasswordVisibilitySaga", err);
      toast.error("Something went wrong #118", {
        className: "toast-danger",
      });
    }
  }
}

export function* removeUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(teamsTabActions.deleteUser().type);

      const { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();
      form_data.append("email", data.user.email);
      form_data.append("organization_id", data.team.organization_id);

      form_data.append("group_id", data.team.id);

      let result = yield call(
        api,
        `/organizations/${data.team.organization_id}/delete_user_from_group`,
        "POST",
        null,
        form_data,
        csrfToken
      );

      if (result === true) {
        if (data.userType === "users") {
          toast.success("User removed successfully!", {
            className: "toast-success",
          });
          yield putResolve(teamsTabActions.removeUser(data.user.id));
        } else {
          toast.success("Manager removed successfully!", {
            className: "toast-success",
          });
          yield putResolve(teamsTabActions.removeManager(data.user.id));
        }
        var user_me_data = yield call(getLatestUserData);
        yield putResolve(authActions.setMe(user_me_data));
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#119");
      console.log("error in removeUserSaga", err);
      toast.error("Something went wrong #119", {
        className: "toast-danger",
      });
    }
  }
}

export function* promoteToManagerSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamsTabActions.promoteToManager().type
      );

      const { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();
      form_data.append("organization_group_user[can_add_team_members]", true);

      let result = yield call(
        api,
        `/organization_group_users/${data.groupUser.id}.json`,
        "PUT",
        null,
        form_data,
        csrfToken
      );
      //result.flash_message: "USER has been promoted to MANAGER."

      if (result && result.organization_group_user) {
        toast.success(result.flash_message, {
          className: "toast-success",
        });
        yield putResolve(
          teamsTabActions.userPromotedToManager({
            user: data.user,
            organizationGroup: result.organization_group_user,
          })
        );
      }
      // show notification
      // }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#120");
      console.log("error in promoteToManagerSaga", err);
      toast.error("Something went wrong #120", {
        className: "toast-danger",
      });
    }
  }
}

export function* demoteToNormalUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamsTabActions.demoteToNormalUser().type
      );

      const { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();
      form_data.append(
        "organization_group_user[can_add_team_members]",
        "false"
      );

      let result = yield call(
        api,
        `/organization_group_users/${data.groupUser.id}.json`,
        "PUT",
        null,
        form_data,
        csrfToken
      );

      //result.flash_message: "USER has been promoted to MANAGER."

      if (result && result.organization_group_user) {
        toast.success(result.flash_message, {
          className: "toast-success",
        });
        yield putResolve(
          teamsTabActions.userDemotedToNormalUser({
            user: data.user,
            organizationGroup: result.organization_group_user,
          })
        );
      }
      // show notification
      // }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#121");
      console.log("error in demoteToNormalUserSaga", err);
      toast.error("Something went wrong #121", {
        className: "toast-danger",
      });
    }
  }
}
export function* deleteTeamSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(teamsTabActions.deleteTeam().type);
      const { csrfToken } = yield select(authSelector((state) => state));
      let result = yield call(
        api,
        `/organizations/${data.organization_id}/groups/${data.id}`,
        "DELETE",
        null,
        null,
        csrfToken
      );
      if (result.success === true) {
        const teams = yield select(
          teamsTabSelector((state) => state.currentCompany.teams)
        );
        let remainingTeams = teams.filter((team) => team.id !== data.id);

        const user_me_data = yield call(getLatestUserData);
        yield putResolve(authActions.setMe(user_me_data));
        toast.success("Team deleted successfully! ", {
          className: "toast-success",
        });
        yield putResolve(teamsTabActions.setTeamsLoading(false));
        if (remainingTeams && remainingTeams.length === 0) {
          yield putResolve(teamsTabActions.resetAddTeam());
          data.history.push(
            `/dashboard/companies/${data.organization_id}/team/create`
          );
        } else {
          yield putResolve(teamsTabActions.setActiveTab(remainingTeams[0].id));
          yield putResolve(
            teamsTabActions.companyLoadData({
              id: remainingTeams[0].id,
              organization_id: remainingTeams[0].organization_id,
              ref: "teamList",
            })
          );
          data.history.push(
            `/dashboard/companies/${data.organization_id}/teams/${remainingTeams[0].id}`
          );
        }

        yield putResolve(teamsTabActions.removeTeam(data.id));
      }
      // show notification
    } catch (err) {
      yield call(sentryErrorCatch, err, "#122");
      console.log("error in deleteTeamSaga", err);
      toast.error("Something went wrong #122", {
        className: "toast-danger",
      });
    }
  }
}

export function* updateTeamSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(teamsTabActions.updateTeam().type);

      const { csrfToken } = yield select(authSelector((state) => state));
      yield putResolve(teamsTabActions.setAddEditTeamLoading(true));
      const form_data = new FormData();
      form_data.append("organization_group[name]", data.newTeamName);

      let result = yield call(
        api,
        `/organizations/${data.organization_id}/groups/${data.id}.json`,
        "PUT",
        null,
        form_data,
        csrfToken
      );
      if (result.organization_id) {
        toast.success("Team updated successfully!", {
          className: "toast-success",
        });
        const user_me_data = yield call(getLatestUserData);
        yield putResolve(authActions.setMe(user_me_data));

        yield putResolve(teamsTabActions.setAddEditTeamLoading(false));
        yield putResolve(teamsTabActions.ChangedTeam(result));
        yield putResolve(teamsTabActions.setActiveTab(data.id));
        yield putResolve(
          teamsTabActions.companyLoadData({
            id: data.id,
            organization_id: data.organization_id,
            ref: "teamList",
          })
        );
        data.history.push(
          `/dashboard/companies/${data.organization_id}/teams/${data.id}`
        );
      }
      // show notification
    } catch (err) {
      yield call(sentryErrorCatch, err, "#123");
      console.log("error in updateTeamSaga", err);
      toast.error("Something went wrong #123", {
        className: "toast-danger",
      });
    }
  }
}
export function* addTeamSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(teamsTabActions.addTeam().type);

      const { csrfToken } = yield select(authSelector((state) => state));
      yield putResolve(teamsTabActions.setAddEditTeamLoading(true));

      // let organization_sym_key = get_org(user_data, data.id).organization_sym_key;
      let organization_sym_key = data.organization_sym_key;
      //decrypt

      var group_key = create_group_key();
      let org_sym_key;
      try {
        org_sym_key = decrypt(organization_sym_key, sym_key());
      } catch (error) {
        org_sym_key = decrypt(organization_sym_key, ""); //sym_key()
      }
      var group_key_encrypted = encrypt(group_key, org_sym_key);

      const form_data = new FormData();
      form_data.append("organization_group[name]", data.newTeamName);
      form_data.append(
        "organization_group[group_sym_key]",
        group_key_encrypted
      );
      form_data.append("organization_group[organization_id]", data.id);
      form_data.append("authenticity_token", csrfToken);
      form_data.append("utf8", true);

      let result = yield call(
        api,
        `/groups.html`,
        "POST",
        null,
        form_data,
        csrfToken
      );

      if (result.team_json) {
        toast.success("Team Added Successfully!", {
          className: "toast-success",
        });
        const user_me_data = yield call(getLatestUserData);
        yield putResolve(authActions.setMe(user_me_data));

        yield putResolve(teamsTabActions.setAddEditTeamLoading(false));
        yield putResolve(teamsTabActions.newTeam(result.team_json));
        yield putResolve(teamsTabActions.setActiveTab(result.team_json.id));
        yield putResolve(
          teamsTabActions.companyLoadData({
            id: result.team_json.id,
            organization_id: data.id,
            ref: "teamList",
          })
        );
        data.history.push(
          `/dashboard/companies/${data.id}/teams/${result.team_json.id}`
        );
      } else if (result.errors) {
        // Name has already been taken
        toast.error("Name has already been taken", {
          className: "toast-danger",
        });
        yield putResolve(teamsTabActions.setAddEditTeamLoading(false));

        yield putResolve(teamsTabActions.setAddTeamError(result.errors[0]));
      }

      // show notification
    } catch (err) {
      yield call(sentryErrorCatch, err, "#124");
      console.log("error in addTeamSaga", err);
      toast.error("Something went wrong #124", {
        className: "toast-danger",
      });
    }
  }
}

export function* inviteUsersSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(teamsTabActions.inviteUsers().type);
      yield putResolve(teamsTabActions.setInviteUserLoading(true));
      const { currentCompany } = yield select(
        teamsTabSelector((state) => state)
      );

      const { total_invites, subscribe_state } = currentCompany;

      let userLimitOver =
        currentCompany.subscribe_state === "block" ||
        currentCompany.subscribe_state === "no-users"
          ? true
          : false;

      const canInviteMore =
        total_invites &&
        subscribe_state === "trial" &&
        total_invites + data.invites.length > 4
          ? false
          : true;
      if (canInviteMore && !userLimitOver) {
        const { me, csrfToken } = yield select(authSelector((state) => state));
        let organization_id = data.team.organization_id;
        let invites = data.invites;
        let newInvitesSent = [];
        let errorLog = [];
        let dataInvites = [];
        let newInviteSent = false;
        for (let i = 0; i < invites.length; i++) {
          let invite = invites[i];
          let email = invite.value;

          let result = yield call(
            api,
            `/organizations/${organization_id}/pk/?email=${email}`,
            "GET",
            null,
            null,
            csrfToken
          );
          if (result) {
            var public_key_of_user = result.pk;
            let user_data = me;
            let team = data.team;
            let group_public_key_encrypted = "";
            var rsaPrivKey = "";
            var rsaPubKey = "";
            try {
              //getting group symkey
              var encrypted_group_key = get_group(
                user_data,
                team.id,
                organization_id
              ).symKey;
              //getting org symkey
              let organization_sym_key = get_org(
                user_data,
                organization_id
              ).organization_sym_key;

              //decrypting org sym
              let org_sym_key = decrypt(organization_sym_key, sym_key());

              //decrypting group sym
              var decrypted_group_key = decrypt(
                encrypted_group_key,
                org_sym_key
              );

              if (result.pk && result.pk.length > 0) {
                // existing user invitation
                if (result.asymmetric_key_algo === "RSA-OAEP") {
                  group_public_key_encrypted = yield subtleRSAEncrypt(
                    public_key_of_user,
                    decrypted_group_key
                  );
                } else {
                  group_public_key_encrypted = rsaEncrypt(
                    public_key_of_user,
                    decrypted_group_key
                  );
                }
              } else if (window.crypto && window.crypto.subtle) {
                // new user invitation
                let keyss = yield window.crypto.subtle.generateKey(
                  {
                    name: "RSA-OAEP",
                    modulusLength: 2048,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: "SHA-256",
                  },
                  true,
                  ["encrypt", "decrypt"]
                );
                rsaPubKey = JSON.stringify(
                  yield window.crypto.subtle.exportKey("jwk", keyss.publicKey)
                );
                rsaPrivKey = JSON.stringify(
                  yield window.crypto.subtle.exportKey("jwk", keyss.privateKey)
                );

                group_public_key_encrypted = yield subtleRSAEncrypt(
                  rsaPubKey,
                  decrypted_group_key
                );
              }
            } catch (e) {}
            const form_data = new FormData();
            form_data.append("invitation[email]", email);
            form_data.append(
              "invitation[group_public_key_encrypted]",
              group_public_key_encrypted
            );
            form_data.append("group_id", team.id);
            form_data.append("organization_id", team.organization_id);
            form_data.append("invitation[share_type]", "group");
            form_data.append("invitation[new_user_key]", rsaPrivKey);
            let inviteSend = yield call(
              api,
              `/invitations.json`,
              "POST",
              null,
              form_data,
              csrfToken
            );
            dataInvites = inviteSend;
            if (inviteSend.status === "created") {
              // toast.success("Invited  " + email + " successfully!", {
              //   className: "toast-success",
              // });
              newInviteSent = true;
              if (data.onBoardingProcess !== true) {
                newInvitesSent.push(inviteSend.invitation);
              }
            } else if (inviteSend.errors) {
              toast.error(inviteSend.errors[0] + " : " + email, {
                className: "toast-danger",
              });
              if (inviteSend.errors[0].includes("has access")) {
                errorLog.push(email);
              }
            }

            //invitesend.errors array
          }
        }

        if (newInvitesSent.length > 0) {
          yield putResolve(teamsTabActions.newInvitations(newInvitesSent));
        }
        if (errorLog.length) {
          yield putResolve(teamsTabActions.setInviteUserError(errorLog));
        } else if (dataInvites.errors) {
          yield putResolve(teamsTabActions.newInvitations(newInvitesSent));
        } else {
          if (rsaPrivKey) {
            // new user event
            window.dataLayer.push({
              event: "gtmEventClick",
              action: "Invited New User",
            });
          } else {
            // existing user event
            window.dataLayer.push({
              event: "gtmEventClick",
              action: "Invited Existing User",
            });
          }

          if (data.onBoardingProcess !== true) {
            yield putResolve(teamsTabActions.setActiveTab(data.team.id));
            yield putResolve(
              teamsTabActions.companyLoadData({ ...data.team, ref: "teamList" })
            );
          }
        }
        yield putResolve(teamsTabActions.setInviteUserLoading(false));
        yield putResolve(teamsTabActions.resetSelectedExistingInvites());
        yield putResolve(teamsTabActions.resetSelectedInvites());
        yield putResolve(teamsTabActions.setModalState(false));
        yield putResolve(
          teamsTabActions.companyLoadData({
            id: data.team.id,
            organization_id: organization_id,
            ref: "teamList",
          })
        );

        // open invitation modal
        if (newInviteSent) {
          yield putResolve(teamsTabActions.setInvitationSentModal(true));
        }

        // Check is from onBoarding step
        if (data.onBoardingProcess !== true) {
          data.history.push(
            `/dashboard/companies/${organization_id}/teams/${data.team.id}`
          );
        } else {
          let { selectedUserFromContactList } = yield select(
            teamsTabSelector((state) => state)
          );
          data.invites.forEach((contact) => {
            let email = contact.email;
            for (let i = 0; i < selectedUserFromContactList.length; i++) {
              selectedUserFromContactList[i].selected = true;
              if (selectedUserFromContactList[i].email === email) {
                selectedUserFromContactList.splice(i, 1);
              }
            }
          });
          yield putResolve(
            teamsTabActions.setSelectedUserFromContactList(
              selectedUserFromContactList
            )
          );
        }
      } else {
        yield putResolve(teamsTabActions.setModalState(false));
        yield putResolve(teamsTabActions.setBillingModalToggle(true));
        yield putResolve(teamsTabActions.setInviteUserLoading(false));
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#125");
      console.log("error in inviteUsersSaga", err);
      toast.error("Something went wrong #125", {
        className: "toast-danger",
      });
    }
  }
}

// Fetch contacts from backend
export function* fetchContactListSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamsTabActions.fetchContactList().type
      );
      yield putResolve(teamsTabActions.setContactLoading(true));
      let { currentCompany } = yield select(teamsTabSelector((state) => state));
      const { csrfToken, me } = yield select(authSelector((state) => state));

      let oauthToken = yield call(
        api,
        `/microsoft/${data.companyOwnerId}`,
        "GET",
        null,
        null,
        csrfToken
      );

      let result = yield call(
        api,
        `/contact/${data.companyOwnerId}`,
        "GET",
        null,
        null,
        csrfToken
      );

      // check oauth tokn is avl or not
      if (oauthToken.success) {
        yield putResolve(teamsTabActions.setOauthAccountConnected(true));
      }

      // contacts
      if (result.success) {
        if (result.contacts.length === 0) {
          yield putResolve(teamsTabActions.setContactsNotFound(true));
        }

        let contacts = result.contacts.map((user) => {
          user.selected = true;
          return user;
        });

        // Remove duplicate
        contacts = contacts.reduce((contact, current) => {
          const x = contact.find((item) => item.email === current.email);
          if (!x) {
            return contact.concat([current]);
          } else {
            return contact;
          }
        }, []);

        // Remove already invited user email from the list
        const invites = currentCompany.org_invites
          ? currentCompany.org_invites.filter((invite) => {
              if (invite.group_id === parseInt(data.teamId)) {
                return invite.email;
              } else {
                return false;
              }
            })
          : [];

        if (invites.length >= 1) {
          invites.forEach((contact) => {
            let email = contact.email;
            for (let i = 0; i < contacts.length; i++) {
              if (contacts[i].email === email) {
                contacts.splice(i, 1);
              }
            }
          });
        }

        if (
          currentCompany.organization_group_users &&
          currentCompany.organization_group_users.length >= 1
        ) {
          currentCompany.organization_group_users.forEach((contact) => {
            let email = contact.email;
            for (let i = 0; i < contacts.length; i++) {
              if (contacts[i].email === email) {
                contacts.splice(i, 1);
              }
            }
          });
        }

        yield putResolve(
          teamsTabActions.setSelectedUserFromContactList(contacts)
        );
      }
      yield putResolve(teamsTabActions.setContactLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#240");
      console.log("error in fetchContactListSaga", err);
      toast.error("Something went wrong #240", {
        className: "toast-danger",
      });
    }
  }
}

export function* getAllContactsFromGoogleAndSaveSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        teamsTabActions.getAllContactsFromGoogleAndSave().type
      );
      yield putResolve(teamsTabActions.setContactLoading(true));

      const { csrfToken } = yield select(authSelector((state) => state));

      let getGoogleContacts = yield call(
        userContacts,
        `https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses&access_token=${data.accessToken}`
      );

      let getGoogleOtherContacts = yield call(
        userContacts,
        `https://people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses&access_token=${data.accessToken}`
      );

      let contacts = [];

      getGoogleContacts &&
        getGoogleContacts.connections &&
        getGoogleContacts.connections.forEach(({ emailAddresses, names }) => {
          if (
            emailAddresses &&
            emailAddresses[0].value &&
            emailAddresses[0].value.length
          ) {
            contacts.push({
              name: names ? names[0].displayName : null,
              email: emailAddresses[0].value,
            });
          }
        });

      getGoogleOtherContacts &&
        getGoogleOtherContacts.otherContacts &&
        getGoogleOtherContacts.otherContacts.forEach(
          ({ emailAddresses, names }) => {
            if (
              emailAddresses &&
              emailAddresses[0].value &&
              emailAddresses[0].value.length
            ) {
              contacts.push({
                name: names ? names[0].displayName : null,
                email: emailAddresses[0].value,
              });
            }
          }
        );

      if (contacts.length === 0) {
        yield putResolve(teamsTabActions.setContactsNotFound(true));
      }
      // Save contacts to database
      yield call(saveContacts, contacts, data, csrfToken);

      contacts = contacts.map((user) => {
        user.selected = true;
        return user;
      });

      // Remove duplicate
      contacts = contacts.reduce((contact, current) => {
        const x = contact.find((item) => item.email === current.email);
        if (!x) {
          return contact.concat([current]);
        } else {
          return contact;
        }
      }, []);

      let { currentCompany } = yield select(teamsTabSelector((state) => state));

      // Remove already invited user email from the list
      const invites = currentCompany.org_invites
        ? currentCompany.org_invites.filter((invite) => {
            if (invite.group_id === parseInt(data.teamId)) {
              return invite.email;
            } else {
              return false;
            }
          })
        : [];

      if (invites.length >= 1) {
        invites.forEach((contact) => {
          let email = contact.email;
          for (let i = 0; i < contacts.length; i++) {
            if (contacts[i].email === email) {
              contacts.splice(i, 1);
            }
          }
        });
      }

      if (
        currentCompany.organization_group_users &&
        currentCompany.organization_group_users.length >= 1
      ) {
        currentCompany.organization_group_users.forEach((contact) => {
          let email = contact.email;
          for (let i = 0; i < contacts.length; i++) {
            if (contacts[i].email === email) {
              contacts.splice(i, 1);
            }
          }
        });
      }
      yield putResolve(
        teamsTabActions.setSelectedUserFromContactList(contacts)
      );
      yield putResolve(teamsTabActions.setOauthAccountConnected(true));

      yield putResolve(teamsTabActions.setContactLoading(false));
    } catch (err) {
      yield putResolve(teamsTabActions.setContactLoading(false));
      yield call(sentryErrorCatch, err, "#237");
      console.log("error in fetchContactListSaga", err);
      toast.error("Something went wrong #237", {
        className: "toast-danger",
      });
    }
  }
}

function* saveContacts(contacts, data, csrfToken) {
  try {
    // save contacts to database
    const form_data = new FormData();
    form_data.append("google_contacts", JSON.stringify(contacts));
    form_data.append("source", "google");
    form_data.append("oauth_refreshToken", data.refreshToken);
    form_data.append("user_login_email", data.userLoginEmail);
    let response = yield call(
      api,
      `/contact.json`,
      "POST",
      null,
      form_data,
      csrfToken
    );
  } catch (err) {
    yield call(sentryErrorCatch, err, "#238");
    console.log("error in saveContacts", err);
    toast.error("Something went wrong #238", {
      className: "toast-danger",
    });
  }
}

async function userContacts(url) {
  try {
    const response = await fetch(url, {
      method: "GET",
    });
    return response.json();
  } catch (err) {
    sentryErrorCatch(err, "#239");
    console.log("error in userContacts", err);
    toast.error("Something went wrong #239", {
      className: "toast-danger",
    });
  }
}

function* getMicrosoftRefreshTokenSaga() {
  while (true) {
    try {
      const { payload: code } = yield take(
        teamsTabActions.getMicrosoftRefreshToken().type
      );
      yield putResolve(teamsTabActions.setTokenLoading(true));
      const { csrfToken } = yield select(authSelector((state) => state));
      let formData = new FormData();

      formData.append("code", code);
      formData.append("ref", "fromInviteUserPage");

      const result = yield call(
        api,
        `/microsoft_refresh_token.json`,
        "POST",
        null,
        formData,
        null
      );

      if (result.tokens.refresh_token) {
        // save refresh token to database
        const form_data = new FormData();
        form_data.append("source", "microsoft");
        form_data.append("microsoftRefreshToken", result.tokens.refresh_token);
        form_data.append("ref", "fromInviteUserPage");
        let response = yield call(
          api,
          `/contact`,
          "POST",
          null,
          form_data,
          csrfToken
        );
        if (response.success === true) {
          yield putResolve(teamsTabActions.setTokenLoading(false));
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#241");
      toast.error("Something Went Wrong #241", {
        className: "toast-danger",
      });
      console.log("err in getMicrosoftRefreshTokenSaga", err);
    }
  }
}

export default function* companyTeamTabPageRootSaga() {
  yield spawn(companyLoadPageSaga);
  yield spawn(removeInvitationSaga);
  yield spawn(unlinkSecretSaga);
  yield spawn(setPasswordVisibilitySaga);
  yield spawn(removeUserSaga);
  yield spawn(promoteToManagerSaga);
  yield spawn(demoteToNormalUserSaga);
  yield spawn(deleteTeamSaga);
  yield spawn(updateTeamSaga);
  yield spawn(addTeamSaga);
  yield spawn(inviteUsersSaga);
  yield spawn(fetchContactListSaga);
  yield spawn(getAllContactsFromGoogleAndSaveSaga);
  yield spawn(getMicrosoftRefreshTokenSaga);
}
