import * as Sentry from "@sentry/browser";
import { select } from "redux-saga/effects";
import { authSelector } from "../pages/auth/store";

function* sentryErrorCatch(error, errCode, data = "") {
  const { me } = yield select(authSelector((state) => state));
  let allCookies = document.cookie;
  Sentry.withScope(function (scope) {
    scope.setTag("error-code", errCode);
    scope.setUser({
      email: me && me.user && me.user.email ? me.user.email : "",
    });
    scope.setExtra("data", data);
    scope.setExtra("Cookies", allCookies);
    Sentry.captureException(error);
  });
}

export default sentryErrorCatch;
