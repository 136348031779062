// @flow
import { all } from "redux-saga/effects";
import loginUserRootSaga from "../pages/auth/login-page/saga";
import registerPageRootSaga from "../pages/auth/register-page/saga";
import authSagaRootSaga from "../pages/auth/saga";
import ForgotPasswordPageRootSaga from "../pages/auth/forgotten-password/saga";
import ResetPasswordPageRootSaga from "../pages/auth/forgotten-password/reset-password/saga";
import confirmationEmailPageRootSaga from "../pages/auth/confirmation-email/saga";
import dashboardRootSaga from "../pages/dashboard-page/saga";
import createCompanyPageRootSaga from "../pages/dashboard-page/create-company-page/saga";
import companyTeamTabPageRootSaga from "../pages/company-page/teams-tab/saga";
import personalSecretPageRootSaga from "../pages/personal-secrets-page/saga";
import personalSecretDetailsRootSaga from "../pages/personal-secrets-page/personal-secret-details-page/saga";

import secretTabPageRootSaga from "../pages/company-page/secret-tab/saga";
import secretTabDetailsRootSaga from "../pages/company-page/secret-tab/secret-tab-details-page/saga";
import dummyRootSaga from "../components/secrets/saga";
import personalSecretAddRootSaga from "../pages/personal-secrets-page/personal-secret-add/saga";
import SecretTabAddRootSaga from "../pages/company-page/teams-tab/secret-team-add-page/saga";
import userPageRootSaga from "../pages/company-page/users-tab/saga";
import billingPageRootSaga from "../pages/company-page/billing-tab/saga";
import ignoredSitesPageRootSaga from "../pages/ignored-lists-page/saga";
import settingPageRootSaga from "../pages/user-setting-page/saga";
import twoFactorAuthRootSaga from "../pages/user-setting-page/twoFactorAuth/saga";
import viewDiagnosticInfoRootSaga from "../pages/user-setting-page/diagnosticInfo/saga";
import settingsTabPageRootSaga from "../pages/company-page/setting-tab/saga";
import editDeleteSecretTeamTabRootSaga from "../pages/company-page/teams-tab/secret-team-details-page/saga";
import billingChangePlanPageRootSaga from "../pages/company-page/billing-tab/change-plan/saga";
import addSecretStepRootSaga from "../pages/company-page/add-secret-step/saga";
//import selectPlanPageRootSaga from "../pages/company-page/select-plan/saga";
export default function* rootSaga() {
  yield all([
    loginUserRootSaga(),
    registerPageRootSaga(),
    authSagaRootSaga(),
    ForgotPasswordPageRootSaga(),
    ResetPasswordPageRootSaga(),
    confirmationEmailPageRootSaga(),
    dashboardRootSaga(),
    createCompanyPageRootSaga(),
    companyTeamTabPageRootSaga(),
    personalSecretPageRootSaga(),
    personalSecretAddRootSaga(),
    personalSecretDetailsRootSaga(),
    billingPageRootSaga(),
    secretTabPageRootSaga(),
    secretTabDetailsRootSaga(),
    dummyRootSaga(),
    SecretTabAddRootSaga(),
    userPageRootSaga(),
    ignoredSitesPageRootSaga(),
    settingPageRootSaga(),
    twoFactorAuthRootSaga(),
    viewDiagnosticInfoRootSaga(),
    settingsTabPageRootSaga(),
    editDeleteSecretTeamTabRootSaga(),
    billingChangePlanPageRootSaga(),
    addSecretStepRootSaga(),
    //selectPlanPageRootSaga(),
  ]);
}
