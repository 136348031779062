export function randomNumber() {
  return Math.random() * 9999;
}
export const sortBy = (sortType) => (a, b) => {
  if (sortType === true) return a > b ? 1 : b > a ? -1 : 0;
  if (sortType === false) return a > b ? -1 : b > a ? 1 : 0;
};

//data-->feildName, sortBy(String or number)

export const sorting = (arr, data) => {
  arr.sort((a, b) => {
    if (data.sortBy === "STRING") {
      let aName = a[data.fieldName].toLowerCase();
      let bName = b[data.fieldName].toLowerCase();
      return sortBy(data.sortType)(aName, bName);
    }
    if (data.sortBy === "INTEGER") {
      let aInteger = parseInt(a[data.fieldName]);
      let bInteger = parseInt(b[data.fieldName]);
      return sortBy(data.sortType)(aInteger, bInteger);
    }
    if (data.sortBy === "DATE") {
      let aDate = new Date(a[data.fieldName]);
      let bDate = new Date(b[data.fieldName]);
      return sortBy(data.sortType)(aDate, bDate);
    }
    if (data.sortBy === "FLOAT") {
      let aFloat = parseFloat(a[data.fieldName]);
      let bFloat = parseFloat(b[data.fieldName]);
      return sortBy(data.sortType)(aFloat, bFloat);
    }
  });
  return arr;
};

export const search = (arr) => (searchText) => (searchField) => {
  let str = "";
  let filterArray = [];
  arr.forEach((el) => {
    for (const [key] of Object.entries(el)) {
      if (!searchField.includes(key)) {
        continue;
      }
      if (typeof el[key] === "string") {
        str = el[key].toLowerCase();
      }
      if (typeof el[key] === "number") {
        str = el[key].toString();
      }
      if (el[key] instanceof Date) {
        str = el[key].toString();
      }
      if (
        typeof el[key] === "string" ||
        typeof el[key] === "number" ||
        el[key] instanceof Date
      ) {
        if (str.indexOf(searchText.toLowerCase()) !== -1) {
          filterArray.push(el);
          break;
        }
      }
    }
  });
  return filterArray;
};
export function subDays(date, days) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - days,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  )
    .toLocaleString()
    .split(",")[0];
}
export function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = month + " " + date + ", " + year;
  return time;
}
