import { state } from "../../../store";

export const [
  ForgotPasswordPageActions,
  ForgotPasswordPageSelector,
  ForgotPasswordPageState,
] = state("ForgotPasswordPage", {
  initial: {
    email: "",
    error: "",
    resetAccountBtnLoading: false,
  },
  on: {
    inputChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      return { ...newStoreObj };
    },
    setError: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, error: data };
    },
    setResetAccountBtnLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return { ...newStoreObj, resetAccountBtnLoading: data };
    },
  },
  events: ["submitForgotPassword"],
});
