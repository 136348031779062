import { putResolve, take, call, spawn, select } from "redux-saga/effects";
import { dashboardPageActions } from "./store";
import { authSelector, authActions } from "../auth/store";
import {
  sym_key,
  encrypt,
  decrypt,
  myRSAGetPriKeyDecryptedAsync,
  process_accounts_in_groups_only,
  sym_key_by_org_id_new,
  rsaEncrypt,
  rsaDecrypt,
  getLatestUserData,
  subtleRSADecrypt,
  subtleRSAEncrypt,
} from "../../helpers/apiUtils";
import { api } from "../../helpers/api";
import { toast } from "react-toastify";
import { get_g_s } from "../../helpers/session";
import sentryErrorCatch from "../../helpers/sentryUtils";

export function* acceptInvitationPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        dashboardPageActions.acceptInvitation().type
      );
      const { csrfToken } = yield select(authSelector((state) => state));

      let result = yield call(
        api,
        `/invitations/${data.id}/accept`,
        null,
        null,
        null,
        csrfToken
      );

      let mineToProcessResult = yield call(
        api,
        `/invitations/mine_to_process`,
        null,
        null,
        null,
        csrfToken
      );
      if (
        data.group_public_key_encrypted === "" ||
        data.group_public_key_encrypted === null ||
        (mineToProcessResult.asymmetric_key_algo !== "RSA-OAEP" &&
          data.group_public_key_encrypted &&
          data.new_user_key)
      ) {
        // accept newly resgister user invitation
        window.dataLayer.push({
          event: "gtmEventClick",
          action: "Accepted New User old Invitation",
        });
        yield putResolve(authActions.setUserFromMe());
      } else {
        // accept existing users invitation
        if (
          mineToProcessResult &&
          mineToProcessResult.invitations &&
          mineToProcessResult.invitations.length > 0
        ) {
          var rsa_private_key = decrypt(
            mineToProcessResult.private_key_encrypted,
            sym_key()
          );

          let invitations = mineToProcessResult.invitations[0];
          let organization_id = invitations.organization_id;
          let group_id = invitations.group_id;
          let decrypted_key = "";

          if (mineToProcessResult.asymmetric_key_algo === "RSA-OAEP") {
            if (data.group_public_key_encrypted && data.new_user_key) {
              // accept new user invitation with new_user_key
              decrypted_key = yield subtleRSADecrypt(
                data.new_user_key,
                data.group_public_key_encrypted
              );
            } else {
              // accept existing user invitation
              decrypted_key = yield subtleRSADecrypt(
                rsa_private_key,
                invitations.group_public_key_encrypted
              );
            }
          } else {
            decrypted_key = yield call(
              myRSAGetPriKeyDecryptedAsync,
              rsa_private_key,
              invitations.group_public_key_encrypted
            );
          }
          let symmetric_user_key_encrypted = encrypt(decrypted_key, sym_key());

          const form_data = new FormData();
          form_data.append(
            "organization_group_user[organization_group_id]",
            group_id
          );
          form_data.append("organization_id", organization_id);
          form_data.append(
            "organization_group_user[symmetric_user_key_encrypted]",
            symmetric_user_key_encrypted
          );

          let addUserToGroupResult = yield call(
            api,
            `/organizations/${organization_id}/addusertogroup`,
            "POST",
            null,
            form_data,
            csrfToken
          );

          if (addUserToGroupResult && addUserToGroupResult.success === true) {
            if (data.new_user_key) {
              window.dataLayer.push({
                event: "gtmEventClick",
                action: "Accepted New User New Invitation",
              });
            } else {
              window.dataLayer.push({
                event: "gtmEventClick",
                action: "Accepted Existing User Invitation",
              });
            }
            //show notification
            yield putResolve(authActions.setUserFromMe());
            toast.success(addUserToGroupResult.message, {
              className: "toast-success",
            });
          }
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#169");
      console.log("error in acceptInvitationPageSaga", err);
      toast.error("Something Went Wrong #169", {
        className: "toast-danger",
      });
    }
  }
}

export function* acceptOwnershipPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        dashboardPageActions.acceptOwnershipInvitationHandler().type
      );

      const { csrfToken } = yield select(authSelector((state) => state));
      let me = yield call(getLatestUserData);
      let private_key_encrypted = me.user.private_key_encrypted;

      let private_key = decrypt(private_key_encrypted, sym_key());
      let decrypted_key = "";
      if (me.user.asymmetric_key_algo === "RSA-OAEP") {
        decrypted_key = yield subtleRSADecrypt(
          private_key,
          data.organization_key_encrypted
        );
      } else {
        decrypted_key = yield call(
          myRSAGetPriKeyDecryptedAsync,
          private_key,
          data.organization_key_encrypted
        );
      }
      let final_encrypted_organization_key = encrypt(decrypted_key, sym_key());
      final_encrypted_organization_key = encodeURIComponent(
        final_encrypted_organization_key
      );

      let result = yield call(
        api,
        `/organizations/${data.org_id}/transfer_accept?org_key=${final_encrypted_organization_key}&notification_id=${data.notification_id}`,
        "GET",
        null,
        null,
        csrfToken
      );

      const mineToProcess = yield call(
        api,
        `/invitations/mine_to_process`,
        "GET",
        null,
        null,
        result.csrfToken
      );

      yield call(process_accounts_in_groups_only, me);

      let update_invites_for_new_users = yield call(
        api,
        `/invitations/confirm_for_new_commonkey_users`,
        "GET",
        null,
        null,
        csrfToken
      );

      if (update_invites_for_new_users) {
        for (var i = 0; i < data.length; i++) {
          var invite = data[i];
          var organization_public_key_encrypted = null;
          var group_public_key_encrypted = null;
          if (invite.invitation.share_type == "organization") {
            var decrypted_org_key = decrypt(
              sym_key_by_org_id_new(invite.invitation.organization_id, me),
              sym_key()
            );
            if (me.user.asymmetric_key_algo === "RSA-OAEP") {
              organization_public_key_encrypted = yield subtleRSAEncrypt(
                invite.user.public_key,
                decrypted_org_key
              );
            } else {
              organization_public_key_encrypted = rsaEncrypt(
                invite.user.public_key,
                decrypted_org_key
              );
            }
          } else {
            var decrypted_group_key = yield call(
              get_g_s,
              invite.invitation.organization_id,
              invite.invitation.group_id
            );
            if (me.user.asymmetric_key_algo === "RSA-OAEP") {
              group_public_key_encrypted = yield subtleRSAEncrypt(
                invite.user.public_key,
                decrypted_group_key
              );
            } else {
              group_public_key_encrypted = rsaEncrypt(
                invite.user.public_key,
                decrypted_group_key
              );
            }
          }
          const invite_form_data = new FormData();

          invite_form_data.append(
            "invitation[group_public_key_encrypted]",
            group_public_key_encrypted
          );
          invite_form_data.append(
            "invitation[organization_public_key_encrypted]",
            organization_public_key_encrypted
          );

          let invitation = yield call(
            api,
            `/invitations/${invite.invitation.id}`,
            "PUT",
            null,
            invite_form_data,
            csrfToken
          );
        }
      }

      if (result) {
        if (result.status === "already accepted") {
          //show in notification
          toast.error("Already Accepted!", {
            className: "toast-danger",
          });
        } else if (result.status === "successfully") {
          // show notification  > updated ownership
          toast.success("Ownership Updated!", {
            className: "toast-success",
          });
          yield putResolve(authActions.setMe(me));
          yield putResolve(authActions.setUserFromMe());
        } else if (result.status === "expired") {
          //show notification invitation expired
          toast.error("Invitation Expired!", {
            className: "toast-danger",
          });
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#170");
      console.log("error in acceptOwnershipPageSaga", err);
      toast.error("Something Went Wrong #170", {
        className: "toast-danger",
      });
    }
  }
}

export function* dontRemindAgainSaga() {
  while (true) {
    try {
      yield take(dashboardPageActions.dontShow().type);

      const { csrfToken } = yield select(authSelector((state) => state));

      let result = yield call(
        api,
        `/me/install_extension.json`,
        "POST",
        null,
        null,
        csrfToken
      );
    } catch (err) {
      yield call(sentryErrorCatch, err, "#171");
      console.log("error in dontRemindAgainSaga", err);
      toast.error("Something Went Wrong #171", {
        className: "toast-danger",
      });
    }
  }
}

export default function* dashboardRootSaga() {
  yield spawn(acceptInvitationPageSaga);
  yield spawn(acceptOwnershipPageSaga);
  yield spawn(dontRemindAgainSaga);
  //yield spawn(loadExtensionInstalledValueSaga);
}
