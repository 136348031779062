import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { billingActions } from "./store";
import { teamsTabActions } from "../teams-tab/store";
import { api } from "../../../helpers/api";
import { authSelector, authActions } from "../../auth/store";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../helpers/sentryUtils";

function* billingPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(billingActions.loadData().type);
      yield putResolve(billingActions.setLoading(true));

      let result = yield call(
        api,
        `/dashboard/companies/${data.companyid}/billing.json`
      );

      let receipts = yield call(
        api,
        `/dashboard/companies/${data.companyid}/billing/receipts.json`
      );
      if (receipts && receipts.billing_charges) {
        yield putResolve(
          billingActions.setInvoicesList(receipts.billing_charges)
        );
      }
      if (result) {
        yield putResolve(billingActions.loadBillingData(result));
      }

      yield putResolve(billingActions.setLoading(false));
    } catch (err) {
      yield call(sentryErrorCatch, err, "#155");
      console.log("error in billingPageSaga", err);
      toast.error("Something Went Wrong #155", {
        className: "toast-danger",
      });
    }
  }
}
function* subscribePlanSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(billingActions.subscribePlan().type);
      if (data.ref === "applyDiscount") {
        yield putResolve(billingActions.setApplyCouponLoading(true));
      }
      const { csrfToken } = yield select(authSelector((state) => state));
      if (data.savedCard === false) {
        const form_data = new FormData();

        if (data.discountText) {
          form_data.append("coupon_code", data.discountText);
        } else {
          form_data.append("stripe_plan_id", data.stripe_plan_name);
        }

        let result = yield call(
          api,
          `/dashboard/companies/${data.organizationId}/billing/stripe_checkout_sessions.json`,
          "POST",
          null,
          form_data,
          csrfToken
        );

        if (result && result.stripe_checkout_session_id) {
          localStorage.setItem("subscribeAmount", data.monthly_cost);

          const stripePromise = loadStripe(data.stripe_pk);
          const stripe = yield stripePromise;
          stripe
            .redirectToCheckout({
              sessionId: result.stripe_checkout_session_id,
            })
            .then(function (stripeResult) {
              console.log(
                "error in subscribePlanSaga",
                stripeResult.error.message
              );
            });
        }
      } else {
        let stripe_plan_id = data.stripe_plan_name; //subscribedPlanInfo.stripe_plan_id;
        const subscriptionFormData = new FormData();

        if (data.discountText) {
          subscriptionFormData.append("coupon", data.discountText);
        } else {
          subscriptionFormData.append("stripe_plan_id", stripe_plan_id);
        }
        let subscriptionResult = yield call(
          api,
          `/organizations/${data.organizationId}/subscriptions.json`,
          "POST",
          null,
          subscriptionFormData,
          csrfToken
        );
        yield putResolve(
          billingActions.setPlanLoading({ planName: data.name, status: false })
        );

        if (data.ref === "applyDiscount") {
          yield putResolve(billingActions.setApplyCouponLoading(false));
        }

        if (subscriptionResult.success === true) {
          window.dataLayer.push({
            event: "gtmEventClick",
            action: "Subscribed Plan",
            subscribedPlanName: stripe_plan_id,
            subscribedAmount: data.monthly_cost,
          });
          window.dataLayer.push({
            event: "GAdsConversionEvent",
          });
          if (data.name === "Team Password Management") {
            yield putResolve(teamsTabActions.setSubscribeState("subscribed"));
          }
          yield putResolve(
            billingActions.loadData({ companyid: data.organizationId })
          );
          //show notification
          toast.success(subscriptionResult.message, {
            className: "toast-success",
          });
        } else {
          //show notification
          toast.error(subscriptionResult.message, {
            className: "toast-danger",
          });
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#156");
      console.log("error in subscribePlanSaga", err);
      toast.error("Something Went Wrong #156", {
        className: "toast-danger",
      });
    }
  }
}

function* unsubscribePlanSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        billingActions.unsubscribePlan().type
      );

      const { csrfToken } = yield select(authSelector((state) => state));
      let stripe_plan_id = data.subscribedPlanInfo.stripe_plan_id;

      let result = yield call(
        api,
        `/organizations/${data.organizationId}/subscriptions/${stripe_plan_id}`,
        "DELETE",
        null,
        null,
        csrfToken
      );
      yield putResolve(
        billingActions.setPlanLoading({ planName: data.name, status: false })
      );

      if (result.success === true) {
        window.dataLayer.push({
          event: "gtmEventClick",
          action: "Unsubscribed Plan ",
          subscribedPlanName: stripe_plan_id,
        });
        toast.success(result.message, {
          className: "toast-success",
        });
        yield putResolve(
          billingActions.loadData({ companyid: data.organizationId })
        );
      } else {
        toast.error(result.message, {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#157");
      console.log("error in unsubscribePlanSaga", err);
      toast.error("Something Went Wrong #157", {
        className: "toast-danger",
      });
    }
  }
}

function* stripeSuccessSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(billingActions.stripeSuccess().type);
      const { csrfToken } = yield select(authSelector((state) => state));

      let result = yield call(api, data.url, "GET", null, null, csrfToken);
      if (result.success === true) {
        if (result.subscription) {
          let subscribeAmount = localStorage.getItem("subscribeAmount");

          window.dataLayer.push({
            event: "gtmEventClick",
            action: "Subscribed Plan successfully:",
            subscribedAmount: subscribeAmount,
            subscribedPlanName: result.subscription.stripe_plan_id,
          });
          window.dataLayer.push({
            event: "GAdsConversionEvent",
          });
          localStorage.removeItem("subscribeAmount");
        }
        if (data.ref && data.groupid && data.ref === "create-company") {
          // data.history.push(
          //   `/dashboard/companies/${result.organization.id}/teams/${data.groupid}`
          // );
          data.history.push(
            `/dashboard/companies/${result.organization.id}/teams/${data.groupid}/step1/add-secrets`
          );
        } else {
          data.history.push(
            `/dashboard/companies/${result.organization.id}/billing`
          );
        }
      } else {
        // data.history.push(`/dashboard/companies/${result.organization.id}/billing`)
      }
      // yield putResolve(teamsTabActions.setOrganization(result.organization))
    } catch (err) {
      yield call(sentryErrorCatch, err, "#158");
      console.log("error in stripeSuccessSaga", err);
      toast.error("Something Went Wrong #158", {
        className: "toast-danger",
      });
    }
  }
}
function* updateCreditCardSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        billingActions.updateCreditCard().type
      );
      const { csrfToken } = yield select(authSelector((state) => state));

      let result = yield call(
        api,
        `/dashboard/companies/${data.organizationId}/billing/stripe_checkout_sessions.json`,
        "POST",
        null,
        null,
        csrfToken
      );

      if (result && result.stripe_checkout_session_id) {
        const stripePromise = loadStripe(data.stripe_pk);
        const stripe = yield stripePromise;
        stripe
          .redirectToCheckout({
            sessionId: result.stripe_checkout_session_id,
          })
          .then(function (stripeResult) {
            console.log(
              "error in updateCreditCardSaga",
              stripeResult.error.message
            );
          });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#159");
      console.log("error in updateCreditCard", err);
      toast.error("Something Went Wrong #159", {
        className: "toast-danger",
      });
    }
  }
}
export default function* billingPageRootSaga() {
  yield spawn(billingPageSaga);
  yield spawn(subscribePlanSaga);
  yield spawn(unsubscribePlanSaga);
  yield spawn(stripeSuccessSaga);
  yield spawn(updateCreditCardSaga);
}
