import CryptoJS from "crypto-js";
import { api } from "./api";
import { call, putResolve, select } from "redux-saga/effects";

import { get_org, get_group } from "../helpers/ckService/UserDataHelper";
import {
  decryptObjectMarkingCorrupt,
  decrypt as encryptDecrypt,
} from "../helpers/ckService/EncryptionHelper";
import { ckServiceSymKey } from "../helpers/ckService/AccountEncryptionRules";
import { teamsTabSelector } from "../pages/company-page/teams-tab/store";
import { organizationSecretDetailsSelector } from "../pages/company-page/secret-tab/secret-tab-details-page/store";
import { authSelector } from "../pages/auth/store";
import {
  convert_encrypt_to_decrypt_based_on_type,
  delete_param_for_org_grp_account,
} from "./organization";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/browser";
import sentryErrorCatch from "./sentryUtils";
import { userActions } from "../pages/company-page/users-tab/store";
//import { get_g_s } from "./session";

export function getGravatar(email) {
  let options = { size: 50 };
  let gravatar_id = CryptoJS.MD5(email);
  let gravatar_url = `https://secure.gravatar.com/avatar/${gravatar_id}?s=${options["size"]}`;

  return gravatar_url;
}

// same as commonkey.rsa.decrypt
export async function myRSAGetPriKeyDecryptedAsync(rsa_private_key, cipher) {
  let keyss = null;

  keyss = await window.myRSAManager.getPriKeyDecryptedAsync(
    rsa_private_key,
    cipher
  );

  return keyss;
}

export async function subtleRSADecrypt(rsa_private_key, data) {
  var binary_string = window.atob(data);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  let group_public_key_encoded = bytes.buffer;

  let private_key = await window.crypto.subtle.importKey(
    "jwk",
    JSON.parse(rsa_private_key),
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    },
    false,
    ["decrypt"]
  );
  let decrypted_key_encoded = await window.crypto.subtle.decrypt(
    {
      name: "RSA-OAEP",
    },
    private_key,
    group_public_key_encoded
  );
  let dec = new TextDecoder("utf-8");
  return dec.decode(decrypted_key_encoded);
}

export function rsaDecrypt(rsa_private_key, cipher, callback) {
  window.myRSAManager.getPriKeyDecryptedAsync(
    rsa_private_key,
    cipher,
    function (error, value) {
      // value = original string
      return value;
    }
  );
}

export function rsaEncrypt(rsa_public_key, string) {
  return window.myRSAManager.getPubKeyEncrypted(rsa_public_key, string);
}

export async function subtleRSAEncrypt(public_key_of_user, data) {
  let enc = new TextEncoder();
  let decrypted_group_key_encoded = enc.encode(data);
  let publicKey = await window.crypto.subtle.importKey(
    "jwk",
    JSON.parse(public_key_of_user),
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    },
    false,
    ["encrypt"]
  );
  let group_public_key_encrypted_encoded = await window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    publicKey,
    decrypted_group_key_encoded
  );
  var binary = "";
  var bytes = new Uint8Array(group_public_key_encrypted_encoded);
  var len = bytes.byteLength;
  for (var j = 0; j < len; j++) {
    binary += String.fromCharCode(bytes[j]);
  }
  return window.btoa(binary);
}

export function create_group_key() {
  var salt1 = CryptoJS.lib.WordArray.random(128 / 8);
  var password = CryptoJS.lib.WordArray.random(128 / 8);
  // Generate new symmetric AES key from password and salt1 using PBKDF2
  var group_key = CryptoJS.PBKDF2(password, salt1, {
    keySize: 256 / 32,
    iterations: 500,
  }).toString();
  return group_key;
}

export function* getLatestUserData() {
  var localMe = JSON.parse(localStorage.getItem("me"));
  try {
    if (check_sym()) {
      const user_me_data = yield call(api, `/me/me_v2.json`);
      if (
        !user_me_data.hasOwnProperty("error_type") &&
        user_me_data.hasOwnProperty("user") &&
        localMe.email === user_me_data.user.email
      ) {
        let organizations = Object.values(user_me_data.orgs_by_id);
        if (organizations && organizations.length > 0) {
          let orgs = organizations.map((org) => {
            let role;
            if (org.owner === true && org.admin === true) {
              role = "owner";
            } else if (org.owner === false && org.admin === true) {
              role = "admin";
            }
            if (org.groups && org.groups.length > 0) {
              org.groups = org.groups.map((group) => {
                if (role) {
                  return { ...group, role: role };
                }
                if (group.can_add_team_members === true) {
                  return { ...group, role: "manager" };
                } else {
                  return { ...group, role: "member" };
                }
              });
              let isManager = org.groups.some((group) =>
                group.can_add_team_members === true ? true : false
              );
              if (isManager) {
                role = "manager";
              } else {
                role = "member";
              }
            }
            org.role = role;
            return org;
          });
          user_me_data["orgs"] = orgs;
        }
        return user_me_data;
      } else {
        if (user_me_data.hasOwnProperty("error_type")) {
          return user_me_data;
        }

        return { error: "" };
      }
    } else {
      return { error: "" };
    }
  } catch (err) {
    yield call(sentryErrorCatch, err, "#214");
    console.log("error in /me/me_v2.json", err);
  }
}

// confirm new user invitation from owner or admin
export function* update_invites_for_new_users(user_me_data) {
  const { csrfToken } = yield select(authSelector((state) => state));
  let confirm_for_new_commonkey_users = yield call(
    api,
    `/invitations/confirm_for_new_commonkey_users`,
    "GET",
    null,
    null,
    csrfToken
  );
  if (
    confirm_for_new_commonkey_users &&
    Array.isArray(confirm_for_new_commonkey_users) &&
    confirm_for_new_commonkey_users.length > 0
  ) {
    try {
      let data = confirm_for_new_commonkey_users;
      for (var i = 0; i < data.length; i++) {
        var invite = data[i];
        var organization_public_key_encrypted = null;
        var group_public_key_encrypted = null;
        if (invite.invitation.share_type == "organization") {
          var decrypted_org_key = decrypt(
            sym_key_by_org_id(invite.invitation.organization_id),
            sym_key()
          );
          if (invite.user.asymmetric_key_algo === "RSA-OAEP") {
            organization_public_key_encrypted = yield subtleRSAEncrypt(
              invite.user.public_key,
              decrypted_org_key
            );
          } else {
            organization_public_key_encrypted = rsaEncrypt(
              invite.user.public_key,
              decrypted_org_key
            );
          }
        } else {
          var decrypted_group_key = get_g_s(
            user_me_data,
            invite.invitation.organization_id,
            invite.invitation.group_id
          );
          if (invite.user.asymmetric_key_algo === "RSA-OAEP") {
            group_public_key_encrypted = yield subtleRSAEncrypt(
              invite.user.public_key,
              decrypted_group_key
            );
          } else {
            group_public_key_encrypted = rsaEncrypt(
              invite.user.public_key,
              decrypted_group_key
            );
          }
        }

        // pass new_user_key empty to accept new invitaion on reciver side
        let new_user_key = "";

        const form_data = new FormData();

        form_data.append(
          "invitation[group_public_key_encrypted]",
          group_public_key_encrypted
        );
        form_data.append(
          "invitation[organization_public_key_encrypted]",
          organization_public_key_encrypted
        );
        form_data.append("invitation[new_user_key]", new_user_key);

        let updateInvitation = yield call(
          api,
          `/invitations/${invite.invitation.id}`,
          "PUT",
          null,
          form_data,
          csrfToken
        );

        if (updateInvitation && updateInvitation.success === true) {
          toast.success(
            `CONFIRMED: You have confirmed ${updateInvitation.user} to ${
              updateInvitation.group
                ? updateInvitation.group + " team within "
                : ""
            } ${updateInvitation.company}`,
            {
              className: "toast-success",
            }
          );
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#219");
      console.log("error update_invites_for_new_users", err);
    }
  }
}

export function* process_accounts_in_groups_only(user_data) {
  let me = user_data;
  const { csrfToken } = yield select(authSelector((state) => state));
  let accounts_in_groups_only = yield call(
    api,
    `/organization_accounts/accounts_in_groups_only`,
    "GET",
    null,
    null,
    csrfToken
  );

  if (
    accounts_in_groups_only &&
    accounts_in_groups_only.to_process.length > 0
  ) {
    try {
      for (let i = 0; i < accounts_in_groups_only.to_process.length; i++) {
        let toa = accounts_in_groups_only.to_process[i];
        try {
          let grpSymKey = yield call(
            get_g_s,
            me,
            toa.account.organization_id,
            toa.group_account.organization_group_id
          );
          toa.group_account = decrypt(toa.group_account, grpSymKey);
        } catch (err) {
          yield call(sentryErrorCatch, err, "#215");
          //handling corrupt secret
          console.log("error in grpSymKey in accounts_in_groups", err);
        }
        let payload = {
          save_all: true,
          organization_id: toa.account.organization_id,
          page_title: toa.group_account.page_title,
          custom_title: toa.group_account.custom_title,
          login_hostname: toa.group_account.login_page_hostname,
          redirect_hostname: toa.group_account.redirect_page_hostname,
          login_page_title: toa.group_account.login_page_title,
          redirect_origin: toa.group_account.redirect_origin,
          login_origin: toa.group_account.login_origin,
          redirect_page_title: toa.group_account.redirect_page_title,
          password: toa.group_account.cipher,
          account_id: toa.account.id,
          url: toa.group_account.url,
          custom: !toa.group_account.auto_login,
          login: toa.group_account.login,
          login_string: toa.group_account.login_string,
          notes: toa.group_account.notes,
          type: "organization",
        };
        yield call(updateOrganizationSecret, payload, me);
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#218");
      console.log("error accounts_in_groups_only", err);
    }
  }
}

function* updateOrganizationSecret(data, user_data) {
  try {
    const { userRole } = yield select(teamsTabSelector((state) => state));

    const { organizationAccount } = yield select(
      organizationSecretDetailsSelector((state) => state)
    );
    // login, login_hostname, redirect_hostname, account_id, type required
    // message
    if (
      !data.login ||
      !data.login_hostname ||
      !data.id ||
      !data.type ||
      !data.redirect_hostname
    ) {
      const { csrfToken } = yield select(authSelector((state) => state));

      let url = "";
      if (
        user_data.orgs_by_id[data.organization_id] &&
        !user_data.orgs_by_id[data.organization_id].admin
      ) {
        url = `/me/all_accounts?group_id=${data.group_id}`;
      } else {
        url = `/me/all_accounts/`;
      }
      var urlAjax = yield call(api, url, "GET", null, null, csrfToken);

      if (urlAjax.length > 0) {
        // Convert all encrypted account to decrypted account
        // according to type
        var accounts;
        if (userRole === "owner" || userRole === "admin") {
          // decrypt org secret (Owner /Admin)
          accounts = convert_encrypt_to_decrypt_based_on_type(
            urlAjax,
            user_data
          );
        } else {
          // decrypt org secret (Manager)
          urlAjax = urlAjax.filter(
            (secret) =>
              secret.class_name != "UserAccount" &&
              secret.class_name != "OrganizationAccount"
          );
          accounts = urlAjax.map((secret) => {
            let accountId = parseInt(secret.id);
            let groupId = parseInt(secret.organization_group_id);
            let organizationId = parseInt(secret.organization_id);
            let groupAccounts =
              user_data.orgs_by_id[organizationId].groups_by_id[groupId]
                .accounts;

            //getting current account object
            let organizationAccount;
            organizationAccount = groupAccounts.filter(
              (account) => account.id === accountId
            );
            if (organizationAccount.length === 0) {
              let userAccount = user_data.user.accounts;
              //getting current account object
              organizationAccount = userAccount.filter(
                (account) => account.id === accountId
              );
            }
            let groupSymKey = get_group(
              user_data,
              groupId,
              organizationId
            ).symKey;
            //return object with all info with symkey of account
            let symKey = ckServiceSymKey(secret, organizationId, groupSymKey);
            //Decrypting the secret and also checking for corrupt secrets
            let decryptedAccount = decryptObjectMarkingCorrupt(secret, symKey);
            return decryptedAccount;
          });
        }

        if (
          check_if_exists_except(
            data.account_id,
            data.login,
            data.login_hostname,
            data.redirect_hostname,
            accounts
          ) !== false
        ) {
          //Toaster
          toast.error("Duplicate record found", {
            className: "toast-danger",
          });
          return;
        }

        var to_save = {
          login: data.login,
          page_title: data.page_title,
          cipher: data.password,
          notes: data.notes,
          custom_title: data.custom_title,
        };

        // save all shit
        ///====> not requred for personal secret
        if (data.save_all) {
          to_save = Object.assign(to_save, {
            login_origin: data.login_url,
            redirect_origin: data.redirect_origin,
            url: data.url,
            needs_to_be_completed: false,
            login_page_hostname: data.login_hostname,
            login_string: remove_placeholders_from_login_string(
              data.login_string,
              data.login,
              data.password
            ),
            redirect_page_title: data.redirect_page_title,
            login_page_title: data.login_page_title,
            redirect_page_hostname: data.redirect_hostname,
            //auto_login: !args.custom
          });
        }
        ///====> not requred for personal secret
        if (data.custom) {
          to_save.login_page_hostname = data.page_title;
          to_save.login_page_title = data.page_title;
          to_save.redirect_page_title = data.page_title;
          to_save.redirect_page_hostname = data.page_title;
          to_save.url = data.url;
          to_save.login_string = "";
        } else {
          if (!data.save_all) {
            // find this account in current accounts and lets update the login_string
            var account_ref = accounts.filter(
              (account) =>
                account.id == parseInt(data.account_id) &&
                (account.type == data.type ||
                  account.organization_id == data.organization_id ||
                  account.organization_group_id == parseInt(data.group_id))
            );

            if (account_ref.length == 1) {
              to_save.login_string =
                replace_login_and_password_for_dd_login_string(
                  account_ref[0].login_string,
                  account_ref[0].login,
                  to_save.login
                );
              to_save.login_string =
                replace_login_and_password_for_dd_login_string(
                  to_save.login_string,
                  account_ref[0].cipher,
                  to_save.cipher
                );
            } else {
              //toaster
              //
              alert(
                "Oh no an error occurred! We're on it! More than one account with the same id...impossible"
              );
              return;
            }
          }
        }

        //Arrange the data to be given to form data and encrypt it.
        // rename
        var save = {};
        for (let x in to_save) {
          save[data.type + "_" + "account[" + x + "]"] = to_save[x];
        }

        // encrypt
        let save_enc = {};

        // setup url
        url = {};
        if (
          user_data.orgs_by_id[data.organization_id] &&
          !user_data.orgs_by_id[data.organization_id].admin
        ) {
          data.type = "manager";
        }

        switch (data.type) {
          case "user":
            url.path = `/user_accounts/${data.account_id}.json`;
            save_enc = encrypt(save, sym_key());
            break;
          case "organization":
            save_enc = encrypt(
              save,
              sym_key_by_org_id_decrypt_new(data.organization_id, user_data)
            );

            url.organization_id = data.organization_id;
            url.path = `/organizations/${data.organization_id}/accounts/${data.account_id}.json`;
            break;

          case "manager":
            let org = user_data.orgs_by_id[data.organization_id];
            let group = org.groups.filter(
              (group) => group.id === data.group_id
            )[0];
            var key = group.symKey;

            var decrypt_org_group_symkey = decrypt(key, sym_key());
            delete_param_for_org_grp_account(save);
            save_enc = encrypt(save, decrypt_org_group_symkey);

            url.organization_id = data.organization_id;

            url.path = `/organizations/${data.organization_id}/groups/${data.group_id}/accounts/${data.account_id}/update_manager.json`;

            // If manager, handle saving within this callback.
            //instance.saveAccount(args, argsdup, to_save, url, save_enc);
            break;
        }

        const form_data = new FormData();
        for (let key in save_enc) {
          form_data.append(key, save_enc[key]);
        }

        let apiData;
        apiData = yield call(api, url.path, "PUT", null, form_data, csrfToken);

        if (apiData.success === true) {
          if (data.type == "organization" && apiData.groups) {
            // rename for group save
            var save = {};

            // save it all
            var save = {};
            for (var arg in to_save) {
              if (arg != "needs_to_be_completed") {
                save["organization_group_account[" + arg + "]"] = to_save[arg];
              }
            }

            var groups = apiData.organization_group_accounts;

            for (var g in groups) {
              if (g === "isArray") {
                //argsdup.success(d);
                continue;
              }

              // encrypt
              var gsk = decrypt(
                groups[g].k,
                sym_key_by_org_id_decrypt_new(url.organization_id, user_data)
              );

              delete_param_for_org_grp_account(save);

              var g_save = encrypt(save, gsk);

              // id of account just saved
              g_save["organization_group_account[organization_account_id]"] =
                apiData.organization_account.id;

              const group_form_data = new FormData();
              //Form Data for Group
              for (let key in g_save) {
                group_form_data.append(key, g_save[key]);
              }

              yield call(
                api,
                `/organizations/${url.organization_id}/groups/${groups[g].group_id}/accounts/${groups[g].organization_group_account}.json`, //orgid and account_id
                "put",
                null,
                group_form_data,
                csrfToken
              );
            }
          }
          // yield putResolve(secretActions.setUpdateButtonLoading(false));
          // const user_me_data = yield call(getLatestUserData);
          // yield putResolve(authActions.setMe(user_me_data));
          // yield putResolve(
          //   secretTabActions.loadData({ companyid: data.organization_id })
          // );
          // dataPayload.history.go(-1);
          // //toaster;
          // toast.success("Secret Update Successfully", {
          //   className: "toast-success",
          // });
        } else {
          yield call(sentryErrorCatch, "API respose is empty", "#198");
          //toaster;
          toast.error("Something went wrong #198", {
            className: "toast-danger",
          });
        }
      } else {
        //toaster;
        toast.error("No Secret Accounts", {
          className: "toast-danger",
        });
      }
    } else {
      yield call(sentryErrorCatch, "Condition false", "#199");
      //toaster;
      toast.error("Something went wrong #199", {
        className: "toast-danger",
      });
    }
  } catch (err) {
    yield call(sentryErrorCatch, err, "#200");
    //toaster;
    toast.error("Something went wrong #200", {
      className: "toast-danger",
    });
  }
}

function check_if_exists_except(
  account_id,
  login,
  login_hostname,
  redirect_hostname,
  all_accounts
) {
  for (var i = 0; i < all_accounts.length; i++) {
    if (
      all_accounts[i].login === login && // same login
      all_accounts[i].login_page_hostname === login_hostname && // same page
      all_accounts[i].redirect_page_hostname === redirect_hostname && // same page
      all_accounts[i].id !== account_id // not same account id
    ) {
      return all_accounts[i];
    }
  }
  return false;
}

export function create_sym_key() {
  var random_value_1 = CryptoJS.lib.WordArray.random(128 / 8);
  var random_value_2 = CryptoJS.lib.WordArray.random(128 / 8);
  return CryptoJS.PBKDF2(random_value_2, random_value_1, {
    keySize: 256 / 32,
    iterations: 500,
  }).toString();
}

// get the sym key
export function sym_key() {
  if (!check_sym()) {
    return;
  }
  let me = JSON.parse(sessionStorage.getItem("me"));

  var sym_key = sessionStorage.getItem("symKey");
  if (sym_key && sym_key.length && sym_key == me.symkey) {
    return sym_key;
  } else {
    return me.symKey;
  }
}

export function check_sym() {
  let sessionMe = sessionStorage.getItem("me");
  var localMe = JSON.parse(localStorage.getItem("me"));

  if (typeof sessionMe != "undefined" && sessionMe !== null) {
    return true;
  } else {
    if (
      typeof localMe != "undefined" &&
      localMe &&
      localMe.symKey &&
      localMe.symKey.length
    ) {
      var localSymKey = localStorage.getItem("symKey");

      if (localSymKey) {
        sessionStorage.setItem("symKey", localSymKey);
        sessionStorage.setItem("me", localStorage.getItem("me"));
        return true;
      }
    }
    return false;

    //calling its selft 5 * 5 times  till it get symkey
    //  let  checks_s = 0
    //   if(this.checks_s < 5){
    //       this.checks_s++;
    //       setTimeout(function(){
    //           return instance.check_sym();
    //       },5000);
    //   }else{
    //       sessionStorage.clear();
    //       $("#logout").click();
    //       return false;
    //   }
  }
}
export function* generateNewUserInfoObject(args) {
  var salt1 = CryptoJS.lib.WordArray.random(128 / 8);
  var salt2 = CryptoJS.lib.WordArray.random(128 / 8);

  // Generate new symmetric AES key from password and salt1 using
  // PBKDF2
  var derivedKey = CryptoJS.SHA256(
    CryptoJS.PBKDF2(args.password, salt1.toString(), {
      keySize: 256 / 32,
      iterations: 1000,
    }).toString()
  ).toString();

  // Generate hash from AES key and salt2 using SHA-2
  var passwordHash = CryptoJS.SHA256(
    salt2.toString().concat(derivedKey)
  ).toString();

  // Generate JSON object to store user specific values
  var UIObj = {
    firstname: args.firstname,
    lastname: args.lastname,
    email: args.email,
    passwordHash: passwordHash,
    salt1: salt1.toString(),
    salt2: salt2.toString(),
    symKey: derivedKey,
    plan: args.plan,
    googleRefreshToken: args.googleRefreshToken,
    microsoftRefreshToken: args.microsoftRefreshToken,
  };

  return UIObj;
}

export function encrypt(object, symKey) {
  switch (typeof object) {
    case "object":
      return encrypt_object(object, symKey);

    case "string":
      return encrypt_string(object, symKey).concat(":string");

    case "number":
      return encrypt_number(object, symKey).concat(":number");

    case "boolean":
      return encrypt_boolean(object, symKey).concat(":boolean");

    default:
      return null;
  }
}
// ENCRYPT and DECRYPT elements
function encrypt_string(string, symKey) {
  return CryptoJS.AES.encrypt(string, symKey).toString();
}

function encrypt_number(number, symKey) {
  return encrypt_string(number.toString(), symKey);
}

function encrypt_boolean(boolean, symKey) {
  return encrypt_string(boolean.toString(), symKey);
}

export function encrypt_object(object, symKey) {
  if (object == null) {
    return null;
  } else {
    var enc_obj;
    if (Array.isArray(object)) {
      enc_obj = [];
      for (var index = 0; index < object.length; index++) {
        if (typeof object[index] == "object") {
          enc_obj[index] = encrypt_object(object[index], symKey);
        } else {
          if (typeof object[index] == "number") {
            enc_obj[index] = encrypt_number(object[index], symKey).concat(
              " = number"
            );
          } else {
            if (typeof object[index] == "string") {
              enc_obj[index] = encrypt_string(object[index], symKey).concat(
                ":string"
              );
            } else {
              if (typeof object[index] == "boolean") {
                enc_obj[index] = encrypt_boolean(object[index], symKey).concat(
                  ":boolean"
                );
              } else {
                enc_obj[index] = object[index];
              }
            }
          }
        }
      }
    } else {
      enc_obj = {};
      for (let key in object) {
        if (typeof object[key] == "object") {
          enc_obj[key] = encrypt_object(object[key], symKey);
        } else {
          if (typeof object[key] == "number") {
            enc_obj[key] = encrypt_number(object[key], symKey).concat(
              ":number"
            );
          } else {
            if (typeof object[key] == "string") {
              enc_obj[key] = encrypt_string(object[key], symKey).concat(
                ":string"
              );
            } else {
              if (typeof object[key] == "boolean") {
                enc_obj[key] = encrypt_boolean(object[key], symKey).concat(
                  ":boolean"
                );
              } else {
                enc_obj[key] = object[key];
              }
            }
          }
        }
      }
    }
    return enc_obj;
  }
}

// general DECRYPT function
export function decrypt(object, symKey) {
  switch (typeof object) {
    case "object":
      return decrypt_object(object, symKey);

    case "string":
      var enc_pieces = object.split(":");

      if (enc_pieces.length == 2 && decrypt_needed(object)) {
        return decryptEncryptionHelper(object, symKey);
      } else {
        return object;
      }

    case "number":
      return decrypt_number(object, symKey);

    case "boolean":
      return decrypt_boolean(object, symKey);

    default:
      return null;
  }
}

export function decryptEncryptionHelper(value, symKey) {
  if (typeof value == "object") {
    return decrypt_object(value, symKey);
  } else {
    if (typeof value == "string") {
      var encPieces = value.split(":");
      var encString = encPieces[0];
      var decType = encPieces[1];

      if (decType == "number") {
        return decrypt_number(encString, symKey);
      } else {
        if (decType == "string") {
          return decrypt_string(encString, symKey);
        } else {
          if (decType == "boolean") {
            return decrypt_boolean(encString, symKey);
          }
        }
      }
    } else {
      return value;
    }
  }
}

export function decrypt_needed(value) {
  var pieces = value.split(":");
  var last = pieces[pieces.length - 1];
  return last == "string" || last == "boolean" || last == "number";
}
export function decrypt_string(string, symKey) {
  return CryptoJS.AES.decrypt(string, symKey).toString(CryptoJS.enc.Utf8);
}

export function decrypt_number(string, symKey) {
  return parseInt(decrypt_string(string, symKey));
}

export function decrypt_boolean(string, symKey) {
  var boolean_string = decrypt_string(string, symKey);
  if (boolean_string == "false") {
    return false;
  } else {
    if (boolean_string == "true") {
      return true;
    } else {
      return null;
    }
  }
}
export function decrypt_object(object, symKey) {
  if (object == null) {
    return null;
  } else {
    var dec_obj;
    if (Array.isArray(object)) {
      dec_obj = [];
      for (var index = 0; index < object.length; index++) {
        if (
          typeof object[index] == "string" &&
          !decrypt_needed(object[index])
        ) {
          dec_obj[index] = object[index];
        } else {
          dec_obj[index] = decryptEncryptionHelper(object[index], symKey);
        }
      }
    } else {
      dec_obj = {};
      for (let key in object) {
        if (typeof object[key] == "string" && !decrypt_needed(object[key])) {
          dec_obj[key] = object[key];
        } else {
          dec_obj[key] = decryptEncryptionHelper(object[key], symKey);
        }
      }
    }
    return dec_obj;
  }
}

export function decrypt_user_account_object(object, symKey) {
  if (object == null) {
    return null;
  } else {
    var dec_obj = {};
    for (let key in object) {
      if (
        key == "id" ||
        key == "created_at" ||
        key == "updated_at" ||
        key == "user_id" ||
        key == "cipher" ||
        key == "login_string"
      ) {
        dec_obj[key] = object[key];
      } else {
        dec_obj[key] = decrypt(object[key], symKey);
      }
    }
    return dec_obj;
  }
}

export function decrypt_org_account_object(object, symKey) {
  if (object == null) {
    return null;
  } else {
    var dec_obj = {};
    for (let key in object) {
      if (
        key == "organization_id" ||
        key == "hostname_id" ||
        key == "cipher" ||
        key == "login_string"
      ) {
        dec_obj[key] = object[key];
      } else {
        try {
          dec_obj[key] = decrypt(object[key], symKey);
        } catch {
          dec_obj.domainDictionaryAttributesCorrupt = true;
        }
      }
    }
    return dec_obj;
  }
}

export function decrypt_group_account_object(object, symKey) {
  if (object == null) {
    return null;
  } else {
    var dec_obj = {};
    for (let key in object) {
      if (
        key == "organization_id" ||
        key == "hostname_id" ||
        key == "cipher" ||
        key == "login_string" ||
        key == "id" ||
        key == "symKey"
      ) {
        dec_obj[key] = object[key];
      } else {
        dec_obj[key] = encryptDecrypt(object[key], symKey);
      }
    }
    return dec_obj;
  }
}

//creates csv file and export it
export function* exportOrganizationAccounts(accounts, userData) {
  var user_accounts = [];
  var org_accounts = accounts;
  for (let i = 0; i < org_accounts.length; i++) {
    let key;

    try {
      if (org_accounts[i].organization_id != null) {
        key = decrypt(
          get_org(userData, org_accounts[i].organization_id)
            .organization_sym_key,
          sym_key()
        );
      } else {
        key = sym_key();
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#216");
      console.log("error in exportOrganizationAccounts", err);
    }
    try {
      let org_account = {
        auto_login: org_accounts[i].auto_login,
        cipher: decrypt(org_accounts[i].cipher, key),
        login: decrypt(org_accounts[i].login, key),
        login_origin: decrypt(org_accounts[i].login_origin, key),
        login_page_hostname: decrypt(org_accounts[i].login_page_hostname, key),
        login_page_title: decrypt(org_accounts[i].login_page_title, key),
        login_string: decrypt(org_accounts[i].login_page_title, key),
        notes: decrypt(org_accounts[i].notes, key).replace(/[\r\n]+/gm, " "),
        application_name: decrypt(org_accounts[i].page_title, key),
        redirect_origin: decrypt(org_accounts[i].redirect_origin, key),
        redirect_page_hostname: decrypt(
          org_accounts[i].redirect_page_hostname,
          key
        ),
        // redirect_page_title: decrypt(org_accounts[i].redirect_page_title, key),
        url: decrypt(org_accounts[i].url, key),
      };
      user_accounts[i] = org_account;
    } catch (e) {
      let org_account = {
        auto_login: "",
        cipher: "",
        login: "",
        login_origin: "",
        login_page_hostname: "",
        login_page_title: "",
        login_string: "",
        notes: "",
        redirect_origin: "",
        redirect_page_hostname: "",
        // redirect_page_title: "",
        url: "",
        application_name: "",
      };
      user_accounts[i] = org_account;
    }
  }
  convert_to_csv(user_accounts);
}
function convert_to_csv(org_accounts) {
  let attributes = [
    "application_name",
    "login",
    "cipher",
    "notes",
    "url",
    "auto_login",
  ];
  var csv_content = attributes.join(",");
  csv_content += "\n";
  for (let i = 0; i < org_accounts.length; i++) {
    var c = [];
    for (let j = 0; j < attributes.length; j++) {
      c[j] = org_accounts[i][attributes[j]];
    }
    csv_content += c.join(",");
    csv_content += "\n";
  }
  download_csv_file(csv_content);
}
function download_csv_file(content) {
  var encodedContent = encodeURIComponent(content);
  var encodedURI = "data:text/csv;charset=utf-8," + encodedContent;

  var link = document.createElement("a");
  link.setAttribute("href", encodedURI);
  link.setAttribute("download", "user_accounts.csv");
  link.click();
}
export function accountFormDataMatchesExistingAccount(
  accounts,
  accountFormData,
  domainDictionary
) {
  if (!domainDictionary) {
    return false;
  }

  for (var i = 0; i < accounts.length; i++) {
    if (
      accounts[i].login == accountFormData["login"] &&
      accounts[i].login_page_hostname == domainDictionary.url
    ) {
      return true;
    }
  }

  return false;
}

export function checkOrganaizationGroupAccounts(
  accounts,
  accountFormData,
  domainDictionary
) {
  //
  for (var i = 0; i < accounts.length; i++) {
    if (
      accounts[i].login == accountFormData["login"] &&
      accounts[i].login_page_hostname == domainDictionary.url
    ) {
      return true;
    }
  }
}

// gets the symkey for an org based on id
// THERE IS AN ISSUE IN GETTING LOCAL STORAGE / SESSION need to check

export function sym_key_by_org_id(org_id) {
  let user_data = localStorage.getItem("me");
  if (!user_data) {
    user_data = JSON.parse(sessionStorage.getItem("me"));
  }
  return user_data.orgs_by_id[org_id].organization_sym_key;
}
export function sym_key_by_org_id_new(org_id, user_data) {
  return user_data.orgs_by_id[org_id].organization_sym_key;
}

function decrypt_org_object(object, user_data) {
  // three arguments for decypt function (should be two)??
  return decrypt(
    object,
    decrypt(sym_key_by_org_id_new(object.organization_id, user_data), sym_key())
  );
}

export function* decryptAccountsData(
  allAccountsJson,
  accountFormData,
  user_data
) {
  var accounts = [];

  // let user_data = localStorage.getItem("me");
  // if (!user_data) {
  //   user_data = JSON.parse(sessionStorage.getItem("me"));
  // }
  allAccountsJson.forEach(function* (v) {
    try {
      if (v.type == "user") {
        var org_id = parseInt(accountFormData.organization_id);
        if (
          user_data.orgs_by_id[org_id] &&
          !user_data.orgs_by_id[org_id].admin
        ) {
          // var group_id = Object.keys(accountFormData.group);
          var key = accountFormData.group.group_sym_key;

          var decrypt_org_group_symkey = decrypt(key, sym_key());
          accounts.push(decrypt(v, decrypt_org_group_symkey));
        } else {
          accounts.push(decrypt(v, sym_key()));
        }
      } else {
        accounts.push(decrypt_org_object(v, user_data));
      }
    } catch (e) {
      yield call(sentryErrorCatch, e, "#217");
      console.log("error in decryptAccountsData", e);
    }
  });
  return accounts;
}

export function accountDataFromAccountFormData(
  accountFormData,
  domainDictionary
) {
  var title = undefined;
  var hostname = undefined;

  var url;
  var origin;
  var loginString;

  var auto_login;
  var extraParams;

  if (domainDictionary) {
    title = domainDictionary.title;
    hostname = domainDictionary.url;
    url = domainDictionary.login_url;
    origin = domainDictionary.login_url;
    loginString = remove_placeholders_from_login_string(
      domainDictionary.login_url,
      accountFormData.login
    );

    extraParams = {};
  } else {
    title = accountFormData.application
      ? accountFormData.application
      : accountFormData.redirect_page_title;
    hostname = accountFormData.application;
    url = accountFormData.url;
    origin = "";
    loginString = "";

    extraParams = { auto_login: false };
  }

  let returnObj = Object.assign(
    {},
    {
      login: accountFormData.login,
      cipher: accountFormData.password
        ? accountFormData.password
        : accountFormData.cipher,
      notes: accountFormData.notes,
      url: url,
      page_title: title,
      redirect_page_title: title,
      redirect_page_hostname: hostname,
      redirect_origin: origin,
      login_page_title: title,
      login_page_hostname: hostname,
      login_origin: origin,
      login_string: loginString,
      auto_login: auto_login,
    },
    extraParams
  );
  return returnObj;
}

export function remove_placeholders_from_login_string(
  login_string,
  login,
  password
) {
  let checked_login_string =
    login_string && login_string.length > 0 ? login_string : "";
  let checked_login = login && login.length > 0 ? login : "";
  let checked_password = password && password.length > 0 ? password : "";
  return checked_login_string
    .replace(/ck-login/g, checked_login)
    .replace(/ck-password/g, checked_password)
    .replace(/ck_login/g, checked_login)
    .replace(/ck_password/g, checked_password);
}

export function wrapHashKeys(hash, prefix) {
  var result = {};
  for (var hashKey in hash) {
    result[prefix + "[" + hashKey + "]"] = hash[hashKey];
  }

  return result;
}

export function* decrypt_organization_secret(user_data, secret) {
  let accountId = parseInt(secret.id);
  let groupId = parseInt(secret.organization_group_id);
  let organizationId = parseInt(secret.organization_id);
  let groupAccounts =
    user_data.orgs_by_id[organizationId].groups_by_id[groupId].accounts;

  //getting current account object
  let organizationAccount = groupAccounts.filter(
    (account) => account.id === accountId
  );

  if (organizationAccount) {
    organizationAccount = organizationAccount[0];
    let organization_id = get_org(
      user_data,
      organizationId
    ).organization_sym_key;
    let groupSymKey = get_group(user_data, groupId, organizationId).symKey;

    //return object with all info with symkey of account
    let symKey = ckServiceSymKey(
      organizationAccount,
      organization_id,
      groupSymKey
    );
    //Decrypting the secret and also checking for corrupt secrets
    let decryptedAccount = decryptObjectMarkingCorrupt(
      organizationAccount,
      symKey
    );
    return decryptedAccount;
  }
}

export function* decrypt_organization_secret_without_latest_me(
  user_data,
  secret
) {
  let groupId = parseInt(secret.organization_group_id);
  let organizationId = parseInt(secret.organization_id);
  const organizationAccount = secret;
  let organization_id = get_org(user_data, organizationId).organization_sym_key;
  let groupSymKey = get_group(user_data, groupId, organizationId).symKey;

  let symKey = ckServiceSymKey(
    organizationAccount,
    organization_id,
    groupSymKey
  );
  let decryptedAccount = decryptObjectMarkingCorrupt(
    organizationAccount,
    symKey
  );
  return decryptedAccount;
}

export function replace_login_and_password_for_dd_login_string(
  login_string,
  old_value,
  new_value
) {
  return login_string.replace(
    new RegExp(
      encodeURIComponent(old_value).replace(
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        "\\$&"
      ),
      "g"
    ),
    encodeURIComponent(new_value)
  );
}

// returns a decrypted sym key for an org based on id
export function sym_key_by_org_id_decrypt_new(org_id, user_data) {
  return decrypt(sym_key_by_org_id_new(org_id, user_data), sym_key());
}

export function remove_if_not_id(object) {
  if (object == null) {
    return null;
  }
  var data = {};
  for (var key in object) {
    if (typeof object[key] == "object") {
      data[key] = remove_if_not_id(object[key]);
    } else if (
      key.match(
        /_id$|^created_at$|^domain_dictionary_last_updated$|^auto_login$|^id$|^updated_at$|^user_id$/
      )
    ) {
      data[key] = object[key];
    }
  }
  return data;
}

// remove attributes that are protected from an object
export function remove_protected_attributes(object) {
  var protected_attributes =
    "^domain_dictionary_last_updated$|^created_at$|^id$|^updated_at$|.*_id";
  var data = {};

  for (var key in object) {
    if (typeof object[key] == "object" && object[key] != null) {
      data[key] = remove_protected_attributes(object[key]);
    } else if (!key.match(protected_attributes)) {
      data[key] = object[key];
    }
  }
  return data;
}

export function* generateNewPasswordObject(salts) {
  var derivedKey = CryptoJS.SHA256(
    CryptoJS.PBKDF2(salts.currentpassword, salts.salt1, {
      keySize: 256 / 32,
      iterations: 1000,
    }).toString()
  ).toString();
  var testHash = CryptoJS.SHA256(salts.salt2.concat(derivedKey)).toString();
  var hash = CryptoJS.SHA256(salts.salt2.concat(sym_key())).toString();
  return { result: testHash == hash, hash: hash };
}

//copied from organization.js
// get the organization for the current dashboard
function organization() {
  return ".organization-dashboard".data("id");
}
// get the org sym key
export function* org_sym_key(user_data, organization_id) {
  // let user_data = yield call(getLatestUserData);

  return decrypt(
    user_data.orgs_by_id[organization_id ? organization_id : ""]
      .organization_sym_key,
    sym_key()
  );
}

export function getOrgSymKey(user_data, organization_id, symKey) {
  return decrypt(
    user_data.orgs_by_id[organization_id ? organization_id : ""]
      .organization_sym_key,
    symKey
  );
}

export function* debugOrganizationObject(symKey, org) {
  // TODO: Why are we decrypting organization name? It is not encrypted in the database.
  let corrupts = {
    organizationAccounts: [],
    organizations: [],
    organizationGroups: [],
    organizationGroupAccounts: [],
  };
  try {
    decrypt(org["name"], symKey);
  } catch (err) {
    corrupts.organizations.push(org);
  }

  if (org["accounts"] != undefined) {
    for (let organizationAccountIndex in org["accounts"]) {
      var organizationAccount = org["accounts"][organizationAccountIndex];

      try {
        decrypt_org_account_object(organizationAccount, symKey);
      } catch (e) {
        corrupts.organizationAccounts.push(organizationAccount);
      }
    }
  }

  if (org["groups"] != undefined) {
    org.groups.forEach(function (groupData) {
      var groupSymKey = undefined;

      try {
        groupSymKey = decrypt(groupData.symKey, symKey);
      } catch (e) {
        corrupts.organizationGroups.push(groupData);
      }
      // groupSymKey =
      //   groupSymKey && groupSymKey.length > 0 ? groupSymKey : undefined;
      if (groupSymKey && !!groupData.accounts && groupData.accounts.length) {
        groupData.accounts.forEach(function (accountData) {
          try {
            decrypt_group_account_object(accountData, groupSymKey);
          } catch {
            corrupts.organizationGroupAccounts.push(accountData);
          }
        });
      }
    });
  }
  return corrupts;
}
export function get_g_s(user_data, org_id, group_id) {
  let org = user_data.orgs_by_id[org_id];
  let organization_sym_key = get_org(user_data, org_id).organization_sym_key;
  try {
    let dec = decrypt(
      org.groups_by_id[group_id].symKey,
      decrypt(organization_sym_key, sym_key())
    );

    return dec;
  } catch (e) {
    try {
      return decrypt(
        user_data.orgs_by_id[org_id].groups_by_id[group_id].symKey,
        sym_key()
      );
    } catch (e) {
      return false;
    }
  }
}
var extend = function () {
  // Variables
  var extended = {};
  var deep = false;
  var i = 0;
  var length = arguments.length;
  // Check if a deep merge
  if (Object.prototype.toString.call(arguments[0]) === "[object Boolean]") {
    deep = arguments[0];
    i++;
  }
  // Merge the object into the extended object
  var merge = function (obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        // If deep merge and property is an object, merge properties
        if (
          deep &&
          Object.prototype.toString.call(obj[prop]) === "[object Object]"
        ) {
          extended[prop] = extend(true, extended[prop], obj[prop]);
        } else {
          extended[prop] = obj[prop];
        }
      }
    }
  };
};

// used in accounts.js and group.js
// don't encrypt ids or anything that is not encrypted on server (timestamps and ids)
// optionally delete common protected attributes
export function encrypt_for_rails(object, symKey, options) {
  var enc = encrypt(object, symKey);

  var ids = remove_if_not_id(object);

  enc = Object.assign(true, enc, ids); //extend(true, enc, ids);

  if (options && options.remove_protected_attrs) {
    enc = remove_protected_attributes(enc);
  }
  return enc;
}

export function update_group_account_json(
  group_account,
  organization_id,
  user_data
) {
  var accounts =
    user_data.orgs_by_id[organization_id].groups_by_id[
      group_account.organization_group_id
    ].accounts;
  var found = false;
  for (var i = 0; i < accounts.length; i++) {
    if (accounts.id == group_account.id) {
      // update account
      accounts[i] = group_account;
      break;
    }
  }
  if (!found) {
    // or add
    accounts.push(group_account);
  }
}

export function* makeAllAdminsToMemeber(data) {
  try {
    yield putResolve(
      userActions.setRemovePermissionLoading({
        flag: true,
        index: data.index,
      })
    );
    const { me, csrfToken } = yield select(authSelector((state) => state));
    let apiData;

    if (data.invitationId === 0) {
      let result = yield call(
        api,
        `/organizations/${data.companyid}/pk/?email=${data.email}`,
        "GET",
        null,
        null,
        csrfToken
      );

      if (result.exists === true) {
        // invite data
        let inviteData = {
          "invitation[email]": data.email,
          "invitation[group_public_key_encrypted]": "",
          "invitation[admin]": false,
          "invitation[share_type]": "organization",
          clear: true,
          group_id: "",
          organization_id: data.companyid,
        };
        const form_data = new FormData();

        form_data.append("invitation[email]", inviteData["invitation[email]"]);
        form_data.append(
          "invitation[group_public_key_encrypted]",
          inviteData["invitation[group_public_key_encrypted]"]
        );
        form_data.append("group_id", inviteData["group_id"]);
        form_data.append("organization_id", inviteData["organization_id"]);
        form_data.append("invitation[admin]", inviteData["invitation[admin]"]);
        form_data.append("clear", true);
        form_data.append("invitation[share_type]", "organization");

        apiData = yield call(
          api,
          `/invitations.json`,
          "POST",
          null,
          form_data,
          csrfToken
        );
      }
    } else {
      apiData = yield call(
        api,
        `/invitations/${data.invitationId}.json`,
        "DELETE",
        null,
        null,
        csrfToken
      );
    }
    //response back from api
    // { "status": true, "organization_invite_deleted": null }
  } catch (err) {
    yield call(sentryErrorCatch, err, "#149");
    console.log("error in removePermissionSaga", err);
    //toaster
    toast.error("Something Went Wrong #149", {
      className: "toast-danger",
    });
  }
}

export function* makeAllManagersToMemeber(data) {
  try {
    const { csrfToken } = yield select(authSelector((state) => state));

    const form_data = new FormData();
    form_data.append("organization_group_user[can_add_team_members]", "false");

    let result = yield call(
      api,
      `/organization_group_users/${data.manager.id}.json`,
      "PUT",
      null,
      form_data,
      csrfToken
    );

    //result.flash_message: "USER has been promoted to MANAGER."
  } catch (err) {
    yield call(sentryErrorCatch, err, "#121");
    console.log("error in demoteToNormalUserSaga", err);
    toast.error("Something went wrong #121", {
      className: "toast-danger",
    });
  }
}

export const refreshTokenSetup = (res) => {
  // Timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    console.log("newAuthRes:", newAuthRes);
    // saveUserToken(newAuthRes.access_token);  <-- save new token
    localStorage.setItem("authToken", newAuthRes.id_token);

    // Setup the other timer after the first one
    setTimeout(refreshToken, refreshTiming);
  };

  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming);
};
