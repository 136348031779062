import { state } from "../../../../store";
export const [
  billingChangePlanActions,
  billingChangePlanSelector,
  billingChangePlanState,
] = state("billingChangePlan", {
  initial: {
    growthPlan: {},
    growthPlanMonthlySelected: false,
    growthPlanLoading: false,
    teamManagementPlan: {},
    teamManagementPlanLoading: false,
    teamPasswordManagementPlanMonthlySelected: false,
    freePlanLoading: false,
    organization: {},
    paymentMethods: {},
    selectedPlan: {},
    subscribedPlanInfo: {},
    loading: true,
    selectedPlan: "",
    usersCount: "",
    savedCard: false,
    stripe_pk: "",
    showUnsubscribeModal: false,
    selectedPlanDetails: {},
    admins: [],
    managers: [],
    currentPlanName: "",
  },
  on: {
    loadBillingData: (_, data) => {
      const newStoreObj = { ..._ };
      return { ...data };
    },

    setTeamManagementPlanLoading: (_, data) => {
      const newStoreObj = { ..._ };
      return { ...newStoreObj, teamManagementPlanLoading: data };
    },
    setGrowthPlanLoading: (_, data) => {
      const newStoreObj = { ..._ };

      return { ...newStoreObj, growthPlanLoading: data };
    },
    setFreePlanLoading: (_, data) => {
      const newStoreObj = { ..._ };

      return { ...newStoreObj, freePlanLoading: data };
    },
    setLoading: (_, data) => {
      const newStoreObj = { ..._ };

      return { ...newStoreObj, loading: data };
    },
    setGrowthPlanMonthlySelected: (_, data) => {
      const newStoreObj = { ..._ };

      return { ...newStoreObj, growthPlanMonthlySelected: data };
    },
    setTeamPasswordManagementPlanMonthlySelected: (_, data) => {
      const newStoreObj = { ..._ };

      return {
        ...newStoreObj,
        teamPasswordManagementPlanMonthlySelected: data,
      };
    },
    setShowUnsubscribeModal: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        showUnsubscribeModal: data,
      };
    },
    setSelectedPlanDetails: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        selectedPlanDetails: data,
      };
    },
    setAdmins: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      console.log();
      return {
        ...newStoreObj,
        admins: data,
      };
    },
    setManagers: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      console.log();
      return {
        ...newStoreObj,
        managers: data,
      };
    },
  },
  events: [
    "loadData",
    "subscribeTogglePlan",
    "subscribeFreePlan",
    "removeAllAdminPermission",
    "removeAllManagerPermission",
  ],
});
