import { state } from "../../../store";

export const [
  twoFactorAuthActions,
  twoFactorAuthSelector,
  twoFactorAuthState,
] = state("twoFactorAuth", {
  initial: {
    user: {},
    code: "",
    loading: true,
    error: "",
    modalStatus: false,
    otp_provisioning_uri: "",
  },
  on: {
    load: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.user = data;
      newStoreObj.otp_provisioning_uri = data.otp_provisioning_uri;

      return newStoreObj;
    },
    inputChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value } = result;
      newStoreObj[fieldName] = value;
      newStoreObj.error = "";
      return { ...newStoreObj };
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    setModalStatus: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.modalStatus = !newStoreObj.modalStatus;
      return newStoreObj;
    },
    setError: (_, data) => {
      let newStoreObj = { ..._, error: data };
      return newStoreObj;
    },
  },
  events: ["enableOtp", "checkOtp", "disableOtp"],
});
