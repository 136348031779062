import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import CryptoJS from "crypto-js";
import { loginActions } from "./store";
import { authActions, authSelector } from "../store";
import { api } from "../../../helpers/api";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../helpers/sentryUtils";
import {
  decrypt,
  getLatestUserData,
  update_invites_for_new_users,
  encrypt,
  sym_key,
  getOrgSymKey,
} from "../../../helpers/apiUtils";

function* updateUserDetailsWithNewSymKey(data) {
  try {
    // Steps ==> Update user data
    // get all secret
    // decrypt with old sym key
    // encrypt decrypted secret with new sym key
    // update in database
    // get latest me
    // update symkey

    const { csrfToken, me, symKey } = data;

    // new sym key
    const newSymKey = sym_key();

    var user_accounts = [];

    for (let i = 0; i < me.user.accounts.length; i++) {
      let secret = me.user.accounts[i];
      try {
        user_accounts.push(yield call(decrypt, secret, symKey));
      } catch (err) {
        console.log("Personal Secrets Corrupted ", err);
        yield call(sentryErrorCatch, err, "#226");
      }
    }

    let user_accounts_update = [];
    for (let i = 0; i < user_accounts.length; i++) {
      // new account holder
      let account = {};
      for (let arg in user_accounts[i]) {
        // only encrypt non-ids and special variables

        if (!arg.match(/^created_at$|^updated_at$|^id$|.*?_id$|^auto_login$/)) {
          try {
            account[arg] = encrypt(user_accounts[i][arg], newSymKey);
          } catch (e) {
            yield call(sentryErrorCatch, e, "#227");
          }
        } else {
          account[arg] = user_accounts[i][arg];
        }
      }
      // now need to save each user account
      user_accounts_update.push((true, {}, account));
    }

    let ignore_list = me.ignore;
    let ignore_lists_update = [];
    for (let domain = 0; domain < ignore_list.length; domain++) {
      let to_ignore = {};
      for (let key in ignore_list[domain]) {
        if (!key.match(/^created_at$|^updated_at$|^id$|.*?_id$/)) {
          try {
            to_ignore[key] = encrypt(
              decrypt(ignore_list[domain][key], symKey),
              newSymKey
            );
          } catch (e) {
            yield call(sentryErrorCatch, e, "#228");
          }
        } else {
          to_ignore[key] = ignore_list[domain][key];
        }
      }
      ignore_lists_update.push((true, {}, to_ignore));
    }

    let organization_groups_update = [];
    let organizations_update = [];
    let shared_groups_update = [];
    for (let org in me.orgs_by_id) {
      try {
        var Org_sym_key = yield call(getOrgSymKey, me, org, symKey);
      } catch (e) {
        yield call(sentryErrorCatch, e, "#229");
      }
      let is_admin = me.orgs_by_id[org].admin;

      let groups = me.orgs_by_id[org].groups;
      let is_owner = me.orgs_by_id[org].owner;

      if (is_admin) {
        // org sym keys update
        try {
          organizations_update.push({
            organization_sym_key: encrypt(Org_sym_key, newSymKey),
            id: org,
          });
        } catch (e) {
          yield call(sentryErrorCatch, e, "#230");
        }
      }

      // get groups and re-encrypt with new sym_key

      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];
        let group_accounts = group.accounts;

        if (is_owner && is_admin) {
          try {
            let group_sym = decrypt(group.symKey, Org_sym_key);
            // add to info
            organization_groups_update.push({
              group_sym_key: encrypt(group_sym, Org_sym_key),
              group_id: group.id,
              organization_id: org,
            });
          } catch (e) {
            yield call(sentryErrorCatch, e, "#231");
          }
        } else if (is_admin) {
        } else {
          try {
            let group_sym = decrypt(group.symKey, symKey);
            // update group accounts
            shared_groups_update.push({
              organization_id: org,
              symmetric_user_key_encrypted: encrypt(group_sym, newSymKey),
              group_id: group.id,
            });
          } catch (e) {
            yield call(sentryErrorCatch, e, "#232");
          }
        }
      }
    }

    const formData = new FormData();

    for (let domain = 0; domain < ignore_lists_update.length; domain++) {
      for (let key in ignore_lists_update[domain]) {
        formData.append(
          `ignore_list[${domain}][${key}]`,
          ignore_lists_update[domain][key]
        );
      }
    }

    for (let domain = 0; domain < user_accounts_update.length; domain++) {
      for (let key in user_accounts_update[domain]) {
        formData.append(
          `user_accounts_update[${domain}][${key}]`,
          user_accounts_update[domain][key]
        );
      }
    }

    for (let domain = 0; domain < organization_groups_update.length; domain++) {
      for (let key in organization_groups_update[domain]) {
        formData.append(
          `groups[${domain}][${key}]`,
          organization_groups_update[domain][key]
        );
      }
    }

    for (let domain = 0; domain < shared_groups_update.length; domain++) {
      for (let key in shared_groups_update[domain]) {
        formData.append(
          `shared_groups[${domain}][${key}]`,
          shared_groups_update[domain][key]
        );
      }
    }

    for (let domain = 0; domain < organizations_update.length; domain++) {
      for (let key in organizations_update[domain]) {
        formData.append(
          `organizations_update[${domain}][${key}]`,
          organizations_update[domain][key]
        );
      }
    }

    //GA
    window.dataLayer.push({
      event: "gtmEventClick",
      action: "password reset successful",
    });

    yield call(
      api,
      `/me/user/${me.user.id}.json`,
      "PUT",
      null,
      formData,
      csrfToken
    );

    yield call(updateSymKey, newSymKey, csrfToken);
  } catch (err) {
    console.log("updateUserDetailsWithNewSymKey".err);
    yield call(sentryErrorCatch, err, "#233");
    toast.error("Something Went Wrong #233", {
      className: "toast-danger",
    });
  }
}

// To update symKey
function* updateSymKey(symKey, csrfToken) {
  try {
    // Save/update user symKey
    let updateResetPasswordFormData = new FormData();
    let updateResetPassPayload = {
      symkey: symKey,
    };
    for (let key in updateResetPassPayload) {
      updateResetPasswordFormData.append(key, updateResetPassPayload[key]);
    }
    yield call(
      api,
      `/me/update_symkey.json`,
      "POST",
      null,
      updateResetPasswordFormData,
      csrfToken
    );
  } catch (err) {
    console.log("updateUserDetailsWithNewSymKey".err);
    yield call(sentryErrorCatch, err, "#234");
    toast.error("Something Went Wrong #234", {
      className: "toast-danger",
    });
  }
}

function* loginUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(loginActions.loginUser().type);
      yield putResolve(loginActions.setLoginButtonLoading(true));
      let emailId = encodeURIComponent(data.emailId);
      const result = yield call(api, `/me/salts?email=${emailId}`);

      if (result.csrfToken && result.email && result.salt1 && result.salt2) {
        yield putResolve(loginActions.setError(""));
        yield putResolve(authActions.setCsrfToken(result.csrfToken));

        // generate key from salt1, generate password hash from salt2
        var derivedKey = CryptoJS.SHA256(
          CryptoJS.PBKDF2(data.password, result.salt1, {
            keySize: 256 / 32,
            iterations: 1000,
          }).toString()
        ).toString();
        let user_data = {
          email: result.email,
          salt1: result.salt1,
          salt2: result.salt2,
          csrf: result.csrfToken,
          symKey: derivedKey,
          passwordHash: CryptoJS.SHA256(
            result.salt2.concat(derivedKey)
          ).toString(),
        };
        //creating FormData type payload for API
        const form_data = new FormData();

        let final_payload = {
          authenticity_token: user_data.csrf,
          email: user_data.email,
          password: user_data.passwordHash,
        };

        for (var key in final_payload) {
          form_data.append(key, final_payload[key]);
        }
        try {
          const tokenApi = yield call(
            api,
            `/tokens.json`,
            "POST",
            null,
            form_data
          );
          if (tokenApi.email && tokenApi.token) {
            // login success, add more key data
            user_data.firstname =
              tokenApi.firstname != null ? tokenApi.firstname : null;
            user_data.lastname =
              tokenApi.lastname != null ? tokenApi.lastname : null;
            user_data.token = tokenApi.token;
            user_data.email = tokenApi.email;

            //storing session
            window.sessionStorage.setItem("symKey", user_data.symKey);

            //local storage
            localStorage.setItem("symKey", user_data.symKey);

            //deleting passwordHash before storing
            delete user_data.passwordHash;

            var meStr = JSON.stringify(user_data);

            //storing session
            window.sessionStorage.setItem("me", meStr);

            //local storage
            localStorage.setItem("me", meStr);
            yield putResolve(authActions.setUserTokenInfo(user_data));

            try {
              let user_me_data = yield call(getLatestUserData);
              if (user_me_data.user) {
                // If user resetting password
                if (user_me_data.user.resetting_password) {
                  yield putResolve(loginActions.setResettingPassword(true));

                  // get old symKey
                  const res = yield call(
                    api,
                    `/me/get_symkey.json`,
                    "GET",
                    null,
                    null,
                    result.csrfToken
                  );

                  const newData = {
                    me: user_me_data,
                    csrf: result.csrfToken,
                    symKey: res.symkey,
                  };

                  yield call(updateUserDetailsWithNewSymKey, newData);

                  // update user data
                  user_me_data = yield call(getLatestUserData);

                  yield putResolve(loginActions.setResettingPassword(false));
                } else {
                  // update SymKey
                  yield call(updateSymKey, user_data.symKey, result.csrfToken);
                }

                var logging = {
                  email: user_me_data.user.email,
                  firstname: user_me_data.user.firstname,
                  lastname: user_me_data.user.lastname,
                  id: user_me_data.user.id,
                  created_at: user_me_data.user.created_at,
                };
                yield putResolve(authActions.setMe(user_me_data));
                yield putResolve(authActions.setUserInfo(logging));
                // confirm new user invitation from owner or admin
                let companies = Object.values(user_me_data.orgs_by_id);
                let ownerOrAdmin = companies.filter(
                  (company) =>
                    (company.owner === true && company.admin === true) ||
                    (company.owner === false && company.admin === true)
                );
                if (ownerOrAdmin && ownerOrAdmin.length > 0) {
                  yield call(update_invites_for_new_users, user_me_data);
                }

                // storing user data to Auth/store for further refrences
                window.dataLayer.push({
                  event: "gtmEventClick",
                  action: "User Logged In",
                });
                if (user_me_data.user.latest_update == "org-sym-key") {
                  const { to_accept } = user_me_data.invitations;

                  // new user and has an invitation
                  if (
                    user_me_data.user.need_to_choose_intent === false &&
                    to_accept.length > 0
                  ) {
                    // set new user intention to "business-employee" if he has invited
                    let user_data = yield call(setIntentionToUseAs);
                    yield putResolve(authActions.setMe(user_data));
                  }

                  // select intension
                  if (
                    user_me_data.user.need_to_choose_intent === false &&
                    to_accept.length === 0
                  ) {
                    data.history.push("/user/select-account-type");
                  } else {
                    if (typeof url == "object") {
                      // cktrack.signup(user_data.user.email,logging);
                      //data.history.push("/dashboard?" + url);
                      data.history.push("/dashboard?url");
                    } else {
                      // cktrack.identify(user_data.user.email,logging);
                      data.history.push("/dashboard");
                    }
                  }
                }
              }
              if (user_me_data.error_type) {
                yield putResolve(loginActions.setTwoFAUser(true));
              }
              yield putResolve(loginActions.setLoginButtonLoading(false));
            } catch (err) {
              yield call(sentryErrorCatch, err, "#101");
              console.log("error in /me/me_v2.json", err);
              toast.error("Something Went Wrong #101", {
                className: "toast-danger",
              });
            }
          } else if (tokenApi.error) {
            yield putResolve(loginActions.setLoginButtonLoading(false));

            if (tokenApi.error.includes("@")) {
              // user not confirm there email id and try to login
              data.history.push(
                `/check-confirmation-email?emailID=${data.emailId}`
              );
            } else {
              // user enter wrong email id or password
              yield putResolve(loginActions.setError(tokenApi.error));
            }
          }
        } catch (err) {
          yield call(sentryErrorCatch, err, "#102");
          console.log("error in loginUserSaga", err);
          toast.error("Something Went Wrong #102", {
            className: "toast-danger",
          });
        }
      } else if (result.error) {
        yield putResolve(loginActions.setLoginButtonLoading(false));
        yield putResolve(loginActions.setError(result.error));
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#103");
      console.log("error in loginUserSaga", err);
      toast.error("Something Went Wrong #103", {
        className: "toast-danger",
      });
    }
  }
}

export function* faCheckingOtpSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(loginActions.faCheckingOtp().type);
      yield putResolve(loginActions.setfaCodeError(""));

      const form_data = new FormData();
      form_data.append("code", data.faCode);

      const { csrfToken } = yield select(authSelector((state) => state));

      form_data.append("authenticity_token", csrfToken);

      let result = yield call(
        api,
        `/me/authenticating2fa`,
        "POST",
        null,
        form_data
      );

      if (result.success === true) {
        let user_me_data = yield call(getLatestUserData);
        if (user_me_data.user) {
          // If user resetting password
          if (user_me_data.user.resetting_password) {
            yield putResolve(loginActions.setResettingPassword(true));

            // get old symKey
            const res = yield call(
              api,
              `/me/get_symkey.json`,
              "GET",
              null,
              null,
              result.csrfToken
            );

            const newData = {
              me: user_me_data,
              csrf: result.csrfToken,
              symKey: res.symkey,
            };
            yield call(updateUserDetailsWithNewSymKey, newData);

            // update user data
            user_me_data = yield call(getLatestUserData);

            yield putResolve(loginActions.setResettingPassword(false));
          } else {
            // update SymKey
            yield call(updateSymKey, sym_key(), result.csrfToken);
          }
          var logging = {
            email: user_me_data.user.email,
            firstname: user_me_data.user.firstname,
            lastname: user_me_data.user.lastname,
            id: user_me_data.user.id,
            created_at: user_me_data.user.created_at,
          };

          // storing user data to Auth/store for further refrences
          yield putResolve(authActions.setMe(user_me_data));
          yield putResolve(authActions.setUserInfo(logging));

          if (user_me_data.user.latest_update == "org-sym-key") {
            const { to_accept } = user_me_data.invitations;

            // new user and has an invitation
            if (
              user_me_data.user.need_to_choose_intent === false &&
              to_accept.length > 0
            ) {
              // set new user intention to "Business-employee" if he has invited
              let user_data = yield call(setIntentionToUseAs);
              yield putResolve(authActions.setMe(user_data));
            }

            if (
              user_me_data.user.need_to_choose_intent === false &&
              to_accept.length === 0
            ) {
              data.history.push("/user/select-account-type");
            } else {
              if (typeof url == "object") {
                // cktrack.signup(user_data.user.email,logging);
                //data.history.push("/dashboard?" + url);
                data.history.push("/dashboard?url");
              } else {
                // cktrack.identify(user_data.user.email,logging);
                data.history.push("/dashboard");
              }
            }
          }
        }
        //show notification result.message
        toast.success(result.message, {
          className: "toast-success",
        });
        // data.history.push("/dashboard");
      } else {
        if (result.status === "too many attempts") {
          yield putResolve(loginActions.setfaCodeError(result.message));
        } else {
          yield putResolve(
            loginActions.setfaCodeError(
              "The code you entered is incorrect, please try again."
            )
          );
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#104");
      console.log("error in faCheckingOtpSaga", err);
      toast.error("Something Went Wrong #104", {
        className: "toast-danger",
      });
    }
  }
}
export function* lostTwoFADeviceSaga() {
  while (true) {
    try {
      const {} = yield take(loginActions.lostTwoFADevice().type);
      const { csrfToken } = yield select(authSelector((state) => state));

      let result = yield call(
        api,
        `/me/initiate_cancellation_2fa`,
        "POST",
        null,
        null,
        csrfToken
      );
      if (result.message) {
        yield putResolve(
          loginActions.setLostTwoFADeviceStatus(
            "We have sent you an email to reset your 2FA."
          )
        );
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#105");
      console.log("error in lostTwoFADevice", err);
      toast.error("Something Went Wrong #105", {
        className: "toast-danger",
      });
    }
  }
}

export function* setIntentionToUseAs() {
  try {
    const { csrfToken } = yield select(authSelector((state) => state));

    let formData = new FormData();
    let setAccountTypePayload = {
      intention_to_use_as: "business-employee",
      need_to_choose_intent: true,
    };

    for (let key in setAccountTypePayload) {
      formData.append(key, setAccountTypePayload[key]);
    }

    yield call(
      api,
      `/me/set_intention_to_use_as.json`,
      "POST",
      null,
      formData,
      csrfToken
    );

    const user_data = yield call(getLatestUserData);
    return user_data;
  } catch (err) {
    yield call(sentryErrorCatch, err, "#242");
    console.log("error in setIntentionToUseAs", err);
    toast.error("Something Went Wrong #242", {
      className: "toast-danger",
    });
  }
}

export default function* loginUserRootSaga() {
  yield spawn(loginUserSaga);
  yield spawn(faCheckingOtpSaga);
  yield spawn(lostTwoFADeviceSaga);
}
