import { take, call, spawn, select, putResolve } from "redux-saga/effects";
import { loadStripe } from "@stripe/stripe-js";

import { createComapnyActions } from "./store";
import {
  create_sym_key,
  encrypt,
  sym_key,
  decryptEncryptionHelper,
} from "../../../helpers/apiUtils";
import { api } from "../../../helpers/api";
import { getLatestUserData } from "../../../helpers/apiUtils";
import { authSelector } from "../../auth/store";
import { authActions } from "../../auth/store";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../../helpers/sentryUtils";

export function* createCompanyPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        createComapnyActions.createCompany().type
      );
      const { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();

      form_data.append("organization[name]", data.organizationName);
      form_data.append("organization[industry_type]", data.industryType);
      form_data.append("organization[have_credit_card]", true);

      // setup key
      var key = {};
      key.plain = create_sym_key();
      key.encrypted = encrypt(key.plain, sym_key());

      form_data.append("organization[organization_sym_key]", key.encrypted);

      // saving to data to DB
      const result = yield call(
        api,
        `/organizations.json`,
        "POST",
        null,
        form_data,
        csrfToken
      );

      if (result.id && result.organization_sym_key) {
        let key = decryptEncryptionHelper(
          result.organization_sym_key,
          sym_key()
        );

        var group_key_encrypted = encrypt(create_sym_key(), key);
        const form_data = new FormData();

        form_data.append("organization_group[name]", "General");
        form_data.append("organization_group[organization_id]", result.id);

        form_data.append(
          "organization_group[group_sym_key]",
          group_key_encrypted
        );

        //creating default TEAM GENERAL
        const groupsResult = yield call(
          api,
          `/groups.json`,
          "POST",
          null,
          form_data,
          csrfToken
        );

        if (groupsResult && groupsResult.id) {
          window.dataLayer.push({
            event: "gtmEventClick",
            action: "Created Company",
          });
          // toaster

          const user_me_data = yield call(getLatestUserData);
          yield putResolve(authActions.setMe(user_me_data));
          toast.success("Company created successfully!", {
            className: "toast-success",
          });
          // data.history.push(
          //   `/dashboard/companies/${result.id}/select-plan/teams/${groupsResult.id}`
          // );
          data.history.push(
            `/dashboard/companies/${result.id}/teams/${groupsResult.id}/step1/add-secrets`
          );
        } else {
          yield call(sentryErrorCatch, "API response is empty", "#172");
          toast.error("Something went wrong #172", {
            className: "toast-danger",
          });
        }
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#173");
      console.log(err);
      toast.error("Something Went Wrong #173", {
        className: "toast-danger",
      });
    }
  }
}

export default function* createCompanyPageRootSaga() {
  yield spawn(createCompanyPageSaga);
}
