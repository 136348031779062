import { putResolve, call, spawn, take, select } from "redux-saga/effects";
import { sitesActions } from "./store";
import { authSelector } from "../auth/store";
import { api } from "../../helpers/api";
import { decrypt, sym_key } from "../../helpers/apiUtils";
import { toast } from "react-toastify";
import sentryErrorCatch from "../../helpers/sentryUtils";

function* ignoredSitesSaga() {
  while (true) {
    try {
      yield take(sitesActions.loadData().type);
      let result;
      result = yield call(api, `/ignore_lists.json`);

      result.map((el) => {
        el.hostname = decrypt(el.hostname, sym_key());
        return el;
      });

      yield putResolve(sitesActions.load(result));

      yield true;
    } catch (err) {
      yield call(sentryErrorCatch, err, "#174")
      console.log("error in ignoredSitesSaga", err);
      //show notifications
      toast.error("Something Went Wrong #174", {
        className: "toast-danger",
      });
    }
  }
}

function* removeSiteSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sitesActions.removeSite().type);
      yield putResolve(
        sitesActions.setRemoveSiteLoading({ flag: true, index: data.index })
      );
      const { csrfToken } = yield select(authSelector((state) => state));

      const form_data = new FormData();
      form_data.append("_method", "delete");
      form_data.append("authenticity_token", csrfToken);

      let apiData;
      apiData = yield call(
        api,
        `/ignore_lists/${data.site.id}.json`,
        "POST",
        null,
        form_data,
        null
      );

      if (apiData.success === true) {
        apiData.ignore_lists.map((el) => {
          el.hostname = decrypt(el.hostname, sym_key());
          return el;
        });
        yield putResolve(
          sitesActions.setRemoveSiteLoading({ flag: false, index: data.index })
        );
        yield putResolve(sitesActions.load(apiData.ignore_lists));
        // toaster
        toast.success("Record deleted successfully!!", {
          className: "toast-success",
        });
      } else {
        yield call(sentryErrorCatch, "Record not deleted", "#204")
        //show notification
        toast.error("Record not deleted #204", {
          className: "toast-danger",
        });
      }
    } catch (err) {
      yield call(sentryErrorCatch, err, "#205")
      toast.error("Something Went Wrong #205", {
        className: "toast-danger",
      });
      console.log("error in removeSiteSaga", err);
    }
  }
}

export default function* ignoredSitesPageRootSaga() {
  yield spawn(ignoredSitesSaga);
  yield spawn(removeSiteSaga);
}
