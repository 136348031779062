import { state } from "../../../store";

export const [
  personalsecretActions,
  personalsecretSelector,
  personalsecretState,
] = state("personalSecretDetails", {
  initial: {
    userAccount: { item: {}, loading: false },
    loading: false,
  },
  on: {
    loadPersonalSecret: (_, result) => {
      const newStoreObj = { ..._ };
      newStoreObj.userAccount.loading = true;
      newStoreObj.userAccount.item = result;
      return newStoreObj;
    },
    setLoading: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      return {
        ...newStoreObj,
        loading: data,
      };
    },
  },

  events: [
    "personalSecretById",
    "deletePersonalSecret",
    "updatePersonalSecret",
  ],
});
