import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import AuthLayout from "../layouts/Auth";
import { allFlattenRoutes as routes } from "./index";
import HorizontalLayout from "../layouts/Horizontal";
import { authActions, authSelector } from "../pages/auth/store";
import { state } from "../store";
import Loader from "../components/Loader";

const withTracker = (WrappedComponent, options = {}) => {
  const HOC = class extends Component {
    componentDidMount() {
      window.dataLayer.push({
        event: "PageView",
        pageName: options.name,
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export const Routes = connect(
  state.select({
    userInfo: authSelector((state) => state.userInfo, ""),
    checkingLogIn: authSelector((state) => state.checkingLogIn, false),
  }),
  state.actions(authActions, ["setUserFromMe"])
)(
  class Routes extends Component {
    UNSAFE_componentWillMount() {
      this.props.setUserFromMe();
    }

    // returns the layout
    getLayout = () => {
      const { userInfo } = this.props;
      if (!userInfo.hasOwnProperty("email")) {
        return AuthLayout;
      }
      let layoutCls = HorizontalLayout;
      return layoutCls;
    };
    render() {
      const { userInfo, checkingLogIn } = this.props;
      const Layout = this.getLayout();

      //rendering empty till we check users logged in status
      if (!checkingLogIn) {
        return (
          <div>
            <Loader />
          </div>
        );
      }

      return (
        <BrowserRouter>
          <Layout {...this.props}>
            <Switch>
              {routes.map((route, index) => {
                return (
                  !route.children && (
                    <route.route
                      userInfo={{ ...userInfo }}
                      key={Math.random()}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      component={withTracker(route.component, route)}
                    ></route.route>
                  )
                );
              })}
              {/* <Route path="*" exact={true} component={PageNotFound} /> */}
            </Switch>
          </Layout>
        </BrowserRouter>
      );
    }
  }
);
