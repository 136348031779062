import React, { Component } from "react";
import { Row, Col, Card, CardBody, Table, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { state } from "../../store";
import { personalsecretActions, personalsecretSelector } from "./store";
import Loader from "../../components/Loader";
import SecretsTable from "../../components/secrets/secretsTable";
import { authSelector } from "../auth/store";

export default withRouter(
  connect(
    state.select({
      filterPersonalSecrets: personalsecretSelector(
        (state) => state.filterPersonalSecrets.items,
        {}
      ),
      loading: personalsecretSelector(
        (state) => state.filterPersonalSecrets.loading,
        false
      ),
      searchText: personalsecretSelector((state) => state.searchText, ""),
      user: authSelector((state) => state.me.user, {}),
    }),
    state.actions(personalsecretActions, [
      "loadData",
      "searching",
      "sorting",
      "detailsPersonalSecret",
    ])
  )(
    class PersonalSecretsPage extends Component {
      constructor(props) {
        super(props);
      }

      componentWillMount() {
        const { pathname } = this.props.location;
        const { searchText, user, history } = this.props;

        // if user not select the intent
        if (user.need_to_choose_intent === false) {
          history.push("/user/select-account-type");
        }

        this.props.loadData();
      }

      sorting = (result) => {
        this.props.sorting(result);
      };
      searching = (result) => {
        this.props.searching(result);
      };

      getSecretId = (data) => {
        const { history } = this.props;
        this.props.detailsPersonalSecret({
          secretId: data.id,
          history: history,
        });
      };

      render() {
        const { loading, filterPersonalSecrets, searchText } = this.props;
        return loading ? (
          <Loader />
        ) : (
          <>
            <Row className="mt-5">
              <Col>
                <div className="page-title-box">
                  <h2 className="page-title font-size">Personal secrets</h2>
                </div>
              </Col>
            </Row>

            <>
              <Card>
                <Row className="mb-2">
                  <Col md={12}>
                    <CardBody className="p-2 p-md-3">
                      <Row>
                        <Col sm={6} className="order-sm-0 order-1">
                          <label className="search-label w-75 inputWidth">
                            <input
                              id="search-bar-0"
                              type="text"
                              value={searchText}
                              aria-labelledby="search-bar-0-label"
                              className="form-control"
                              placeholder="Search"
                              onChange={(e) => {
                                let payload = {
                                  fieldName: "searchText",
                                  value: e.target.value,
                                };
                                this.searching(payload);
                              }}
                            />
                          </label>
                        </Col>

                        <Col sm={6} className="order-sm-1 order-0">
                          <div className="text-sm-right">
                            <Link to="/user-account/create">
                              <Button
                                color="primary"
                                className="mb-2 btn-sm btnWidth"
                              >
                                <i className="mdi mdi-plus"></i> Add Secret
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>

                      <SecretsTable
                        secrets={filterPersonalSecrets}
                        searchText={searchText}
                        handleSorting={(obj) => this.sorting(obj)}
                        handleSearching={(obj) => this.searching(obj)}
                        handleGetSecretId={(data) => this.getSecretId(data)}
                      />
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </>
          </>
        );
      }
    }
  )
);
