import { state } from "../../../store";

export const [
  personalSecretAddActions,
  personalSecretAddSelector,
  personalsecretAddState,
] = state("personalSecretAdd", {
  initial: {
    domains: { items: [], loading: false },
    selectApplicationError: false,
    loading: false,
    selectApplication: "",
    page_title: "",
    url: "",
    login: "",
    cipher: "",
    notes: "",
  },
  on: {
    load: (_, data) => {
      const newStoreObj = { ..._ };
      newStoreObj.domains.items = data;
      return newStoreObj;
    },
    setLoading: (_, data) => {
      const newStoreObj = { ..._ };
      return { ...newStoreObj, loading: data };
    },
  },
  events: ["loadData", "addSecretAuto", "addSecretCustom"],
});
